var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-content"
  }, [_vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('p', [_vm._v(" APEX Accelerators can use the following resources to serve as critical enablers to opening the defense industrial base to small, medium, large, and new entrant companies. ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_vm._m(1), _c('div', {
    staticClass: "row pb-4 text-center"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('img', {
    staticClass: "img-fluid branding",
    attrs: {
      "src": "assets/images/branding.png",
      "alt": "Branding"
    },
    on: {
      "click": _vm.branding
    }
  })]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('img', {
    staticClass: "img-fluid legal",
    attrs: {
      "src": "assets/images/legal.png",
      "alt": "Legal"
    },
    on: {
      "click": _vm.legal
    }
  })]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "img-fluid dod_program_materials",
    on: {
      "click": _vm.dod_program_materials
    }
  }, [_c('span', [_vm._v("DoD PROGRAM MATERIALS")])])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_vm._m(2), _c('div', {
    staticClass: "pb-4"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search All Documents..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedFolder != '' || _vm.searchQuery != '',
      expression: "selectedFolder != '' || searchQuery != ''"
    }],
    staticClass: "mt-2",
    attrs: {
      "color": "#00008B",
      "plain": ""
    },
    on: {
      "click": _vm.all_documents
    }
  }, [_vm._v(" ALL DOCUMENTS ")])], 1)])]), _vm.displayedBlogItems.length === 0 && _vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" No items match the search criteria... ")]) : _vm._e(), _vm.displayedBlogItems.length === 0 && !_vm.searchQuery ? _c('p', {
    staticClass: "pb-2"
  }, [_vm._v(" Check back soon for documents.. ")]) : _vm._e(), _c('div', {
    staticClass: "blog-post-grid"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.displayedBlogItems, function (item) {
    var _item$taggedCategory$;
    return _c('div', {
      key: item.id,
      staticClass: "col-12 mb-3"
    }, [_c('div', {
      staticClass: "col-12 mb-3"
    }, [_c('div', {
      staticClass: "row blog-item border d-flex"
    }, [_c('a', {
      staticClass: "col-2 col-sm-3 col-xl-2 blog-post-image center-image-vertically",
      attrs: {
        "target": "_blank"
      }
    }, [_c('img', {
      staticClass: "thumbnail-cover",
      attrs: {
        "src": item.thumbnail,
        "alt": item.thumbnailDescription
      }
    })]), _c('div', {
      staticClass: "col-10 col-sm-9 col-xl-10 useful-link-info"
    }, [_c('a', {
      staticClass: "text-dark"
    }, [_c('div', {
      staticClass: "post-event d-inline-block align-items-center pt-2"
    }, [_c('a', {
      staticClass: "blog-info-meta text-dark"
    }, [_vm._v(_vm._s((_item$taggedCategory$ = item.taggedCategory[0]) === null || _item$taggedCategory$ === void 0 ? void 0 : _item$taggedCategory$.text.toUpperCase()) + " ")])]), _c('h4', {
      staticClass: "blog-post-title mt-1"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "limit-lines-3"
    }, [_vm._v(_vm._s(item.description) + " ")]), _c('a', {
      staticClass: "limit-lines-1",
      attrs: {
        "target": "_blank",
        "href": item.url
      }
    }, [_vm._v(" View Document ")])])])])])]);
  }), 0)]), _c('div', [_c('b-pagination', {
    attrs: {
      "id": "blog-pagination",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center"
    },
    on: {
      "page-click": _vm.pageClicked
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "inner-header-bg"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "section-breadcrumb"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 justify-content-center align-self-center pb-2"
  }, [_c('div', {
    staticClass: "breadcrumb-content"
  }, [_c('div', {
    staticClass: "title-section"
  }, [_c('h1', {
    staticClass: "title text-center text-white"
  }, [_vm._v(" Documents ")])]), _c('div', {
    staticClass: "breadcrumb-nav"
  }, [_c('ul', {
    staticClass: "breadcrumb-link text-center list-unstyled mb-0"
  }, [_c('li', {
    staticClass: "text-white"
  }, [_c('i', {
    staticClass: "mdi mdi-home theme-light-color pr-2"
  }), _c('a', {
    attrs: {
      "href": "/#/"
    }
  }, [_vm._v("Home")])]), _c('li', {
    staticClass: "theme-light-color active"
  }, [_vm._v("Documents")])])])])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-2 text-left"
  }, [_c('p', {
    staticClass: "text-left"
  }, [_c('strong', [_vm._v("Choose a Document Type")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-2 text-center"
  }, [_c('p', {
    staticClass: "text-center"
  }, [_c('strong', [_vm._v("OR")])])]);

}]

export { render, staticRenderFns }