export const acceleratorLocations = [
    {
        "address": "1901 Bragaw St., Ste. 199, Anchorage, AK",
        "position": {
            "lat": 61.20318449999999,
            "lng": -149.807664
        },
        "info": {
            "State": "AK",
            "Name": "Alaska APEX Accelerator - Anchorage Center",
            "Address Line 1": "1901 Bragaw St., Ste. 199",
            "Address Line 2": "",
            "City": "Anchorage",
            "Territory": "AK",
            "Postal Code": 99508,
            "Phone Number": "(907) 786-7258",
            "Extension": "",
            "email for references": "info@ptacalaska.org",
            "website": "https://ptacalaska.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "3750 Bonita Street, Fairbanks, AK",
        "position": {
            "lat": 64.81097129999999,
            "lng": -147.7482213
        },
        "info": {
            "State": "AK",
            "Name": "Alaska APEX Accelerator - Fairbanks Center",
            "Address Line 1": "3750 Bonita Street",
            "Address Line 2": "",
            "City": "Fairbanks",
            "Territory": "AK",
            "Postal Code": 99701,
            "Phone Number": "(907) 456-7822",
            "Extension": "",
            "email for references": "info@ptacalaska.org",
            "website": "https://ptacalaska.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "Innovation Depot, Birmingham, AL",
        "position": {
            "lat": 33.5119467,
            "lng": -86.8123648
        },
        "info": {
            "State": "AL",
            "Name": "Alabama APEX Accelerator - Birmingham",
            "Address Line 1": "Innovation Depot",
            "Address Line 2": "1500 1st Avenue North",
            "City": "Birmingham",
            "Territory": "AL",
            "Postal Code": 35203,
            "Phone Number": "(205) 348-1687",
            "Extension": "",
            "email for references": "ptac@ua.edu",
            "website": "https://apexal.org/",
            "Organization Service Area": "Bibb, Chilton, Cullman, Dallas, Greene, Hale, Jefferson, Limestone, Marengo, Morgan, Perry, Pickens, Shelby, Sumter, Tuscaloosa, Walker, Wilcox"
        }
    },
    {
        "address": "621 Greensboro Ave, Tuscaloosa, AL",
        "position": {
            "lat": 33.208223,
            "lng": -87.56727649999999
        },
        "info": {
            "State": "AL",
            "Name": "Alabama APEX Accelerator - Tuscaloosa",
            "Address Line 1": "621 Greensboro Ave",
            "Address Line 2": "",
            "City": "Tuscaloosa",
            "Territory": "AL",
            "Postal Code": 35401,
            "Phone Number": "(205) 348-1687",
            "Extension": "",
            "email for references": "ptac@ua.edu",
            "website": "https://apexal.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "225 Church Street, Huntsville, AL",
        "position": {
            "lat": 34.7320885,
            "lng": -86.5907969
        },
        "info": {
            "State": "AL",
            "Name": "Alabama APEX Accelerator - Huntsville",
            "Address Line 1": "225 Church Street",
            "Address Line 2": "Suite 319",
            "City": "Huntsville",
            "Territory": "AL",
            "Postal Code": 35801,
            "Phone Number": "(256) 824-6936",
            "Extension": "",
            "email for references": "ptac@uah.edu",
            "website": "https://apexal.org/",
            "Organization Service Area": "Madison"
        }
    },
    {
        "address": "700 Pelham Road North, Jacksonville, AL",
        "position": {
            "lat": 33.8286523,
            "lng": -85.7631119
        },
        "info": {
            "State": "AL",
            "Name": "Alabama APEX Accelerator - Jacksonville",
            "Address Line 1": "700 Pelham Road North",
            "Address Line 2": "Merrill Hall",
            "City": "Jacksonville",
            "Territory": "AL",
            "Postal Code": 36265,
            "Phone Number": "256-782-5322",
            "Extension": "",
            "email for references": "kgrissom@jsu.edu",
            "website": "https://apexal.org/",
            "Organization Service Area": "Blount, Calhoun, Cherokee, Clay, Cleburne, DeKalb, Etowah, Jackson, Marshall, Randolph, St. Clair, Talladega"
        }
    },
    {
        "address": "915 S. Jackson St., Montgomery, AL",
        "position": {
            "lat": 32.3651836,
            "lng": -86.2957213
        },
        "info": {
            "State": "AL",
            "Name": "Alabama APEX Accelerator - Montgomery",
            "Address Line 1": "915 S. Jackson St.",
            "Address Line 2": "",
            "City": "Montgomery",
            "Territory": "AL",
            "Postal Code": 36104,
            "Phone Number": "(334) 229-4138",
            "Extension": "",
            "email for references": "cyoung@alasu.edu",
            "website": "https://apexal.org/",
            "Organization Service Area": "Autauga, Bullock, Chambers, Coosa, Elmore, Lowndes, Macon, Montgomery, Tallapoosa"
        }
    },
    {
        "address": "541 West College St, Florence, AL",
        "position": {
            "lat": 34.7956601,
            "lng": -87.68130409999999
        },
        "info": {
            "State": "AL",
            "Name": "Alabama APEX Accelerator - Northwest",
            "Address Line 1": "541 West College St",
            "Address Line 2": "",
            "City": "Florence",
            "Territory": "AL",
            "Postal Code": 35632,
            "Phone Number": "(256) 765-4668",
            "Extension": "",
            "email for references": "phillip.marks@ua.edu",
            "website": "https://apexal.org/",
            "Organization Service Area": "Colbert, Fayette, Franklin, Lamar, Lauderdale, Lawrence, Marion, Winston"
        }
    },
    {
        "address": "358 St Louis St, Mobile, AL",
        "position": {
            "lat": 30.69336179999999,
            "lng": -88.0475539
        },
        "info": {
            "State": "AL",
            "Name": "Alabama APEX Accelerator - South",
            "Address Line 1": "358 St Louis St",
            "Address Line 2": "",
            "City": "Mobile",
            "Territory": "AL",
            "Postal Code": 36602,
            "Phone Number": "(251) 460-6004",
            "Extension": "",
            "email for references": "gerald.dunlop@ua.edu",
            "website": "https://apexal.org/",
            "Organization Service Area": "Baldwin, Choctaw, Clarke, Conecuh, Escambia, Mobile, Monroe, Washington"
        }
    },
    {
        "address": "100 Industrial Blvd, Troy, AL",
        "position": {
            "lat": 31.7749914,
            "lng": -85.93121219999999
        },
        "info": {
            "State": "AL",
            "Name": "Alabama APEX Accelerator - Wiregrass Region",
            "Address Line 1": "100 Industrial Blvd",
            "Address Line 2": "",
            "City": "Troy",
            "Territory": "AL",
            "Postal Code": 36081,
            "Phone Number": "(334) 674-2425",
            "Extension": "",
            "email for references": "betsy.baker@ua.edu",
            "website": "https://apexal.org/",
            "Organization Service Area": "Barbour, Butler, Coffee, Covington, Crenshaw, Dale, Geneva, Henry, Houston, Lee, Pike, Russell"
        }
    },
    {
        "address": "2301 South University Ave., Little Rock, AR",
        "position": {
            "lat": 34.7306867,
            "lng": -92.3402749
        },
        "info": {
            "State": "AR",
            "Name": "Arkansas APEX Accelerator",
            "Address Line 1": "2301 South University Ave.",
            "Address Line 2": "Room 110",
            "City": "Little Rock",
            "Territory": "AR",
            "Postal Code": 72204,
            "Phone Number": "(501)671-2390",
            "Extension": "",
            "email for references": "apex@uada.edu",
            "website": "https://www.uaex.uada.edu/apex",
            "Organization Service Area": "Arkansas, Ashley, Baxter, Benton, Boone, Bradley, Calhoun, Carroll, Chicot, Clark, Clay, Cleburne, Cleveland, Columbia, Conway, Craighead, Crawford, Crittenden, Cross, Dallas, Desha, Drew, Faulkner, Franklin, Fulton, Garland, Grant, Greene, Hempstead, Hot Spring, Howard, Independence, Izard, Jackson, Jefferson, Johnson, Lafayette, Lawrence, Lee, Lincoln, Little River, Logan, Lonoke, Madison, Marion, Miller, Mississippi, Monroe, Montgomery, Nevada, Newton, Ouachita, Perry, Phillips, Pike, Poinsett, Polk, Pope, Prairie, Pulaski, Randolph, Saline, Scott, Searcy, Sebastian, Sevier, Sharp, St. Francis, Stone, Union, Van Buren, Washington, White, Woodruff, Yell"
        }
    },
    {
        "address": "3200 North Dobson Road, Building C, Chandler, AZ",
        "position": {
            "lat": 33.3531857,
            "lng": -111.8770731
        },
        "info": {
            "State": "AZ",
            "Name": "American Indian Chamber Education Fund APEX Accelerator- Arizona",
            "Address Line 1": "3200 North Dobson Road, Building C",
            "Address Line 2": "Suite 103",
            "City": "Chandler",
            "Territory": "AZ",
            "Postal Code": 85224,
            "Phone Number": "(480) 699-9529",
            "Extension": "",
            "email for references": "Venessa.gleich@aicccal.org",
            "website": "http://aicef-apex.org",
            "Organization Service Area": "CA, AZ, UT, NV, WA, OR, ID - BIA REGION (PACIFIC / WESTERN/NORTHWEST)"
        }
    },
    {
        "address": "6000 W. Olive Ave., Glendale, AZ",
        "position": {
            "lat": 33.5694333,
            "lng": -112.1908271
        },
        "info": {
            "State": "AZ",
            "Name": "Arizona APEX Accelerator - Glendale",
            "Address Line 1": "6000 W. Olive Ave.",
            "Address Line 2": "Building I-2, Room 105",
            "City": "Glendale",
            "Territory": "AZ",
            "Postal Code": 85302,
            "Phone Number": "(623) 845-4700",
            "Extension": "",
            "email for references": "azptac.admin@domail.maricopa.edu",
            "website": "https://www.azptac.com",
            "Organization Service Area": "Apache, Graham, Greenlee, La Paz, Mohave, Navajo, Pinal"
        }
    },
    {
        "address": "108 N. 40th Street, South Building, Phoenix, AZ",
        "position": {
            "lat": 33.4484729,
            "lng": -111.9977572
        },
        "info": {
            "State": "AZ",
            "Name": "AZAPEX Accelerator - Phoenix",
            "Address Line 1": "108 N. 40th Street, South Building",
            "Address Line 2": "",
            "City": "Phoenix",
            "Territory": "AZ",
            "Postal Code": "",
            "Phone Number": "(623) 845-4700",
            "Extension": "",
            "email for references": "azptac.admin@domail.maricopa.edu",
            "website": "https://www.azptac.com",
            "Organization Service Area": "Central and East Valley Phoenix Metro Area, East Maricopa County, Yavapai, Coconino, Gila Counties"
        }
    },
    {
        "address": "Cochise College Downtown Center, Sierra Vista, AZ",
        "position": {
            "lat": 31.5513229,
            "lng": -110.2717132
        },
        "info": {
            "State": "AZ",
            "Name": "AZAPEX Accelerator - Sierra Vista",
            "Address Line 1": "Cochise College Downtown Center",
            "Address Line 2": "2600 E. Wilcox Dr. Suite",
            "City": "Sierra Vista",
            "Territory": "AZ",
            "Postal Code": 85365,
            "Phone Number": "(623) 845-4701",
            "Extension": "",
            "email for references": "azptac.admin@domail.maricopa.edu",
            "website": "https://www.azptac.com",
            "Organization Service Area": "East Pima (Tucson), Santa Cruz, Cochise"
        }
    },
    {
        "address": "Arizona Western College, Yuma, AZ",
        "position": {
            "lat": 32.6886907,
            "lng": -114.4950487
        },
        "info": {
            "State": "AZ",
            "Name": "AZAPEX Accelerator - Yuma",
            "Address Line 1": "Arizona Western College",
            "Address Line 2": "1351 S Redondo Drive, Suite 101",
            "City": "Yuma",
            "Territory": "AZ",
            "Postal Code": 85365,
            "Phone Number": "(920) 782-7774",
            "Extension": "",
            "email for references": "azptac.admin@domail.maricopa.edu",
            "website": "https://www.azptac.com",
            "Organization Service Area": "Yuma County, West Maricopa County, West Pima County"
        }
    },
    {
        "address": "Hwy 264, St Michaels, AZ",
        "position": {
            "lat": 35.6562488,
            "lng": -109.0946875
        },
        "info": {
            "Address Line 1": "Hwy 264",
            "Address Line 2": "100 Taylor Rd.",
            "City": "St. Michaels",
            "email for references": "info@ncaied.org",
            "Extension": "",
            "Name": "The National Center APEX Accelerator - Navajo Nation Arizona Satellite Office",
            "Organization Service Area": "Navajo Nation BIA Region - Arizona, New Mexico, Utah",
            "Phone Number": "(480) 371-8373",
            "Postal Code": 86511,
            "Territory": "AZ",
            "State": "AZ",
            "Name": "The National Center American Indian APEX Accelerator - Navajo Nation Arizona Satellite Office",
            "Address Line 1": "Hwy 264",
            "Address Line 2": "100 Taylor Rd",
            "City": "St Michaels",
            "Postal Code": 86511,
            "Phone Number": "(928) 255-1172",
            "Extension": "",
            "email for references": "apex@ncaied.org",
            "website": "http://www.ncaied.org/programs/ptac",
            "Organization Service Area": "Navajo Nation BIA Region - Arizona, New Mexico, Utah"
        }
    },
    {
        "address": "633 West 5th Street, Los Angeles, CA",
        "position": {
            "lat": 34.0510747,
            "lng": -118.2543536
        },
        "info": {
            "State": "CA",
            "Name": "American Indian Chamber Education Fund APEX Accelerator - Headquarters",
            "Address Line 1": "633 West 5th Street",
            "Address Line 2": "26th floor",
            "City": "Los Angeles",
            "Territory": "CA",
            "Postal Code": 90071,
            "Phone Number": "(213) 448-2333",
            "Extension": "",
            "email for references": "http://aicef-apex.org",
            "website": "http://aicef-ptac.org/",
            "Organization Service Area": "CA, AZ, UT, NV, WA, OR, ID - BIA REGION (PACIFIC / WESTERN/NORTHWEST)"
        }
    },
    {
        "address": "5912 Bolsa Avenue, Huntington Beach, CA",
        "position": {
            "lat": 33.7440059,
            "lng": -118.0257195
        },
        "info": {
            "State": "CA",
            "Name": "American Indian Chamber Education Fund APEX Accelerator - California",
            "Address Line 1": "5912 Bolsa Avenue",
            "Address Line 2": "Suite 108",
            "City": "Huntington Beach",
            "Territory": "CA",
            "Postal Code": 92649,
            "Phone Number": "(714) 906-2701",
            "Extension": "",
            "email for references": "ptacinfo@aicccal.org",
            "website": "http://aicef-ptac.org/",
            "Organization Service Area": "CA, AZ, UT, NV, WA, OR, ID - BIA REGION (PACIFIC / WESTERN/NORTHWEST)"
        }
    },
    {
        "address": "1792 Tribute Road, Suite 270, Sacramento, CA",
        "position": {
            "lat": 38.598165,
            "lng": -121.4457451
        },
        "info": {
            "State": "CA",
            "Name": "California Capital APEX Accelerator",
            "Address Line 1": "1792 Tribute Road, Suite 270",
            "Address Line 2": "",
            "City": "Sacramento",
            "Territory": "CA",
            "Postal Code": 95815,
            "Phone Number": "(916) 442-1729",
            "Extension": "",
            "email for references": "inewman@cacapital.org",
            "website": "https://cacapital.org/apex/",
            "Organization Service Area": "Butte, Colusa, El Dorado, Glenn, Lake, Lassen, Modoc, Nevada, Placer, Plumas, Sacramento, Sierra, Sutter, Tehama, Yolo, Yuba"
        }
    },
    {
        "address": "4130 Ardmore Ave, Suite 100, Bakersfield, CA",
        "position": {
            "lat": 35.337555,
            "lng": -119.0491764
        },
        "info": {
            "State": "CA",
            "Name": "Golden State APEX Accelerator",
            "Address Line 1": "4130 Ardmore Ave, Suite 100",
            "Address Line 2": "",
            "City": "Bakersfield",
            "Territory": "CA",
            "Postal Code": 93309,
            "Phone Number": "",
            "Extension": "",
            "email for references": "ptac@goldenstateptac.com",
            "website": "https://goldenstateptac.com/",
            "Organization Service Area": "Kern, Santa Barbara, San Luis Obispo, Ventura"
        }
    },
    {
        "address": "4716 East Cesar E Chavez Ave., Los Angeles, CA",
        "position": {
            "lat": 34.0403423,
            "lng": -118.1644805
        },
        "info": {
            "State": "CA",
            "Name": "Los Angeles County APEX Accelerator",
            "Address Line 1": "4716 East Cesar E Chavez Ave.",
            "Address Line 2": "Building B",
            "City": "Los Angeles",
            "Territory": "CA",
            "Postal Code": 90022,
            "Phone Number": "(323) 881-3964",
            "Extension": "",
            "email for references": "ecgonzalez@opportunity.lacounty.gov",
            "website": "LA County APEX Accelerator link",
            "Organization Service Area": "The County of Los Angeles"
        }
    },
    {
        "address": "123 Capitol Street, Suite B, Salinas, CA",
        "position": {
            "lat": 36.67517309999999,
            "lng": -121.660374
        },
        "info": {
            "State": "CA",
            "Name": "Monterey Bay APEX Accelerator",
            "Address Line 1": "123 Capitol Street, Suite B",
            "Address Line 2": "",
            "City": "Salinas",
            "Territory": "CA",
            "Postal Code": 93901,
            "Phone Number": "(831) 216-3000",
            "Extension": "",
            "email for references": "apex@mcbc.biz",
            "website": "https://montereybayapex.org/",
            "Organization Service Area": "Alpine, Fresno, Kings, Madera, Mariposa, Merced, Monterey, San Benito, Santa Cruz, San Joaquin, Stanislaus, Tulare, Tuolumne counties."
        }
    },
    {
        "address": "1 Harpst Street, Arcata, CA",
        "position": {
            "lat": 40.8744392,
            "lng": -124.0790979
        },
        "info": {
            "State": "CA",
            "Name": "Norcal APEX Accelerator",
            "Address Line 1": "1 Harpst Street",
            "Address Line 2": "Cal Poly Humboldt",
            "City": "Arcata",
            "Territory": "CA",
            "Postal Code": 95521,
            "Phone Number": "(707) 267-7561",
            "Extension": "",
            "email for references": "info@apexnorcal.org",
            "website": "https://apexnorcal.org",
            "Organization Service Area": "Alameda, Contra Costa, Del Norte, Humboldt, Marin, Mendocino, Napa, San Francisco, San Mateo, Santa Clara, Shasta, Siskiyou, Sonoma, Solano, Trinity"
        }
    },
    {
        "address": "3801 Market Street, Riverside, CA",
        "position": {
            "lat": 33.9823287,
            "lng": -117.3760753
        },
        "info": {
            "State": "CA",
            "Name": "Inland Empire APEX Accelerator",
            "Address Line 1": "3801 Market Street",
            "Address Line 2": "",
            "City": "Riverside",
            "Territory": "CA",
            "Postal Code": 92501,
            "Phone Number": "(951) 222-8887",
            "Extension": "",
            "email for references": "IEApexAccelerator@rccd.edu",
            "website": "https://www.rccdptac.com/",
            "Organization Service Area": "Riverside, San Bernardino counties only."
        }
    },
    {
        "address": "880 National City Blvd, National City, CA",
        "position": {
            "lat": 32.6748741,
            "lng": -117.1071717
        },
        "info": {
            "State": "CA",
            "Name": "San Diego, Orange, Imperial APEX Accelerator (Southwestern College Foundation)",
            "Address Line 1": "880 National City Blvd",
            "Address Line 2": "Suite 7100",
            "City": "National City",
            "Territory": "CA",
            "Postal Code": 91950,
            "Phone Number": "(619) 216-6671",
            "Extension": "",
            "email for references": "msabellico@swccd.edu",
            "website": "https://socalptac.org",
            "Organization Service Area": "San Diego, Orange, Imperial Counties, California"
        }
    },
    {
        "address": "559 E. Pikes Peak Ave., Colorado Springs, CO",
        "position": {
            "lat": 38.8331316,
            "lng": -104.813634
        },
        "info": {
            "State": "CO",
            "Name": "Colorado APEX Accelerator",
            "Address Line 1": "559 E. Pikes Peak Ave.",
            "Address Line 2": "Suite 101",
            "City": "Colorado Springs",
            "Territory": "CO",
            "Postal Code": 80903,
            "Phone Number": "(719) 667-3845",
            "Extension": "",
            "email for references": "p.mcglew@coloradoptac.org",
            "website": "https://www.coloradoptac.org",
            "Organization Service Area": "Adams, Alamosa, Arapahoe, Archuleta, Baca, Bent, Boulder, City & County of Broomfield, Chaffee, Cheyenne, Clear Creek, Conejos, Costilla, Crowley, Custer, Delta, Denver City and County, Dolores, Douglas, Eagle, Elbert, El Paso, Fremont, Garfield, Gilpin, Grand, Gunnison, Hinsdale, Huerfano, Jackson, Jefferson, Kiowa, Kit Carson, La Plata, Lake, Larimer, Las Animas, Lincoln, Logan, Mesa, Mineral, Moffat, Montezuma, Montrose, Morgan, Otero, Ouray, Park, Phillips, Pitkin, Prowers, Pueblo, Rio Blanco, Rio Grande, Routt, Saguache, San Juan, San Miguel, Sedgwick, Summit, Teller, Washington, Weld, Yuma"
        }
    },
    {
        "address": "14305 E. Alameda Ave, Suite 300, Aurora, CO",
        "position": {
            "lat": 39.7117462,
            "lng": -104.8211319
        },
        "info": {
            "State": "CO",
            "Name": "Colorado APEX Accelerator - Aurora Satellite Office",
            "Address Line 1": "14305 E. Alameda Ave, Suite 300",
            "Address Line 2": "",
            "City": "Aurora",
            "Territory": "CO",
            "Postal Code": 80012,
            "Phone Number": "(303) 365-4921",
            "Extension": "",
            "email for references": "",
            "website": "https://www.coloradoptac.org",
            "Organization Service Area": ""
        }
    },
    {
        "address": "1667 Cole Blvd, Bldg. 19, Golden, CO",
        "position": {
            "lat": 39.7419555,
            "lng": -105.1549525
        },
        "info": {
            "State": "CO",
            "Name": "Colorado APEX Accelerator - Golden",
            "Address Line 1": "1667 Cole Blvd, Bldg. 19",
            "Address Line 2": "Suite 400",
            "City": "Golden",
            "Territory": "CO",
            "Postal Code": 80401,
            "Phone Number": "(719) 493-0553",
            "Extension": "",
            "email for references": "L.Gunning@ColoradoPTAC.org",
            "website": "https://www.coloradoptac.org",
            "Organization Service Area": ""
        }
    },
    {
        "address": "Stratus Information Building, Ft. Collins, CO",
        "position": {
            "lat": 40.5852602,
            "lng": -105.084423
        },
        "info": {
            "State": "CO",
            "Name": "Colorado APEX Accelerator - Ft. Collins",
            "Address Line 1": "Stratus Information Building",
            "Address Line 2": "2120 South College Ave",
            "City": "Ft. Collins",
            "Territory": "CO",
            "Postal Code": 80522,
            "Phone Number": "(719) 644-8210",
            "Extension": "",
            "email for references": "c.terrell@coloradoptac.org",
            "website": "https://www.coloradoptac.org",
            "Organization Service Area": ""
        }
    },
    {
        "address": "Advanced Space Building, Westminster, CO",
        "position": {
            "lat": 39.9172063,
            "lng": -105.0030544
        },
        "info": {
            "State": "CO",
            "Name": "Colorado APEX Accelerator - Westminster Satellite Office",
            "Address Line 1": "Advanced Space Building",
            "Address Line 2": "12200 Pecos Street, Ste 100",
            "City": "Westminster",
            "Territory": "CO",
            "Postal Code": 80234,
            "Phone Number": "(303) 453-8512",
            "Extension": "",
            "email for references": "t.thompson@ColoradoPTAC.org",
            "website": "https://www.coloradoptac.org",
            "Organization Service Area": ""
        }
    },
    {
        "address": "12503 E. Euclid Dr. STE 200, Centennial, CO",
        "position": {
            "lat": 39.5959003,
            "lng": -104.8431818
        },
        "info": {
            "State": "CO",
            "Name": "The National Center American Indian APEX Accelerator - Colorado Satellite Office",
            "Address Line 1": "12503 E. Euclid Dr. STE 200",
            "Address Line 2": "",
            "City": "Centennial",
            "Territory": "CO",
            "Postal Code": 80111,
            "Phone Number": "(480) 824-8520",
            "Extension": "",
            "email for references": "apex@ncaied.org",
            "website": "https://www.ncaied.org/ptac/",
            "Organization Service Area": "Southwestern BIA Region - Colorado, New Mexico"
        }
    },
    {
        "address": "19 B Thames Street, Groton, CT",
        "position": {
            "lat": 41.3619813,
            "lng": -72.08415049999999
        },
        "info": {
            "State": "CT",
            "Name": "Connecticut APEX - Headquarters",
            "Address Line 1": "19 B Thames Street",
            "Address Line 2": "",
            "City": "Groton",
            "Territory": "CT",
            "Postal Code": "06340-3652",
            "Phone Number": "(860) 437-4659",
            "Extension": 208,
            "email for references": "ptac@ctptac.org",
            "website": "https://www.ctptac.org",
            "Organization Service Area": "Fairfield, Hartford, Litchfield, Middlesex, New Haven, New London, Tolland, Windham"
        }
    },
    {
        "address": "805 Brook Street, Bldg. 4 (CERC), Rocky Hill, CT",
        "position": {
            "lat": 41.6437882,
            "lng": -72.6748483
        },
        "info": {
            "State": "CT",
            "Name": "Connecticut APEX - Rocky Hill",
            "Address Line 1": "805 Brook Street, Bldg. 4 (CERC)",
            "Address Line 2": "",
            "City": "Rocky Hill",
            "Territory": "CT",
            "Postal Code": "06067-3406",
            "Phone Number": "(860) 437-4659",
            "Extension": 208,
            "email for references": "ptac@ctptac.org",
            "website": "https://www.ctptac.org",
            "Organization Service Area": "Fairfield, Hartford, Litchfield, Middlesex, New Haven, New London, Tolland, Windham"
        }
    },
    {
        "address": "One Landmark Square, Stamford, CT",
        "position": {
            "lat": 41.054523,
            "lng": -73.5375993
        },
        "info": {
            "State": "CT",
            "Name": "Connecticut APEX - Stamford",
            "Address Line 1": "One Landmark Square",
            "Address Line 2": "Suite 300",
            "City": "Stamford",
            "Territory": "CT",
            "Postal Code": "06901",
            "Phone Number": "(203) 487-6879",
            "Extension": "",
            "email for references": "ptac@ctptac.org",
            "website": "https://www.ctptac.org",
            "Organization Service Area": "Fairfield, Hartford, Litchfield, Middlesex, New Haven, New London, Tolland, Windham"
        }
    },
    {
        "address": "222 Pitkin Street, East Hartford, CT",
        "position": {
            "lat": 41.7642644,
            "lng": -72.6566007
        },
        "info": {
            "State": "CT",
            "Name": "Connecticut APEX - East Hartford",
            "Address Line 1": "222 Pitkin Street",
            "Address Line 2": "Suite 101",
            "City": "East Hartford",
            "Territory": "CT",
            "Postal Code": "06106",
            "Phone Number": "",
            "Extension": "",
            "email for references": "ptac@ctptac.org",
            "website": "https://www.ctptac.org",
            "Organization Service Area": "Fairfield, Hartford, Litchfield, Middlesex, New Haven, New London, Tolland, Windham"
        }
    },
    {
        "address": "70 West River Street, Milford, CT",
        "position": {
            "lat": 41.2261574,
            "lng": -73.059384
        },
        "info": {
            "State": "CT",
            "Name": "Connecticut APEX - Milford",
            "Address Line 1": "70 West River Street",
            "Address Line 2": "2nd Floor",
            "City": "Milford",
            "Territory": "CT",
            "Postal Code": "06460",
            "Phone Number": "",
            "Extension": "",
            "email for references": "ptac@ctptac.org",
            "website": "https://www.ctptac.org",
            "Organization Service Area": "Fairfield, Hartford, Litchfield, Middlesex, New Haven, New London, Tolland, Windham"
        }
    },
    {
        "address": "10 Middle Street, Bridgeport, CT",
        "position": {
            "lat": 41.1786219,
            "lng": -73.1880848
        },
        "info": {
            "State": "CT",
            "Name": "Connecticut APEX - Bridgeport",
            "Address Line 1": "10 Middle Street",
            "Address Line 2": "14th floor",
            "City": "Bridgeport",
            "Territory": "CT",
            "Postal Code": "06604",
            "Phone Number": "",
            "Extension": "",
            "email for references": "ptac@ctptac.org",
            "website": "https://www.ctptac.org",
            "Organization Service Area": "Fairfield, Hartford, Litchfield, Middlesex, New Haven, New London, Tolland, Windham"
        }
    },
    {
        "address": "441 4th Street, NW, Washington, DC",
        "position": {
            "lat": 38.8954713,
            "lng": -77.0156037
        },
        "info": {
            "State": "DC",
            "Name": "DC APEX Accelerator",
            "Address Line 1": "441 4th Street, NW",
            "Address Line 2": "Suite 850N",
            "City": "Washington",
            "Territory": "DC",
            "Postal Code": 20001,
            "Phone Number": "(202) 727-3900",
            "Extension": "",
            "email for references": "Milton.Goodman@dc.gov",
            "website": "https://dslbd.dc.gov/service/dc-procurement-technical-assistance-center",
            "Organization Service Area": "District of Columbia"
        }
    },
    {
        "address": "591 Collaboration Way, Newark, DE",
        "position": {
            "lat": 39.6631055,
            "lng": -75.7576441
        },
        "info": {
            "State": "DE",
            "Name": "University of Delaware APEX Accelerator",
            "Address Line 1": "591 Collaboration Way",
            "Address Line 2": "2nd Floor",
            "City": "Newark",
            "Territory": "DE",
            "Postal Code": 19713,
            "Phone Number": "(302) 831-0780",
            "Extension": "",
            "email for references": "wblaney@udel.edu",
            "website": "https://www.delawareapex.org",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "220 West Garden Street, Pensacola, FL",
        "position": {
            "lat": 30.4129639,
            "lng": -87.2188735
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Main Office",
            "Address Line 1": "220 West Garden Street",
            "Address Line 2": "Suite 302",
            "City": "Pensacola",
            "Territory": "FL",
            "Postal Code": 32502,
            "Phone Number": "(850) 898-3485",
            "Extension": "",
            "email for references": "jtenney@uwf.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "625 E Tennessee St, Tallahassee, FL",
        "position": {
            "lat": 30.4443114,
            "lng": -84.27344099999999
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Tallahassee",
            "Address Line 1": "625 E Tennessee St",
            "Address Line 2": "Suite 200",
            "City": "Tallahassee",
            "Territory": "FL",
            "Postal Code": 32308,
            "Phone Number": "(850) 599-3407",
            "Extension": "",
            "email for references": "emery.parker@famu.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Franklin, Gadsden, Jefferson, Leon, Liberty, Madison, Taylor, Wakulla"
        }
    },
    {
        "address": "901 NW 35th Street, Boca Raton, FL",
        "position": {
            "lat": 26.3836038,
            "lng": -80.10044719999999
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Boca Raton",
            "Address Line 1": "901 NW 35th Street",
            "Address Line 2": "",
            "City": "Boca Raton",
            "Territory": "FL",
            "Postal Code": 33431,
            "Phone Number": "(954) 224-7202",
            "Extension": "",
            "email for references": "apexaccelerator@fau.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Broward, Palm Beach"
        }
    },
    {
        "address": "3652 FAU Boulevard #400, Boca Raton, FL",
        "position": {
            "lat": 26.3827415,
            "lng": -80.0978475
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Boca Raton (Satellite)",
            "Address Line 1": "3652 FAU Boulevard #400",
            "Address Line 2": "",
            "City": "Boca Raton",
            "Territory": "FL",
            "Postal Code": 33431,
            "Phone Number": "(561) 901-2588",
            "Extension": "",
            "email for references": "apexaccelerator@fau.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Broward, Palm Beach"
        }
    },
    {
        "address": "10501 FGCU Blvd. S, Ft. Myers, FL",
        "position": {
            "lat": 26.4570665,
            "lng": -81.7686214
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Fort Myers",
            "Address Line 1": "10501 FGCU Blvd. S",
            "Address Line 2": "",
            "City": "Ft. Myers",
            "Territory": "FL",
            "Postal Code": 33965,
            "Phone Number": "(239) 745-3700",
            "Extension": "",
            "email for references": "modell@fgcu.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Charlotte, Collier, Glades, Hendry, Lee"
        }
    },
    {
        "address": "FIU Downtown on Brickell, 1101 Brickell Avenue, Miami, FL",
        "position": {
            "lat": 25.7631858,
            "lng": -80.1909501
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Miami",
            "Address Line 1": "FIU Downtown on Brickell, 1101 Brickell Avenue",
            "Address Line 2": "South Tower Penthouse, 11th floor",
            "City": "Miami",
            "Territory": "FL",
            "Postal Code": 33131,
            "Phone Number": "(305) 779-9230",
            "Extension": "",
            "email for references": "lubatist@fiu.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Miami-Dade, Monroe"
        }
    },
    {
        "address": "3209 Virginia Avenue, Fort Pierce, FL",
        "position": {
            "lat": 27.423055,
            "lng": -80.3563818
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Fort Pierce",
            "Address Line 1": "3209 Virginia Avenue",
            "Address Line 2": "Rm Y101",
            "City": "Fort Pierce",
            "Territory": "FL",
            "Postal Code": "34981-5541",
            "Phone Number": "(772) 462-4942",
            "Extension": "",
            "email for references": "scotty.wilson@FloridaPTAC.org",
            "website": "https://fptac.org/",
            "Organization Service Area": "Indian River, Martin, Okeechobee, St. Lucie"
        }
    },
    {
        "address": "1519 Clearlake Rd, Cocoa, FL",
        "position": {
            "lat": 28.3838714,
            "lng": -80.7592177
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Cocoa (Satellite)",
            "Address Line 1": "1519 Clearlake Rd",
            "Address Line 2": "Building 3, Rm 115",
            "City": "Cocoa",
            "Territory": "FL",
            "Postal Code": 32922,
            "Phone Number": "(321) 433-7386",
            "Extension": "",
            "email for references": "kara.vernon@ucf.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Brevard, Flager, Lake, Orange, Osceola, Seminole, Sumter, Volusia"
        }
    },
    {
        "address": "3201 E. Colonial Drive, Orlando, FL",
        "position": {
            "lat": 28.556271,
            "lng": -81.34080089999999
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Orlando",
            "Address Line 1": "3201 E. Colonial Drive",
            "Address Line 2": "Suite A-20",
            "City": "Orlando",
            "Territory": "FL",
            "Postal Code": 32803,
            "Phone Number": "(407) 420-4859",
            "Extension": "",
            "email for references": "steve.south@ucf.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Brevard, Flager, Lake, Orange, Osceola, Seminole, Sumter, Volusia"
        }
    },
    {
        "address": "12000 Alumni Dr. University Center, Jacksonville, FL",
        "position": {
            "lat": 30.2604507,
            "lng": -81.5020171
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Jacksonville",
            "Address Line 1": "12000 Alumni Dr. University Center",
            "Address Line 2": "",
            "City": "Jacksonville",
            "Territory": "FL",
            "Postal Code": 32224,
            "Phone Number": "(904) 620-2053",
            "Extension": "",
            "email for references": "smallbiz@unf.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Alachua, Baker, Bradford, Citrus, Clay, Columbia, Dixie, Duval, Gilchrist, Hamilton, Lafayette, Levy, Marion, Nassau, Putnam, St. Johns, Suwannee, Union"
        }
    },
    {
        "address": "13805 58th Street, North Clearwater, FL",
        "position": {
            "lat": 27.8974519,
            "lng": -82.7117694
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Clearwater",
            "Address Line 1": "13805 58th Street",
            "Address Line 2": "",
            "City": "North Clearwater",
            "Territory": "FL",
            "Postal Code": 33760,
            "Phone Number": "(727) 453-7200",
            "Extension": "",
            "email for references": "ygoodloe@co.pinellas.fl.us",
            "website": "https://fptac.org/",
            "Organization Service Area": "DeSoto, Hardee, Hernando, Highlands, Hillsborough, Manatee, Pasco, Pinellas, Polk, Sarasota"
        }
    },
    {
        "address": "USF CONNECT, 3802 Spectrum Blvd, Tampa, FL",
        "position": {
            "lat": 28.0557043,
            "lng": -82.4152143
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Tampa",
            "Address Line 1": "USF CONNECT, 3802 Spectrum Blvd",
            "Address Line 2": "Suite 201",
            "City": "Tampa",
            "Territory": "FL",
            "Postal Code": 33612,
            "Phone Number": "(813) 396-2700",
            "Extension": "",
            "email for references": "kkrymski@usf.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "DeSoto, Hardee, Hernando, Highlands, Hillsborough, Manatee, Pasco, Pinellas, Polk, Sarasota"
        }
    },
    {
        "address": "11000 University Parkway, Pensacola, FL",
        "position": {
            "lat": 30.5434177,
            "lng": -87.221513
        },
        "info": {
            "State": "FL",
            "Name": "Florida APEX Accelerator - Pensacola",
            "Address Line 1": "11000 University Parkway",
            "Address Line 2": "Building 76, suite 109",
            "City": "Pensacola",
            "Territory": "FL",
            "Postal Code": 32514,
            "Phone Number": "(850) 474-2386",
            "Extension": "",
            "email for references": "cwells1@uwf.edu",
            "website": "https://fptac.org/",
            "Organization Service Area": "Bay, Calhoun, Escambia, Gulf, Holmes, Jackson, Okaloosa, Santa Rosa, Walton, Washington"
        }
    },
    {
        "address": "125 Pine Street, Albany, GA",
        "position": {
            "lat": 31.5789115,
            "lng": -84.1503627
        },
        "info": {
            "State": "GA",
            "Name": "Georgia Tech Procurement Assistance Center - Albany Office",
            "Address Line 1": "230 South Jackson St",
            "Address Line 2": "Suite 218",
            "City": "Albany",
            "Territory": "GA",
            "Postal Code": 31707,
            "Phone Number": "(229) 430-4189",
            "Extension": "",
            "email for references": "bridget.bennett@innovate.gatech.edu",
            "website": "https://gtapexaccelerator.org/",
            "Organization Service Area": "Baker, Ben Hill, Berrien, Brooks, Calhoun, Clay,  Colquitt, Cook, Decatur, Dougherty, Early, Echols,  Grady, Irwin, Lee, Lowndes, Miller, Mitchell, Quitman, Randolph, Schley, Seminole, Stewart, Sumter, Terrell, Thomas, Tift, Turner, Webster, Westminster,  Worth."
        }
    },
    {
        "address": "1120 15th Street, Augusta, GA",
        "position": {
            "lat": 33.4699171,
            "lng": -81.9897417
        },
        "info": {
            "State": "GA",
            "Name": "Georgia Tech Procurement Assistance Center - Augusta Office",
            "Address Line 1": "2907 Professional Parkway Suite 200",
            "Address Line 2": "",
            "City": "Augusta",
            "Territory": "GA",
            "Postal Code": 30907,
            "Phone Number": "(803) 507-9986",
            "Extension": "",
            "email for references": "david.hepner@innovate.gatech.edu",
            "website": "https://gtapexaccelerator.org/",
            "Organization Service Area": "Burke, Columbia, Emanuel, Glascock, Jefferson, Jenkins, Johnson, Lincoln, McDuffie, Richmond, Screven,Taliaferro, Washington, Warren, Wilkes."
        }
    },
    {
        "address": "1601 Maple Street, Carrollton, GA",
        "position": {
            "lat": 33.5715066,
            "lng": -85.10335599999999
        },
        "info": {
            "State": "GA",
            "Name": "Georgia Tech Procurement Assistance Center - Carrollton Office",
            "Address Line 1": "1601 Maple Street",
            "Address Line 2": "",
            "City": "Carrollton",
            "Territory": "GA",
            "Postal Code": 30118,
            "Phone Number": "(773) 530-9869",
            "Extension": "",
            "email for references": "annette.bettis@innovate.gatech.edu",
            "website": "https://gtapexaccelerator.org/",
            "Organization Service Area": "Bartow,Carroll, Catoosa, Chattooga, Cherokee, Cobb, Dade, Douglas, Fannin, Floyd, Gilmer, Gordon, Haralson, Murray,  Murray, Paulding, Pickens, Polk, Walker, Whitfield"
        }
    },
    {
        "address": "133 Main Street, LaGrange, GA",
        "position": {
            "lat": 33.0377947,
            "lng": -85.0308257
        },
        "info": {
            "State": "GA",
            "Name": "Georgia Tech Procurement Assistance Center - LaGrange Office",
            "Address Line 1": "133 Main Street",
            "Address Line 2": "",
            "City": "LaGrange",
            "Territory": "GA",
            "Postal Code": 30240,
            "Phone Number": "(706) 407-1314",
            "Extension": "",
            "email for references": "heather.honerkamp@innovate.gatech.edu",
            "website": "https://gtapexaccelerator.org/",
            "Organization Service Area": "Butts, Chattahoochee, Clayton, Coweta, Fayette, Harris, Heard, Henry, Lamar, Marion, Meriwether, Muscogee, Pike, Spalding, Talbot, Taylor, Troup, Upson"
        }
    },
    {
        "address": "210 Technology Circle, Savannah, GA",
        "position": {
            "lat": 32.1681393,
            "lng": -81.2115183
        },
        "info": {
            "State": "GA",
            "Name": "Georgia Tech Procurement Assistance Center - Savannah Office",
            "Address Line 1": "210 Technology Circle",
            "Address Line 2": "Room 230, Training is in Room 227",
            "City": "Savannah",
            "Territory": "GA",
            "Postal Code": "31407-3039",
            "Phone Number": "(912) 963-2524",
            "Extension": "",
            "email for references": "larry.blige@innovate.gatech.edu",
            "website": "https://gtapexaccelerator.org/",
            "Organization Service Area": "Appling, Atkinson, Bacon, Brantley, Bryan, Bulloch, Camden, Candler, Chatham, Charlton, Clinch, Coffee, Effingham, Evans, Glynn, Jeff Davis, Lanier, Liberty, Long, McIntosh, Montgomery, Pierce, Tattnall, Telfair, Toombs, Treutlen, Ware, Wayne, Wheeler"
        }
    },
    {
        "address": "151 Osigian Blvd, Warner Robins, GA",
        "position": {
            "lat": 32.6131476,
            "lng": -83.6881087
        },
        "info": {
            "State": "GA",
            "Name": "Georgia Tech Procurement Assistance Center - Warner Robins Office",
            "Address Line 1": "151 Osigian Blvd",
            "Address Line 2": "Suite 157",
            "City": "Warner Robins",
            "Territory": "GA",
            "Postal Code": 31088,
            "Phone Number": "(478) 953-1460",
            "Extension": "",
            "email for references": "aileen.zoellner@innovate.gatech.edu",
            "website": "https://gtapexaccelerator.org/",
            "Organization Service Area": "Bibb, Bleckley, Crawford, Crisp, Dodge, Dooly, Houston, Jones, Laurens, Macon, Monroe, Peach, Pulaski, Twiggs, Wilcox, Wilkinson"
        }
    },
    {
        "address": "75 - 5th St., NW, Atlanta, GA",
        "position": {
            "lat": 33.77744300000001,
            "lng": -84.38912309999999
        },
        "info": {
            "State": "GA",
            "Name": "Georgia Tech Procurement Assistance Center - Atlanta Office",
            "Address Line 1": "75 - Fifth St., NW",
            "Address Line 2": "",
            "City": "Atlanta",
            "Territory": "GA",
            "Postal Code": 30308,
            "Phone Number": "(404) 894-3512, (678) 653-2177",
            "Extension": "",
            "email for references": "jennifer.white@innovate.gatech.edu, will.parker@innovate.gatech.edu",
            "website": "https://gtapexaccelerator.org/",
            "Organization Service Area": "DeKalb, Fulton, Rockdale"
        }
    },
    {
        "address": "3041 Landrum Education Dr., Oakwood, GA",
        "position": {
            "lat": 34.2347792,
            "lng": -83.86205319999999
        },
        "info": {
            "State": "GA",
            "Name": "Georgia Tech Procurement Assistance Center - Gainesville Office",
            "Address Line 1": "3041 Landrum Education Dr.",
            "Address Line 2": "UNG - Oakwood Building, Room 133",
            "City": "Oakwood",
            "Territory": "GA",
            "Postal Code": 30566,
            "Phone Number": "(770) 847-6694",
            "Extension": "",
            "email for references": "chioma.anosike@innovate.gatech.edu",
            "website": "https://gtapexaccelerator.org/",
            "Organization Service Area": "Banks, Barrow, Dawson, Forsyth, Gwinnett, Habersham, Hall, Jackson, Lumpkin, Rabun, Stephens, Towns, Union, White"
}
    },
    {
        "address": "86 South Cobb Drive, Marietta, GA",
        "position": {
            "lat": 33.9310788,
            "lng": -84.5332936
        },
        "info": {
        "Address Line 1": "86 South Cobb Drive",
        "Address Line 2": "MZ 0510",
        "City": "Marietta",
        "email for references": "apex@ncaied.org",
        "Extension": "",
        "Name": "The National Center APEX Accelerator- Georgia Main Office",
        "Organization Service Area": "Southwest, Navajo, Eastern BIA Regions.",
        "Phone Number": "(480) 280-6098",
        "Postal Code": "30063-0510",
        "State": "GA",
        "Territory": "GA",
        "website": "https://www.ncaied.org/ptac"
        }
    },
    {
        "address": "303 University Dr, Mangilao, Guam, GU",
        "position": {
            "lat": 13.4321551,
            "lng": 144.7998269
        },
        "info": {
            "State": "GU",
            "Name": "Guam APEX Accelerator",
            "Address Line 1": "303 University Dr",
            "Address Line 2": "",
            "City": "Mangilao, Guam",
            "Territory": "GU",
            "Postal Code": "96913-1800",
            "Phone Number": "(671) 735-2596",
            "Extension": "",
            "email for references": "admin@guamptac.com",
            "website": "https://www.guamptac.com",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "677 Ala Moana Blvd. Ste 612, Honolulu, HI",
        "position": {
            "lat": 21.2974428,
            "lng": -157.862271
        },
        "info": {
            "State": "HI",
            "Name": "Hawaii APEX Accelerator",
            "Address Line 1": "677 Ala Moana Blvd. Ste 612",
            "Address Line 2": "",
            "City": "Honolulu",
            "Territory": "HI",
            "Postal Code": 96813,
            "Phone Number": "(808) 784-3711",
            "Extension": 109,
            "email for references": "bruce.powers@hiptac.org",
            "website": "https://www.hiptac.org",
            "Organization Service Area": "Kauai, Maui, Kalawao, Hawaii, Honolulu"
        }
    },
    {
        "address": "383 Collins Road NE, Cedar Rapids, IA",
        "position": {
            "lat": 42.0264491,
            "lng": -91.6374805
        },
        "info": {
            "State": "IA",
            "Name": "Iowa State University APEX Accelerator - Cedar Rapids Office",
            "Address Line 1": "383 Collins Road NE",
            "Address Line 2": "Suite 201",
            "City": "Cedar Rapids",
            "Territory": "IA",
            "Postal Code": 52402,
            "Phone Number": "(319) 310-8612",
            "Extension": "",
            "email for references": "jafagle@iastate.edu",
            "website": "https://www.ciras.iastate.edu/government-contracting/",
            "Organization Service Area": "Allamakee, Benton, Black Hawk, Bremer, Buchanan, Butler, Chickasaw, Clayton, Clinton, Delaware, Dubuque, Fayette, Floyd, Grundy, Howard, Jackson, Jones, Linn, Marshall, Mitchell, Tama, Winneshiek."
        }
    },
    {
        "address": "1840 NW 118th Street, Clive, IA",
        "position": {
            "lat": 41.6079533,
            "lng": -93.7777614
        },
        "info": {
            "State": "IA",
            "Name": "Iowa State University APEX Accelerator - Des Moines Office",
            "Address Line 1": "1840 NW 118th Street",
            "Address Line 2": "Ste. 206",
            "City": "Clive",
            "Territory": "IA",
            "Postal Code": 50325,
            "Phone Number": "(515) 620-6265",
            "Extension": "",
            "email for references": "kmfreel@iastate.edu",
            "website": "https://www.ciras.iastate.edu/government-contracting/",
            "Organization Service Area": "Polk"
        }
    },
    {
        "address": "136 S. Dubuque Street, Iowa City, IA",
        "position": {
            "lat": 41.6591667,
            "lng": -91.5323373
        },
        "info": {
            "State": "IA",
            "Name": "Iowa State University APEX Accelerator - Iowa City Office",
            "Address Line 1": "136 S. Dubuque Street",
            "Address Line 2": "",
            "City": "Iowa City",
            "Territory": "IA",
            "Postal Code": 52240,
            "Phone Number": "(319) 333-9558",
            "Extension": "",
            "email for references": "siferm@iastate.edu",
            "website": "https://www.ciras.iastate.edu/government-contracting/",
            "Organization Service Area": "Cedar, Davis, Des Moines, Henry, Iowa, Jefferson, Johnson, Keokuk, Lee, Louisa, Mahaska, Muscatine, Poweshiek, Scott, Van Buren, Wapello, Washington"
        }
    },
    {
        "address": "1805 Collaboration Place, Ames, IA",
        "position": {
            "lat": 41.99109989999999,
            "lng": -93.6365448
        },
        "info": {
            "State": "IA",
            "Name": "Iowa State University APEX Accelerator - Headquarters",
            "Address Line 1": "1805 Collaboration Place",
            "Address Line 2": "Suite 2300",
            "City": "Ames",
            "Territory": "IA",
            "Postal Code": 50010,
            "Phone Number": "(515) 294-3420",
            "Extension": "",
            "email for references": "jnice@iastate.edu",
            "website": "https://www.ciras.iastate.edu/government-contracting/",
            "Organization Service Area": "Adair, Adams, Appanoose, Audubon, Boone, Buena Vista, Calhoun, Carroll, Cass, Cerro Gordo, Cherokee, Clarke, Clay, Crawford, Dallas, Decatur, Dickinson, Emmett, Franklin, Fremont, Greene, Guthrie, Hamilton, Hancock, Hardin, Harrison, Humboldt, Ida, Jasper, Kossuth, Lucas, Lyon, Madison, Marion, Mills, Monona, Monroe, Montgomery, O'Brien, Osceola, Page, Palo Alto, Plymouth, Pocahontas, Pottawattamie, Ringgold, Sac, Shelby, Sioux, Story, Taylor, Union, Warren, Wayne, Webster, Winnebago, Woodbury, Worth, Wright"
        }
    },
    {
        "address": "1101 Avenue H, Carter Lake, IA",
        "position": {
            "lat": 41.2806183,
            "lng": -95.9153821
        },
        "info": {
            "State": "IA",
            "Name": "The PEDCO APEX Accelerator",
            "Address Line 1": "1101 Avenue H",
            "Address Line 2": "Suite B",
            "City": "Carter Lake",
            "Territory": "IA",
            "Postal Code": 51510,
            "Phone Number": "(712) 308-1366",
            "Extension": "",
            "email for references": "info@pedcoptac.org",
            "website": "https://pedcoptac.org",
            "Organization Service Area": "businesses owned by Native American individuals, Tribes and Native Corporations that are based in Iowa, Michigan, Minnesota, Wisconsin. "
        }
    },
    {
        "address": "2756 O Street, Lincoln, IA",
        "position": {
            "lat": 40.8133827,
            "lng": -96.6815723
        },
        "info": {
            "Address Line 1": "2756 O Street",
            "Address Line 2": "",
            "City": "Lincoln",
            "email for references": "jniceswanger@pedcoapex.org",
            "Extension": "",
            "Name": "PEDCO APEX Accelerator",
            "Organization Service Area": "businesses owned by Native American individuals, Tribes and Native Corporations that are based in Iowa, Michigan, Minnesota, Nebraska, and Wisconsin. ",
            "Phone Number": "(531) 310-2494",
            "Postal Code": 68510,
            "State": "IA",
            "Territory": "NE",
            "website": "https://pedco-ne.org/pedco-native-apex-accelerator"
        }
    },
    {
        "address": "5465 East Terra Linda Way, Nampa, ID",
        "position": {
            "lat": 43.6116415,
            "lng": -116.5022814
        },
        "info": {
            "State": "ID",
            "Name": "Idaho APEX Accelerator",
            "Address Line 1": "5465 East Terra Linda Way",
            "Address Line 2": "",
            "City": "Nampa",
            "Territory": "ID",
            "Postal Code": 83687,
            "Phone Number": "208-615-2246",
            "Extension": "",
            "email for references": "idahoapex@boisestate.edu",
            "website": "https://www.idahoapexaccelerator.com",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "141 Jobst Hall, Peoria, IL",
        "position": {
            "lat": 40.6995435,
            "lng": -89.6171849
        },
        "info": {
            "State": "IL",
            "Name": "Illinois APEX Accelerator - Bradley University",
            "Address Line 1": "141 Jobst Hall",
            "Address Line 2": "",
            "City": "Peoria",
            "Territory": "IL",
            "Postal Code": "61625-0001",
            "Phone Number": "(309) 677-3297",
            "Extension": "",
            "email for references": "khuhra@bradley.edu",
            "website": "https://www.bradley.edu/academic/colleges/fcba/centers/turner/centers/ptac/",
            "Organization Service Area": "Adams, Brown, Bureau, Carroll, Cass, Champaign, Christian, Clark, Coles, Coupee, De Witt, Douglas, Edgar, Ford, Fulton, Hancock, Henderson, Henry, Knox, LaSalle Lee, Livingston, Logan, Macon, Marshall, Mason, Mclean, McDonough, Mercer, Menard, Morgan, Moultrie, Ogle, Peoria, Piatt, Putnam, Rock Island, Sangamon, Scott, Shelby, Stark, Tazewell, Vermilion, Warren, Whiteside,Webster, Woodford,"
        }
    },
    {
        "address": "535 Duane Street, Glen Ellyn, IL",
        "position": {
            "lat": 41.8751341,
            "lng": -88.0660209
        },
        "info": {
            "State": "IL",
            "Name": "IL APEX Accelerator - College of DuPage",
            "Address Line 1": "535 Duane Street",
            "Address Line 2": "Office 233",
            "City": "Glen Ellyn",
            "Territory": "IL",
            "Postal Code": 60137,
            "Phone Number": "(630) 942-2184",
            "Extension": "",
            "email for references": "kellyi421@cod.edu",
            "website": "https://www.cod.edu/ptac",
            "Organization Service Area": "Boone, Carroll, Cook, DeKalb, DuPage, Grundy, Jo Daviess, Kane, Kendall, Lake, LaSalle, Lee, McHenry, Ogle, Stephenson, Whiteside, Will, Winnebago"
        }
    },
    {
        "address": "837 W. 115th St., Chicago, IL",
        "position": {
            "lat": 41.6836896,
            "lng": -87.64383939999999
        },
        "info": {
            "State": "IL",
            "Name": "IL APEX Accelerator - Far South Community Development Corp",
            "Address Line 1": "837 W. 115th St.",
            "Address Line 2": "",
            "City": "Chicago",
            "Territory": "IL",
            "Postal Code": 60643,
            "Phone Number": "(773) 830-6562",
            "Extension": "",
            "email for references": "franchon@farsouthcdc.org",
            "website": "http://www.farsouthcdc.org",
            "Organization Service Area": "Cook, Grundy, Kankakee, Iroquois, Will"
        }
    },
    {
        "address": "2106 W. 63rd St., Chicago, IL",
        "position": {
            "lat": 41.779542,
            "lng": -87.6767549
        },
        "info": {
            "State": "IL",
            "Name": "IL APEX Accelerator - Greater Southwest Development Corp.",
            "Address Line 1": "2106 W. 63rd St.",
            "Address Line 2": "",
            "City": "Chicago",
            "Territory": "IL",
            "Postal Code": 60629,
            "Phone Number": "(773) 362-3379",
            "Extension": "",
            "email for references": "ptac@greatersouthwest.org",
            "website": "http://www.greatersouthwest.org",
            "Organization Service Area": "Cook, Grundy, Kankakee, Will"
        }
    },
    {
        "address": "222 W. Merchandise Mart Plaza, Chicago, IL",
        "position": {
            "lat": 41.8884096,
            "lng": -87.6354498
        },
        "info": {
            "State": "IL",
            "Name": "IL APEX Accelerator - IL Hispanic Chamber of Commerce",
            "Address Line 1": "222 W. Merchandise Mart Plaza",
            "Address Line 2": "#1212",
            "City": "Chicago",
            "Territory": "IL",
            "Postal Code": 60654,
            "Phone Number": "(312) 425-9500",
            "Extension": 117,
            "email for references": "ogutierrez@ihccbusiness.net",
            "website": "https://ihccbusiness.net/ihcc-procurement-technical-assistance/",
            "Organization Service Area": "Cook"
        }
    },
    {
        "address": "7600 West Roosevelt Rd., Forest Park, IL",
        "position": {
            "lat": 41.86500909999999,
            "lng": -87.8041999
        },
        "info": {
            "State": "IL",
            "Name": "IL APEX Accelerator - Joseph Center",
            "Address Line 1": "7600 West Roosevelt Rd.",
            "Address Line 2": "",
            "City": "Forest Park",
            "Territory": "IL",
            "Postal Code": 60130,
            "Phone Number": "(708) 697-6142",
            "Extension": "",
            "email for references": "chollingsworth@jbs.edu",
            "website": "http://josephcenter.com/ptac/",
            "Organization Service Area": "Cook, Grundy, Kane, Kankakee, Kendall, Lake, McHenry, Will"
        }
    },
    {
        "address": "1740 Innovation Drive, Carbondale, IL",
        "position": {
            "lat": 37.702835,
            "lng": -89.216776
        },
        "info": {
            "State": "IL",
            "Name": "IL APEX Accelerator - Southern Illinois University - Carbondale",
            "Address Line 1": "1740 Innovation Drive",
            "Address Line 2": "",
            "City": "Carbondale",
            "Territory": "IL",
            "Postal Code": 62903,
            "Phone Number": "(618) 536-2424",
            "Extension": "",
            "email for references": "brenda.j.henderson@siu.edu",
            "website": "https://ptac.siu.edu",
            "Organization Service Area": "Alexander, Clay, Clark, Coles, Cumberland, Edwards, Franklin, Gallatin, Hamilton, Hardin, Jackson, Jasper, Jefferson, Johnson, Lawrence, Massac, Marion, Perry, Pope, Pulaski, Richland, Union, Saline, Wabash, Wayne, White, Williamson"
        }
    },
    {
        "address": "Western Illinois University, Macomb, IL",
        "position": {
            "lat": 40.4705003,
            "lng": -90.68585589999999
        },
        "info": {
            "State": "IL",
            "Name": "IL APEX Accelerator - Western Illinois University",
            "Address Line 1": "Western Illinois University",
            "Address Line 2": "518 Stipes Hall",
            "City": "Macomb",
            "Territory": "IL",
            "Postal Code": 61455,
            "Phone Number": "(618) 420-8313",
            "Extension": "",
            "email for references": "tl-ebeler@wiu.edu",
            "website": "http://www.iira.org/rdrg/illinois-procurement-technical-assistance-center-at-western-illinois-university-illinois-ptac-wiu/",
            "Organization Service Area": "Bond, Christian, Clay, Calhoun, Clinton, Cumberland, Effingham, Fayette, Greene, Jersey, Macoupin, Madison, Marion, Monroe, Montgomery, Morgan, Perry, Pike, Shelby, Randloph, St Clair, Schuyler, Scott, Washington"
        }
    },
    {
        "address": "8 South Michigan Suite 400, Chicago, IL",
        "position": {
            "lat": 41.8819021,
            "lng": -87.6248878
        },
        "info": {
            "State": "IL",
            "Name": "IL APEX Accelerator - Women's Business Development Center",
            "Address Line 1": "8 South Michigan Suite 400",
            "Address Line 2": "",
            "City": "Chicago",
            "Territory": "IL",
            "Postal Code": "60603-3302",
            "Phone Number": "(312) 853-3477",
            "Extension": 680,
            "email for references": "dbeasley@wbdc.org",
            "website": "https://www.wbdc.org/established-programs/procurement-program-services/",
            "Organization Service Area": "Cook"
        }
    },
    {
        "address": "500 E. Monroe, Springfield, IL",
        "position": {
            "lat": 39.799208,
            "lng": -89.6491758
        },
        "info": {
            "State": "IL",
            "Name": "Illinois APEX Accelerator - Headquarters",
            "Address Line 1": "500 E. Monroe",
            "Address Line 2": "",
            "City": "Springfield",
            "Territory": "IL",
            "Postal Code": 62701,
            "Phone Number": "(217) 524-4146",
            "Extension": "",
            "email for references": "darryl.thomas@illinois.gov",
            "website": "http://www.ilsmallbiz.biz/PTAC",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "One North Capitol Avenue, Indianapolis, IN",
        "position": {
            "lat": 39.8101334,
            "lng": -86.1613977
        },
        "info": {
            "State": "IN",
            "Name": "Indiana APEX Accelerator - Central",
            "Address Line 1": "One North Capitol Avenue",
            "Address Line 2": "Suite 700",
            "City": "Indianapolis",
            "Territory": "IN",
            "Postal Code": 46204,
            "Phone Number": "(317) 431-1560",
            "Extension": "",
            "email for references": "nlofton@iedc.in.gov",
            "website": "https://www.indianaptac.com",
            "Organization Service Area": "Vermillion, Parke, Putnam, Boone, Hendricks, Morgan, Johnson, Marion, Hamilton, Hancock, Shelby, Henry, Rush, Fayette, Wayne, Union"
        }
    },
    {
        "address": "200 E. Main St., Ft. Wayne, IN",
        "position": {
            "lat": 41.0805631,
            "lng": -85.1373292
        },
        "info": {
            "State": "IN",
            "Name": "Indiana APEX Accelerator - Northeast",
            "Address Line 1": "200 E. Main St.",
            "Address Line 2": "Suite 910",
            "City": "Ft. Wayne",
            "Territory": "IN",
            "Postal Code": 46802,
            "Phone Number": "(317) 586-1945",
            "Extension": "",
            "email for references": "chgibson@iedc.in.gov",
            "website": "client registration: https://iedc.formstack.com/forms/request_for_government_contracting_assistance",
            "Organization Service Area": "LaGrange, Steuben, Noble, DeKalb, Whitley, Allen, Huntington, Wells, Adams, Grant, Blackford, Jay, Madison, Delaware, Randolph"
        }
    },
    {
        "address": "209 N. Main Street, South Bend, IN",
        "position": {
            "lat": 41.678152,
            "lng": -86.2522557
        },
        "info": {
            "State": "IN",
            "Name": "Indiana APEX Acelerator - North Central",
            "Address Line 1": "209 N. Main Street",
            "Address Line 2": "Suite 204",
            "City": "South Bend",
            "Territory": "IN",
            "Postal Code": 46601,
            "Phone Number": "(219) 214-2126",
            "Extension": "",
            "email for references": "dhunter@iedc.in.gov",
            "website": "",
            "Organization Service Area": "St. Joseph, Elkhart, Marshall, Kosciusko, Fulton, Cass, Wabash, Miami, Howard, Tipton, Clinton"
        }
    },
    {
        "address": "Matchbox Coworking Studio, Lafayette, IN",
        "position": {
            "lat": 40.4168067,
            "lng": -86.8903629
        },
        "info": {
            "State": "IN",
            "Name": "Indiana APEX Accelerator - Northwest",
            "Address Line 1": "Matchbox Coworking Studio",
            "Address Line 2": "17 South 6th Street",
            "City": "Lafayette",
            "Territory": "IN",
            "Postal Code": 47901,
            "Phone Number": "(317) 670-0348",
            "Extension": "",
            "email for references": "chrmeyer@iedc.in.gov",
            "website": "",
            "Organization Service Area": "Lake, Porter, LaPorte, Newton, Jasper, Starke, Pulaski, Benton, White, Carroll, Warren, Tippecanoe, Fountain, Montgomery"
        }
    },
    {
        "address": "642 N. Madison St., Bloomington, IN",
        "position": {
            "lat": 39.1729156,
            "lng": -86.53697369999999
        },
        "info": {
            "State": "IN",
            "Name": "Indiana APEX Accelerator - South Central",
            "Address Line 1": "642 N. Madison St.",
            "Address Line 2": "",
            "City": "Bloomington",
            "Territory": "IN",
            "Postal Code": 47404,
            "Phone Number": "(812) 340-4342",
            "Extension": "",
            "email for references": "aalexander@iedc.in.gov",
            "website": "",
            "Organization Service Area": "Vigo, Clay, Owen, Sullivan, Greene, Monroe, Brown, Lawrence, Jackson, Bartholomew, Decatur, Franklin, Jennings, Ripley, Dearborn, Washington, Scott, Jefferson, Switzerland, Ohio, Clark, Floyd"
        }
    },
    {
        "address": "318 Main Street, Evansville, IN",
        "position": {
            "lat": 37.9716092,
            "lng": -87.57163969999999
        },
        "info": {
            "State": "IN",
            "Name": "Indiana APEX Accelerator - Southwest",
            "Address Line 1": "318 Main Street",
            "Address Line 2": "Suite 401",
            "City": "Evansville",
            "Territory": "IN",
            "Postal Code": 47708,
            "Phone Number": "(812) 340-5174",
            "Extension": "",
            "email for references": "jhaddan@iedc.in.gov",
            "website": "",
            "Organization Service Area": "Knox, Daviess, Martin, Orange, Gibson, Pike, Dubois, Crawford, Harrison, Posey, Vanderburgh, Warrick, Spencer, Perry"
        }
    },
    {
        "address": "Regnier Center, Room 240, Overland Park, KS",
        "position": {
            "lat": 38.9243379,
            "lng": -94.726621
        },
        "info": {
            "State": "KS",
            "Name": "Kansas APEX Accelerator - Kansas City Subcenter",
            "Address Line 1": "Regnier Center, Room 240",
            "Address Line 2": "12345 College Blvd",
            "City": "Overland Park",
            "Territory": "KS",
            "Postal Code": 66210,
            "Phone Number": "(913) 469-8500",
            "Extension": 2313,
            "email for references": "kmuldrow@jccc.edu",
            "website": "https://www.jccc.edu/community-resources/ptac/",
            "Organization Service Area": "Kansas City Area"
        }
    },
    {
        "address": "1845 Fairmount St., Wichita, KS",
        "position": {
            "lat": 37.7164839,
            "lng": -97.29597
        },
        "info": {
            "State": "KS",
            "Name": "Kansas APEX Accelerator",
            "Address Line 1": "1845 Fairmount St.",
            "Address Line 2": "",
            "City": "Wichita",
            "Territory": "KS",
            "Postal Code": "67260-0215",
            "Phone Number": "(316) 978-6788",
            "Extension": "",
            "email for references": "kansasptac@wichita.edu",
            "website": "http://www.kansasptac.org/",
            "Organization Service Area": "Allen, Anderson, Atchison, Barber, Barton, Bourbon, Brown, Butler, Chase, Chautauqua, Cherokee, Cheyenne, Clark, Clay, Cloud, Coffey, Comanche, Cowley, Crawford, Decatur, Dickinson, Doniphan, Douglas, Edwards, Elk, Ellis, Ellsworth, Finney, Ford, Franklin, Geary, Gove, Graham, Grant, Gray, Greeley, Greenwood, Hamilton, Harper, Harvey, Haskell, Hodgeman, Jackson, Jefferson, Jewell, Johnson, Kearny, Kingman, Kiowa, Labette, Lane, Leavenworth, Lincoln, Linn, Logan, Lyon, McPherson, Marion, Marshall, Meade, Miami, Mitchell, Montgomery, Morris, Morton, Nemaha, Neosho, Ness, Norton, Osage, Osborne, Ottawa, Pawnee, Phillips, Pottawatomie, Pratt, Rawlins, Reno, Republic, Rice, Riley, Rooks, Rush, Russell, Saline, Scott, Sedgwick, Seward, Shawnee, Sheridan, Sherman, Smith, Stafford, Stanton, Stevens, Sumner, Thomas, Trego, Wabaunsee, Wallace, Washington, Wichita, Wilson, Woodson, Wyandotte"
        }
    },
    {
        "address": "380 South Mill Street, Lexington, KY",
        "position": {
            "lat": 38.0449649,
            "lng": -84.5026366
        },
        "info": {
            "State": "KY",
            "Name": "Kentucky APEX Accelerator - Lexington (Main office)",
            "Address Line 1": "380 South Mill Street",
            "Address Line 2": "Suite 300",
            "City": "Lexington",
            "Territory": "KY",
            "Postal Code": 40508,
            "Phone Number": "859.251.6019",
            "Extension": "",
            "email for references": "kyapex@kstc.com",
            "website": "https://www.kyapex.com/",
            "Organization Service Area": "Adair, Allen, Anderson, Ballard, Barren, Bath, Bell, Boone, Bourbon, Boyd, Boyle, Bracken, Breathitt, Breckinridge, Bullitt, Butler, Caldwell, Calloway, Campbell, Carlisle, Carroll, Carter, Casey, Christian, Clark, Clay, Clinton, Crittenden, Cumberland, Daviess, Edmonson, Elliott, Estill, Fayette, Fleming, Floyd, Franklin, Fulton, Gallatin, Garrard, Grant, Graves, Grayson, Green, Greenup, Hancock, Hardin, Harlan, Harrison, Hart, Henderson, Henry, Hickman, Hopkins, Jackson, Jefferson, Jessamine, Johnson, Kenton, Knott, Knox, Larue, Laurel, Lawrence, Lee, Leslie, Letcher, Lewis, Lincoln, Livingston, Logan, Lyon, McCracken, McCreary, McLean, Madison, Magoffin, Marion, Marshall, Martin, Mason, Meade, Menifee, Mercer, Metcalfe, Monroe, Montgomery, Morgan, Muhlenberg, Nelson, Nicholas, Ohio, Oldham, Owen, Owsley, Pendleton, Perry, Pike, Powell, Pulaski, Robertson, Rockcastle, Rowan, Russell, Scott, Shelby, Simpson, Spencer, Taylor, Todd, Trigg, Trimble, Union, Warren, Washington, Wayne, Webster, Whitley, Wolfe, Woodford"
        }
    },
    {
        "address": "710 College Street, Bowling Green, KY",
        "position": {
            "lat": 36.9952726,
            "lng": -86.4390531
        },
        "info": {
            "State": "KY",
            "Name": "Kentucky APEX Accelerator - Bowling Green",
            "Address Line 1": "710 College Street",
            "Address Line 2": "",
            "City": "Bowling Green",
            "Territory": "KY",
            "Postal Code": 42101,
            "Phone Number": "",
            "Extension": "",
            "email for references": "kyapex@kstc.com",
            "website": "https://www.kyapex.com/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "112 West Pike Street, Covington, KY",
        "position": {
            "lat": 39.0834448,
            "lng": -84.51212129999999
        },
        "info": {
            "State": "KY",
            "Name": "Kentucky APEX Accelerator - Covington",
            "Address Line 1": "112 West Pike Street",
            "Address Line 2": "",
            "City": "Covington",
            "Territory": "KY",
            "Postal Code": 41011,
            "Phone Number": "",
            "Extension": "",
            "email for references": "kyapex@kstc.com",
            "website": "https://www.kyapex.com/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "1201 North Dixie Highway, Elizabethtown, KY",
        "position": {
            "lat": 37.7188746,
            "lng": -85.8784896
        },
        "info": {
            "State": "KY",
            "Name": "Kentucky APEX Accelerator - Elizabethtown",
            "Address Line 1": "1201 North Dixie Highway",
            "Address Line 2": "Suite 112",
            "City": "Elizabethtown",
            "Territory": "KY",
            "Postal Code": 42701,
            "Phone Number": "",
            "Extension": "",
            "email for references": "kyapex@kstc.com",
            "website": "https://www.kyapex.com/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "2292 S Hwy 27, Somerset, KY",
        "position": {
            "lat": 37.0608849,
            "lng": -84.6196224
        },
        "info": {
            "State": "KY",
            "Name": "Kentucky APEX Accelerator - Morehead",
            "Address Line 1": "2292 S Hwy 27",
            "Address Line 2": "",
            "City": "Somerset",
            "Territory": "KY",
            "Postal Code": 42501,
            "Phone Number": "",
            "Extension": "",
            "email for references": "kyapex@kstc.com",
            "website": "https://www.kyapex.com/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "3121 Broadway, Ste 106, Paducah, KY",
        "position": {
            "lat": 37.0710627,
            "lng": -88.6376267
        },
        "info": {
            "State": "KY",
            "Name": "Kentucky APEX Accelerator - Paducah",
            "Address Line 1": "3121 Broadway, Ste 106",
            "Address Line 2": "",
            "City": "Paducah",
            "Territory": "KY",
            "Postal Code": 42001,
            "Phone Number": "",
            "Extension": "",
            "email for references": "kyapex@kstc.com",
            "website": "https://www.kyapex.com/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "SKED 2292, Somerset, KY",
        "position": {
            "lat": 37.060951,
            "lng": -84.619678
        },
        "info": {
            "State": "KY",
            "Name": "Kentucky APEX Accelerator - Somerset",
            "Address Line 1": "SKED 2292",
            "Address Line 2": "South Highway 27",
            "City": "Somerset",
            "Territory": "KY",
            "Postal Code": 42501,
            "Phone Number": "",
            "Extension": "",
            "email for references": "kyapex@kstc.com",
            "website": "https://www.kyapex.com/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "537 Cajundome Blvd., Lafayette, LA",
        "position": {
            "lat": 30.2199977,
            "lng": -92.04333489999999
        },
        "info": {
            "State": "LA",
            "Name": "Louisiana APEX Accelerator",
            "Address Line 1": "537 Cajundome Blvd.",
            "Address Line 2": "Suite 236",
            "City": "Lafayette",
            "Territory": "LA",
            "Postal Code": 70506,
            "Phone Number": "(337) 482-6422",
            "Extension": "",
            "email for references": "la-apex@louisiana.edu",
            "website": "http://apex.louisiana.edu",
            "Organization Service Area": "Acadia, Allen, Ascension, Assumption, Avoyelles, Beauregard, Calcasieu, Caldwell, Cameron, Catahoula, Concordia, East Baton Rouge, East Carroll, East Feliciana, Evangeline, Franklin, Grant, Iberia, Iberville, Jackson, Jefferson, Jefferson Davis, La Salle, Lafayette, Lafourche, Livingston, Madison, Morehouse, Orleans, Ouachita, Plaquemines, Pointe  Coupee, Rapides, Richland, St. Bernard, St. Charles, St. Helena, St. James, St. John the Baptist, St. Landry, St. Martin, St. Mary, St. Tammany, Tangipahoa, Tensas, Terrebonne, Union, Vermillon, Vernon, Washington, Webster, West Baton Rouge, West Carroll, West Feliciana, Winn"
        }
    },
    {
        "address": "400 Edwards Street, Shreveport, LA",
        "position": {
            "lat": 32.5138503,
            "lng": -93.7496213
        },
        "info": {
            "State": "LA",
            "Name": "NW Louisiana APEX Accelerator",
            "Address Line 1": "400 Edwards Street",
            "Address Line 2": "",
            "City": "Shreveport",
            "Territory": "LA",
            "Postal Code": 71101,
            "Phone Number": "(318) 677-2519",
            "Extension": "",
            "email for references": "gayle@shreveportchamber.org",
            "website": "http://nwlaptac.org/",
            "Organization Service Area": "Bienville, Bossier, Caddo, Claiborne, De soto, Lincoln, Natchitoches, Red River, Sabine"
        }
    },
    {
        "address": "150 Melancon Rd, Marksville, LA",
        "position": {
            "lat": 31.1279652,
            "lng": -92.0662356
        },
        "info": {
            "State": "LA",
            "Name": "The National Center American Indian APEX Accelerator - Louisiana Satellite Office",
            "Address Line 1": "150 Melancon Rd",
            "Address Line 2": "",
            "City": "Marksville",
            "Territory": "LA",
            "Postal Code": 71351,
            "Phone Number": "(480) 255-6625",
            "Extension": "",
            "email for references": "c.jeansonne@ncaied.org",
            "website": "https://www.ncaied.org/ptac",
            "Organization Service Area": "Southeastern BIA Region - Louisiana"
        }
    },
    {
        "address": ", Amherst, MA",
        "position": {
            "lat": 42.3732216,
            "lng": -72.5198537
        },
        "info": {
            "State": "MA",
            "Name": "Massachusetts Ptac - Central Massachusetts Area",
            "Address Line 1": "",
            "Address Line 2": "",
            "City": "Amherst",
            "Territory": "MA",
            "Postal Code": "01003-9346",
            "Phone Number": "(508) 870-3193",
            "Extension": "",
            "email for references": "sledbetter@msbdc.umass.edu",
            "website": "https://www.massptac.org/rfc.html",
            "Organization Service Area": "Worchester"
        }
    },
    {
        "address": "1 Federal Street, Springfield, MA",
        "position": {
            "lat": 42.1097286,
            "lng": -72.5764861
        },
        "info": {
            "State": "MA",
            "Name": "Massachusetts Ptac - Greater Boston Area",
            "Address Line 1": "1 Federal Street",
            "Address Line 2": "Bldg 101-R",
            "City": "Springfield",
            "Territory": "MA",
            "Postal Code": "01105-1222",
            "Phone Number": "(413) 404-3670",
            "Extension": "",
            "email for references": "sgrip@umass.edu",
            "website": "https://www.massptac.org/rfc.html",
            "Organization Service Area": "Boston, Suffolk, Norfolk"
        }
    },
    {
        "address": "600 Suffolk St, Suite 160 I, Lowell, MA",
        "position": {
            "lat": 42.6493455,
            "lng": -71.3161708
        },
        "info": {
            "State": "MA",
            "Name": "Massachusetts Ptac - Northshore and Greater Lowell area",
            "Address Line 1": "600 Suffolk St, Suite 160 I",
            "Address Line 2": "",
            "City": "Lowell",
            "Territory": "MA",
            "Postal Code": 1854,
            "Phone Number": "(978) 934-2725; (781) 820-4302",
            "Extension": "",
            "email for references": "msimoes@umass.edu",
            "website": "https://www.massptac.org/rfc.html",
            "Organization Service Area": "Middlesex, Essex"
        }
    },
    {
        "address": "1 Federal Street, Springfield, MA",
        "position": {
            "lat": 42.1097286,
            "lng": -72.5764861
        },
        "info": {
            "State": "MA",
            "Name": "Massachusetts Ptac - Program Manager Office",
            "Address Line 1": "1 Federal Street",
            "Address Line 2": "Building 101-R",
            "City": "Springfield",
            "Territory": "MA",
            "Postal Code": "01105-1222",
            "Phone Number": "(413) 577-1764",
            "Extension": "",
            "email for references": "pcokotis@isenberg.umass.edu",
            "website": "https://www.massptac.org/rfc.html",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "1 Federal Street, Springfield, MA",
        "position": {
            "lat": 42.1097286,
            "lng": -72.5764861
        },
        "info": {
            "State": "MA",
            "Name": "Massachusetts Ptac - Southeastern Cape & Islands Area",
            "Address Line 1": "1 Federal Street",
            "Address Line 2": "Building 101-R",
            "City": "Springfield",
            "Territory": "MA",
            "Postal Code": "01105-1222",
            "Phone Number": "(508) 673-9783",
            "Extension": 100,
            "email for references": "sgrip@umass.edu",
            "website": "https://www.massptac.org/rfc.html",
            "Organization Service Area": "Bamstable, Bristol, Duke, Nantucket, Plymouth"
        }
    },
    {
        "address": "1 Federal Street, Springfield, MA",
        "position": {
            "lat": 42.1097286,
            "lng": -72.5764861
        },
        "info": {
            "State": "MA",
            "Name": "Massachusetts Ptac - Western Massachusetts area",
            "Address Line 1": "1 Federal Street",
            "Address Line 2": "Building 101-R",
            "City": "Springfield",
            "Territory": "MA",
            "Postal Code": "01105-1199",
            "Phone Number": "(413) 545-6307",
            "Extension": "",
            "email for references": "gotta@umass.edu",
            "website": "https://www.massptac.org/rfc.html",
            "Organization Service Area": "Berkshire, Franklin, Hampshire, Hampden"
        }
    },
    {
        "address": "5825 University Research Court, College Park, MD",
        "position": {
            "lat": 38.9710328,
            "lng": -76.9219121
        },
        "info": {
            "State": "MD",
            "Name": "The Maryland APEX Accelerator",
            "Address Line 1": "5825 University Research Court",
            "Address Line 2": "Suite 1300",
            "City": "College Park",
            "Territory": "MD",
            "Postal Code": 20740,
            "Phone Number": "(301) 405-6550",
            "Extension": "",
            "email for references": "mdickso1@umd.edu",
            "website": "https://www.mdptac.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "40 Harlow Street, Bangor, ME",
        "position": {
            "lat": 44.8031614,
            "lng": -68.7705536
        },
        "info": {
            "State": "ME",
            "Name": "Maine APEX Accelerator - Central c/o Eastern Maine Development Corporation",
            "Address Line 1": "40 Harlow Street",
            "Address Line 2": "",
            "City": "Bangor",
            "Territory": "ME",
            "Postal Code": "04401",
            "Phone Number": "(207) 974-3249",
            "Extension": "",
            "email for references": "maineapex@emdc.org",
            "website": "https://www.maineapex.com",
            "Organization Service Area": "Statewide support, plus Hancock, Penobscot, Piscataquis and Waldo"
        }
    },
    {
        "address": "7 Ames Way, Machias, ME",
        "position": {
            "lat": 44.7127955,
            "lng": -67.45696749999999
        },
        "info": {
            "State": "ME",
            "Name": "Maine APEX Accelerator / Downeast c/o Sunrise County Economic Council (SCEC)",
            "Address Line 1": "7 Ames Way",
            "Address Line 2": "",
            "City": "Machias",
            "Territory": "ME",
            "Postal Code": "04654",
            "Phone Number": "(207) 255-0983",
            "Extension": "",
            "email for references": "maineapex@emdc.org",
            "website": "https://www.maineapex.com",
            "Organization Service Area": "Washington County"
        }
    },
    {
        "address": "15 Terminal Road, Brunswick, ME",
        "position": {
            "lat": 43.8984294,
            "lng": -69.9342042
        },
        "info": {
            "State": "ME",
            "Name": "Maine APEX Accelerator - Midstate c/o Eastern Maine Development Corporation",
            "Address Line 1": "74 Orion St",
            "Address Line 2": "",
            "City": "Brunswick",
            "Territory": "ME",
            "Postal Code": "04011",
            "Phone Number": "(207) 974-3249",
            "Extension": "",
            "email for references": "maineapex@emdc.org",
            "website": "https://www.maineapex.com",
            "Organization Service Area": "Kennebec, Knox, Lincoln, Sagadahoc and Somerset"
        }
    },
    {
        "address": "11 West Presque Isle Road, Caribou, ME",
        "position": {
            "lat": 46.832093,
            "lng": -68.0107051
        },
        "info": {
            "State": "ME",
            "Name": "Maine APEX Accelerator - North c/o Northern Maine Development Commission",
            "Address Line 1": "11 West Presque Isle Road",
            "Address Line 2": "",
            "City": "Caribou",
            "Territory": "ME",
            "Postal Code": "04736",
            "Phone Number": "(207) 498-8736",
            "Extension": "",
            "email for references": "maineapex@emdc.org",
            "website": "https://www.maineapex.com",
            "Organization Service Area": "Aroostook County"
        }
    },
    {
        "address": "125 Manley Road, Auburn, ME",
        "position": {
            "lat": 44.0785366,
            "lng": -70.26004379999999
        },
        "info": {
            "State": "ME",
            "Name": "Maine APEX Accelerator - Southwest c/o Eastern Maine Development Corporation",
            "Address Line 1": "125 Manley Road",
            "Address Line 2": "",
            "City": "Auburn",
            "Territory": "ME",
            "Postal Code": "04210",
            "Phone Number": "(207) 974-3249",
            "Extension": "",
            "email for references": "maineapex@emdc.org",
            "website": "https://www.maineapex.com",
            "Organization Service Area": "Androscoggin, Cumberland, Franklin, Oxford, York"
        }
    },
    {
        "address": "12929 Warren Avenue, Dearborn, MI",
        "position": {
            "lat": 42.343954,
            "lng": -83.1700613
        },
        "info": {
            "State": "MI",
            "Name": "MoveAmerica APEX Accelerator",
            "Address Line 1": "12929 Warren Avenue",
            "Address Line 2": "Move America Inc",
            "City": "Dearborn",
            "Territory": "MI",
            "Postal Code": "",
            "Phone Number": "(313) 675-0044",
            "Extension": "",
            "email for references": "ahmad.ramadan@moveamerica.org",
            "website": "",
            "Organization Service Area": "Wayne"
        }
    },
    {
        "address": "519 S. Saginaw Street  Suite 200, Flint, MI",
        "position": {
            "lat": 43.0158904,
            "lng": -83.69028999999999
        },
        "info": {
            "State": "MI",
            "Name": "East Michigan APEX Accelerator",
            "Address Line 1": "519 S. Saginaw Street  Suite 200",
            "Address Line 2": "Suite 200",
            "City": "Flint",
            "Territory": "MI",
            "Postal Code": 48502,
            "Phone Number": "1 (810) 600-4066",
            "Extension": "",
            "email for references": "apex@flintandgenesee.org; jmckenney@flintandgenesee.org",
            "website": "https://developflintandgenesee.org/apex-accelerator/",
            "Organization Service Area": "Genesee, Huron, Tuscola, Lapeer, Sanilac, St. Clair, Shiawassee Counties"
        }
    },
    {
        "address": "7900 Tank Avenue, Warren, MI",
        "position": {
            "lat": 42.49830420000001,
            "lng": -83.0288369
        },
        "info": {
            "State": "MI",
            "Name": "Macomb Regional APEX Accelerator",
            "Address Line 1": "7900 Tank Avenue",
            "Address Line 2": "Room 103",
            "City": "Warren",
            "Territory": "MI",
            "Postal Code": 48092,
            "Phone Number": "(586) 498-4122",
            "Extension": "",
            "email for references": "apex@macomb.edu",
            "website": "http://www.macomb.edu/ptac",
            "Organization Service Area": "Macomb County"
        }
    },
    {
        "address": "600 E. Front Street, Traverse City, MI",
        "position": {
            "lat": 44.7638147,
            "lng": -85.6096729
        },
        "info": {
            "State": "MI",
            "Name": "Northwest APEX Accelerator",
            "Address Line 1": "600 E. Front Street",
            "Address Line 2": "Suite 205",
            "City": "Traverse City",
            "Territory": "MI",
            "Postal Code": 49685,
            "Phone Number": "(231) 929-5036",
            "Extension": "",
            "email for references": "APEXaccelerator@networksnorthwest.org",
            "website": "https://www.networksnorthwest.org/business/apex/",
            "Organization Service Area": "Antrim, Benzie, Charlevoix, Emmet, Grand Traverse, Kalkaska, Leelanau, Manistee, Missaukee, Wexford"
        }
    },
    {
        "address": "101 W. Washington Street, Marquette, MI",
        "position": {
            "lat": 46.543045,
            "lng": -87.3927918
        },
        "info": {
            "State": "MI",
            "Name": "Northwest APEX Accelerator - Marquette Office",
            "Address Line 1": "101 W. Washington Street",
            "Address Line 2": "Suite 13",
            "City": "Marquette",
            "Territory": "MI",
            "Postal Code": 49685,
            "Phone Number": "(231) 929-5036",
            "Extension": "",
            "email for references": "APEXaccelerator@networksnorthwest.org",
            "website": "https://www.networksnorthwest.org/business/apex/",
            "Organization Service Area": "Alger, Baraga, Chippewa, Delta, Dickinson, Gogebic, Houghton, Iron, Keweenaw, Luce, Mackinac, Marquette, Menominee, Ontonagon, Schoolcraft"
        }
    },
    {
        "address": "515 N. Washington Ave., Saginaw, MI",
        "position": {
            "lat": 43.4382922,
            "lng": -83.9376309
        },
        "info": {
            "State": "MI",
            "Name": "Saginaw Future APEX Accelerator (Northwest APEX Accelerator subcenter)",
            "Address Line 1": "515 N. Washington Ave.",
            "Address Line 2": "Suite 300",
            "City": "Saginaw",
            "Territory": "MI",
            "Postal Code": 48607,
            "Phone Number": "989-757-2105",
            "Extension": "",
            "email for references": "dsallen@saginawfuture.com; apex@saginawfuture.com",
            "website": "https://www.saginawfuture.com/doing-business/government-contracting",
            "Organization Service Area": "Arenac, Bay, Clare, Gladwin, Gratiot, Isabella, Midland, Saginaw"
        }
    },
    {
        "address": "20709 State Street, Onaway, MI",
        "position": {
            "lat": 45.3573886,
            "lng": -84.2279836
        },
        "info": {
            "State": "MI",
            "Name": "Northeast Michigan APEX Accelerator",
            "Address Line 1": "20709 State Street",
            "Address Line 2": "P O Box 711",
            "City": "Onaway",
            "Territory": "MI",
            "Postal Code": 49765,
            "Phone Number": "(989) 733-8540",
            "Extension": "",
            "email for references": "APEXAccelerator@nemcworks.org",
            "website": "https://www.ptac4nemi.org/",
            "Organization Service Area": "Alcona, Alpena, Cheboygan, Crawford, Iosco, Montmorency, Ogemaw, Oscoda, Otsego, Presque Isle, Roscommon"
        }
    },
    {
        "address": "4717 Campus Drive, Kalamazoo, MI",
        "position": {
            "lat": 42.2556671,
            "lng": -85.6458153
        },
        "info": {
            "State": "MI",
            "Name": "Southwest Michigan APEX Accelerator (subcenter of Northeast Michigan APEX Accelerator)",
            "Address Line 1": "4717 Campus Drive",
            "Address Line 2": "",
            "City": "Kalamazoo",
            "Territory": "MI",
            "Postal Code": 49008,
            "Phone Number": "(269) 491-6585",
            "Extension": "",
            "email for references": "SWMIAPEX@nemcworks.org",
            "website": "https://www.ptac4nemi.org/",
            "Organization Service Area": "Berrien, Branch, Cass, Calhoun, Kalamazoo, St. Joseph, Van Buren"
        }
    },
    {
        "address": "18600 Haggerty Road, Livonia, MI",
        "position": {
            "lat": 42.4219367,
            "lng": -83.431975
        },
        "info": {
            "State": "MI",
            "Name": "Schoolcraft College APEX Accelerator",
            "Address Line 1": "18600 Haggerty Road",
            "Address Line 2": "Suite 340",
            "City": "Livonia",
            "Territory": "MI",
            "Postal Code": "48152-3932",
            "Phone Number": "(734) 462-4438",
            "Extension": "",
            "email for references": "apex@schoolcraft.edu",
            "website": "https://www.schoolcraft.edu/bdc/services/apex/",
            "Organization Service Area": "Livingston, Monroe, Oakland, Washtenaw, Wayne"
        }
    },
    {
        "address": "5057 Woodward Ave., Detroit, MI",
        "position": {
            "lat": 42.35742339999999,
            "lng": -83.06534219999999
        },
        "info": {
            "State": "MI",
            "Name": "Wayne State University APEX Accelerator (subcenter of Schoolcraft College APEX Accelerator)",
            "Address Line 1": "5057 Woodward Ave.",
            "Address Line 2": "Suite 3101",
            "City": "Detroit",
            "Territory": "MI",
            "Postal Code": 48202,
            "Phone Number": "313-577-0132",
            "Extension": "",
            "email for references": "apex@wayne.edu",
            "website": "https://execed.wayne.edu/entrepreneurship/apex-accelerator",
            "Organization Service Area": "City of Detroit"
        }
    },
    {
        "address": "100 Jackson Square, Jackson, MI",
        "position": {
            "lat": 42.247041,
            "lng": -84.40932370000002
        },
        "info": {
            "State": "MI",
            "Name": "South Central Michigan APEX Accelerator",
            "Address Line 1": "100 Jackson Square",
            "Address Line 2": "Suite 1100",
            "City": "Jackson",
            "Territory": "MI",
            "Postal Code": 49201,
            "Phone Number": "(517) 788-4680",
            "Extension": "",
            "email for references": "info@enterprisegroup.org",
            "website": "https://enterprisegroup.org/ptac/",
            "Organization Service Area": "Jackson, Hillsdale, Lenawee, Ingham, Eaton, Clinton"
        }
    },
    {
        "address": "380 West Western Avenue, Muskegon, MI",
        "position": {
            "lat": 43.235845,
            "lng": -86.2529028
        },
        "info": {
            "State": "MI",
            "Name": "West Michigan APEX Accelerator",
            "Address Line 1": "380 West Western Avenue",
            "Address Line 2": "Suite 202",
            "City": "Muskegon",
            "Territory": "MI",
            "Postal Code": 49440,
            "Phone Number": "(231) 722-7700",
            "Extension": "",
            "email for references": "krhoda@westmiapex.org",
            "website": "https://westmiapex.org/",
            "Organization Service Area": "Allegan, Barry, Ionia, Kent, Lake, Mason, Mecosta, Montcalm, Muskegon, Newaygo, Oceana, Osceola, Ottawa"
        }
    },
    {
        "address": "50 Sherburne Avenue, St. Paul, MN",
        "position": {
            "lat": 44.9564826,
            "lng": -93.1013899
        },
        "info": {
            "State": "MN",
            "Name": "Minnesota APEX Accelerator",
            "Address Line 1": "50 Sherburne Avenue",
            "Address Line 2": "Suite 309 (3rd floor)",
            "City": "St. Paul",
            "Territory": "MN",
            "Postal Code": 55155,
            "Phone Number": "(651) 201-2629",
            "Extension": "",
            "email for references": "apexaccelerator.adm@state.mn.us;  christina.nebel-dickerson@state.mn.us",
            "website": "https://www.mn.gov/admin/apexaccelerator",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "221 Gentry Hall, Columbia, MO",
        "position": {
            "lat": 38.9443471,
            "lng": -92.3248188
        },
        "info": {
            "State": "MO",
            "Name": "Missouri APEX Accelerator Main Office",
            "Address Line 1": "221 Gentry Hall",
            "Address Line 2": "",
            "City": "Columbia",
            "Territory": "MO",
            "Postal Code": 65211,
            "Phone Number": "(573) 882-8058",
            "Extension": "",
            "email for references": "moapex@missouri.edu",
            "website": "https://www.moptac.net",
            "Organization Service Area": ""
        }
    },
    {
        "address": "500 East Walnut St, Columbia, MO",
        "position": {
            "lat": 38.9523211,
            "lng": -92.33135469999999
        },
        "info": {
            "State": "MO",
            "Name": "MO APEX Accelerator Columbia 1",
            "Address Line 1": "500 East Walnut St",
            "Address Line 2": "Suite 103",
            "City": "Columbia",
            "Territory": "MO",
            "Postal Code": 65201,
            "Phone Number": "(573) 882-9398",
            "Extension": "",
            "email for references": "Kris Bockting \nmoapex@missouri.edu",
            "website": "https://www.moptac.net",
            "Organization Service Area": "Adair, Audrain, Benton, Boone, Callaway, Camden, Chariton, Clark, Cole, Cooper, Howard, Knox, Lewis, Linn, Macon, Maries, Marion, Miller, Moniteau, Monroe,\nMorgan, Osage, Pettis, Phelps, Putnam, Ralls, Randolph, Saline, Schuyler, Scotland, Shelby, Sullivan"
        }
    },
    {
        "address": "4747 Troost Bldg, Kansas City, MO",
        "position": {
            "lat": 39.0400227,
            "lng": -94.5725179
        },
        "info": {
            "State": "MO",
            "Name": "MO APEX Accelerator Kansas City",
            "Address Line 1": "4747 Troost Bldg",
            "Address Line 2": "Suite 105",
            "City": "Kansas City",
            "Territory": "MO",
            "Postal Code": 64110,
            "Phone Number": "816 235-2891; (816) 235-6083",
            "Extension": "ext 2",
            "email for references": "Jason Cole; \nJeff Rosenblatt; \napex@umkc.edu",
            "website": "https://www.moptac.net",
            "Organization Service Area": "Andrew, Atchison, Bates, Buchanan, Caldwell, Cass, Caroll, Clay, Clinton, Daviess, DeKalb, Gentry, Grundy, Harrison, Henry, Holt, Jackson, Johnson, \nLafayette, Livingston, Mercer, Nodaway, Platte, Ray, Worth"
        }
    },
    {
        "address": "Robert W. Plaster Center, Springfield, MO",
        "position": {
            "lat": 37.2118284,
            "lng": -93.289889
        },
        "info": {
            "State": "MO",
            "Name": "MO APEX Accelerator Springfield",
            "Address Line 1": "Robert W. Plaster Center",
            "Address Line 2": "",
            "City": "Springfield",
            "Territory": "MO",
            "Postal Code": 65806,
            "Phone Number": "(417) 837-2612",
            "Extension": "",
            "email for references": "Allen Waldo;\nmoapex@missouri.edu",
            "website": "https://www.moptac.net",
            "Organization Service Area": "Barry, Barton, Cedar, Christian, Dade, Dallas, Greene, Hickory, Jasper, Laclede, Lawrence, McDonald, Newton, Polk, Pulaski, St. Clair, Stone, Taney, Vernon, Webster"
        }
    },
    {
        "address": "9667 Page Avenue, St. Louis, MO",
        "position": {
            "lat": 38.688736,
            "lng": -90.373183
        },
        "info": {
            "State": "MO",
            "Name": "MO APEX Accelerator St. Louis County",
            "Address Line 1": "9667 Page Avenue",
            "Address Line 2": "",
            "City": "St. Louis",
            "Territory": "MO",
            "Postal Code": 63132,
            "Phone Number": "314-403-7410; 314-400-7378; 314-376-5375",
            "Extension": "",
            "email for references": "Jon Hubach,\nGina Corce, \nJaymie Mitchell; \nmoapex@missouri.edu",
            "website": "https://www.moptac.net",
            "Organization Service Area": "Crawford, Franklin, Gasconade, Iron, Jefferson, Lincoln, Madison, Montgomery, Pery, Pike, St. Charles, St. Francois, St. Louis, Ste. Genevieve, Warren, Washington, St. Louis City"
        }
    },
    {
        "address": "Howell County Extension Center, West Plains, MO",
        "position": {
            "lat": 36.7281154,
            "lng": -91.8523711
        },
        "info": {
            "State": "MO",
            "Name": "MO APEX Accelerator West Plains",
            "Address Line 1": "Howell County Extension Center",
            "Address Line 2": "217 S. Aid Avenue",
            "City": "West Plains",
            "Territory": "MO",
            "Postal Code": 65775,
            "Phone Number": "(417) 256-2391",
            "Extension": "",
            "email for references": "Willis Mushrush; \nmoapex@missouri.edu",
            "website": "https://www.moptac.net",
            "Organization Service Area": "Bollinger, Butler, Cape Girardeau, Carter, Dent, Douglas, Dunklin, Howell, Mississippi, New Madrid, Oregon, Ozark, Pemiscot, Reynolds, Ripley, Scott, Shannon, Stoddard, Texas, Wayne, Wright"
        }
    },
    {
        "address": "501 North West Street, Jackson, MS",
        "position": {
            "lat": 32.3041577,
            "lng": -90.1840313
        },
        "info": {
            "State": "MS",
            "Name": "Mississippi APEX Accelerator - Program Office",
            "Address Line 1": "501 North West Street",
            "Address Line 2": "Woolfolk Building, 4th floor",
            "City": "Jackson",
            "Territory": "MS",
            "Postal Code": "39205-0849",
            "Phone Number": "(601) 359-3448",
            "Extension": "",
            "email for references": "scbutler@mississippi.org",
            "website": "https://www.mscpc.com/",
            "Organization Service Area": "ADAMS, CLAIBORNE, COPIAH, FRANKLIN, GREENE, HINDS, JEFFERSON, JEFFERSON DAVIS, LAWRENCE, LINCOLN, MADISON, RANKIN, SIMPSON, WARREN, YAZOO"
        }
    },
    {
        "address": "723 23rd Avenue, Meridian, MS",
        "position": {
            "lat": 32.3647273,
            "lng": -88.7020033
        },
        "info": {
            "State": "MS",
            "Name": "East MS Contract Procurement Center",
            "Address Line 1": "723 23rd Avenue",
            "Address Line 2": "",
            "City": "Meridian",
            "Territory": "MS",
            "Postal Code": 39301,
            "Phone Number": "(601) 934-5975",
            "Extension": "",
            "email for references": "scbutler@mississippi.org",
            "website": "https://www.mscpc.com/",
            "Organization Service Area": "CLARKE, COVINGTON, JASPER, JONES, KEMPER, LAUDERDALE, LEAKE, NESHOBA, NEWTON, SCOTT, SMITH, WAYNE ISSEQUENA, LEFLORE, PANOLA, QUITMAN, SHARKEY, SUNFLOWER"
        }
    },
    {
        "address": "318 7th Street North, Columbus, MS",
        "position": {
            "lat": 33.49858,
            "lng": -88.4258862
        },
        "info": {
            "State": "MS",
            "Name": "NorthEast MS Contract Procurement Center",
            "Address Line 1": "318 7th Street North",
            "Address Line 2": "",
            "City": "Columbus",
            "Territory": "MS",
            "Postal Code": "39703-1805",
            "Phone Number": "(662) 329-1077",
            "Extension": "",
            "email for references": "scbutler@mississippi.org",
            "website": "https://www.mscpc.com/",
            "Organization Service Area": "ADAMS, ALCORN, ATTALA, BENTON, CALHOUN, CHICKASAW, CHOCTAW, CLAY, ISSAQUENA, ITAWAMBA, LAFAYETTE, LEE, LOWNDES, MARSHALL, MONROE, MONTGOMERY, NOXUBEE, OKTIBBEHA, PONTOTOC, PRENTISS, TALLAHATCHIE, TATE, TIPPAH, TISHOMINGO, TUNICA, UNION, WASHINGTON, WEBSTER, WINSTON, YALOBUSHA"
        }
    },
    {
        "address": "7075 Golden Oaks Loop W., Southaven, MS",
        "position": {
            "lat": 34.9636229,
            "lng": -89.9630866
        },
        "info": {
            "State": "MS",
            "Name": "Northwest MS Contract Procurement Center",
            "Address Line 1": "7075 Golden Oaks Loop W.",
            "Address Line 2": "Suite 12",
            "City": "Southaven",
            "Territory": "MS",
            "Postal Code": 38671,
            "Phone Number": "(662) 349-2379",
            "Extension": "",
            "email for references": "scbutler@mississippi.org",
            "website": "https://www.mscpc.com/",
            "Organization Service Area": "BOLIVAR, CARROLL, COAHOMA, DESOTO, GRENADA, HOLMES, HUMPHREYS, ISSEQUENA, LEFLORE, PANOLA, QUITMAN, SHARKEY, SUNFLOWE, YAZOO"
        }
    },
    {
        "address": "2500 14th St., Gulfport, MS",
        "position": {
            "lat": 30.36841069999999,
            "lng": -89.0932009
        },
        "info": {
            "State": "MS",
            "Name": "South Mississippi Contract Procurement Center",
            "Address Line 1": "2500 14th St.",
            "Address Line 2": "8th Floor",
            "City": "Gulfport",
            "Territory": "MS",
            "Postal Code": 39501,
            "Phone Number": "(228) 396-1288",
            "Extension": "",
            "email for references": "scbutler@mississippi.org",
            "website": "https://www.mscpc.com/",
            "Organization Service Area": "AMITE, FORREST, GEORGE, GREENE, HANCOCK, HARRISON, JACKSON, LAMAR, MARION, PEARL RIVER, PERRY, PIKE, STONE, WALTHALL, WILKINSON"
        }
    },
    {
        "address": "405 3rd Street NW Suite 203, Great Falls, MT",
        "position": {
            "lat": 47.5119857,
            "lng": -111.31564
        },
        "info": {
            "State": "MT",
            "Name": "Montana PTAC  APEX Accelerator - Great Falls",
            "Address Line 1": "405 3rd Street NW Suite 203",
            "Address Line 2": "The Portage Building at West Bank Landing",
            "City": "Great Falls",
            "Territory": "MT",
            "Postal Code": 59404,
            "Phone Number": "(406) 564-0957",
            "Extension": "",
            "email for references": "lsunwall@growgreatfalls.org",
            "website": "https://www.montanaptac.org/",
            "Organization Service Area": "Cascade, Teton, Chouteau, Pondera, Toole, Liberty, Hill counties"
        }
    },
    {
        "address": "201 N Broadway, Billings, MT",
        "position": {
            "lat": 45.7833307,
            "lng": -108.5062954
        },
        "info": {
            "State": "MT",
            "Name": "Montana PTAC APEX Accelerator -Billings",
            "Address Line 1": "201 N Broadway",
            "Address Line 2": "",
            "City": "Billings",
            "Territory": "MT",
            "Postal Code": 59101,
            "Phone Number": "(406) 869-8410",
            "Extension": "",
            "email for references": "langman@bigskyeda.org",
            "website": "https://www.montanaptac.org/",
            "Organization Service Area": "Daniels, Sheridan, Roosevelt, McCone, Richland, Dawson, Prairie, Wibaux, Custer, Fallon, Powder River, Carter, Rosebud, Treasure, Yellowstone, Big Horn, Carbon, Stillwater, Sweet Grass, Park counties"
        }
    },
    {
        "address": "44 2nd Avenue West, Kalispell, MT",
        "position": {
            "lat": 48.1973805,
            "lng": -114.3168524
        },
        "info": {
            "State": "MT",
            "Name": "Montana PTAC APEX Accelerator - Kalispell",
            "Address Line 1": "44 2nd Avenue West",
            "Address Line 2": "",
            "City": "Kalispell",
            "Territory": "MT",
            "Postal Code": 59901,
            "Phone Number": "(406) 257-7711",
            "Extension": 5,
            "email for references": "jill@dobusinessinmontana.com",
            "website": "https://www.montanaptac.org/",
            "Organization Service Area": "Flathead, Glacier, Sanders, Lincoln counties"
        }
    },
    {
        "address": "Room 255, Gallagher Business Building, Missoula, MT",
        "position": {
            "lat": 46.86254590000001,
            "lng": -113.9876926
        },
        "info": {
            "State": "MT",
            "Name": "Montana PTAC APEX Accelerator - Missoula",
            "Address Line 1": "Room 255, Gallagher Business Building",
            "Address Line 2": "",
            "City": "Missoula",
            "Territory": "MT",
            "Postal Code": 59812,
            "Phone Number": "(406) 243-4030 ",
            "Extension": "",
            "email for references": "patty.cox@mso.umt.edu",
            "website": "https://www.montanaptac.org/",
            "Organization Service Area": "Lake, Missoula, Mineral, Powell, Granite, Deer Lodge, Ravalli counties"
        }
    },
    {
        "address": "613 N.E. Main, Lewistown, MT",
        "position": {
            "lat": 47.0697781,
            "lng": -109.4096882
        },
        "info": {
            "State": "MT",
            "Name": "Montana PTAC APEX Accelerator - Lewistown",
            "Address Line 1": "613 N.E. Main",
            "Address Line 2": "",
            "City": "Lewistown",
            "Territory": "MT",
            "Postal Code": 59457,
            "Phone Number": "(406) 535-2591",
            "Extension": "",
            "email for references": "wheatley@snowymountaindevelopment.com",
            "website": "https://www.montanaptac.org/",
            "Organization Service Area": "Blaine, Phillips, Valley, Garfield, Petroleum, Musselshell, Golden Valley, Wheatland, Meagher, Judith Basin, Fergus counties"
        }
    },
    {
        "address": "201 N Broadway, Billings, MT",
        "position": {
            "lat": 45.7833307,
            "lng": -108.5062954
        },
        "info": {
            "State": "MT",
            "Name": "Southwest Montana Big Sky EDA",
            "Address Line 1": "201 N Broadway",
            "Address Line 2": "",
            "City": "Billings",
            "Territory": "MT",
            "Postal Code": 59101,
            "Phone Number": "(406) 869-8413",
            "Extension": "",
            "email for references": "hale@bigskyeda.org",
            "website": "https://www.montanaptac.org/",
            "Organization Service Area": "Beaverhead, Broadwater, Jefferson, Gallatin, Lewis and Clark, Madison, Silver Bow counties"
        }
    },
    {
        "address": "17 N 26th St, Billings, MT",
        "position": {
            "lat": 45.7830676,
            "lng": -108.5025513
        },
        "info": {
            "State": "MT",
            "Name": "Native American Development Corporation APEX Accelerator",
            "Address Line 1": "17 N 26th St",
            "Address Line 2": "",
            "City": "Billings",
            "Territory": "MT",
            "Postal Code": 59101,
            "Phone Number": "(406) 294-7994",
            "Extension": "",
            "email for references": "Mwalksoverice@nadc-nabn.org",
            "website": "http://www.nadc-nabn.org",
            "Organization Service Area": "BIA Rocky Mountain and Great Plains Regions (MT, WY, ND, SD, NE)"
        }
    },
    {
        "address": "1200 Memorial HWY, Bismarck, ND",
        "position": {
            "lat": 46.808297,
            "lng": -100.8123771
        },
        "info": {
            "State": "ND",
            "Name": "ND APEX Accelerator\n- Bismarck Office",
            "Address Line 1": "1200 Memorial HWY",
            "Address Line 2": "",
            "City": "Bismarck",
            "Territory": "ND",
            "Postal Code": 58504,
            "Phone Number": "(701) 777-6571",
            "Extension": "",
            "email for references": "david.kleppe@und.edu",
            "website": "https://und.edu/ndapex/index.html",
            "Organization Service Area": "Statewide"
            //"Organization Service Area": "Adams, Barnes, Benson, Billings, Bottineau, Bowman, Burke, Burleigh, Cass, Cavalier, Dickey, Divide, Dunn, Eddy, Emmons, Foster, Golden Valley, Grand Forks, Grant, Griggs, Hettinger, Kidder, LaMoure, Logan, McHenry, McIntosh, McKenzie, McLean, Mercer, Morton, Mountrail, Nelson, Oliver, Pembina, Pierce, Ramsey, Ransom, Renville, Richland, Rolette, Sargent, Sheridan, Sioux, Slope, Stark, Steele, Stutsman, Towner, Traill, Walsh, Ward, Wells, Williams"
        }
    },
    {
        "address": "417 Main Avenue, Fargo, ND",
        "position": {
            "lat": 46.8744563,
            "lng": -96.785893
        },
        "info": {
            "State": "ND",
            "Name": "ND APEX Accelerator\n- Fargo Office",
            "Address Line 1": "417 Main Avenue",
            "Address Line 2": "Suite 202",
            "City": "Fargo",
            "Territory": "ND",
            "Postal Code": 58103,
            "Phone Number": "(701) 777-6574",
            "Extension": "",
            "email for references": "ndapex@und.edu",
            "website": "https://und.edu/ndapex/index.html",
            "Organization Service Area": "Statewide"
        }
    },
    // {
    //     "address": "1925 S Broadway STE 2, Minot, ND",
    //     "position": {
    //         "lat": 48.2118827,
    //         "lng": -101.2954087
    //     },
    //     "info": {
    //         "State": "ND",
    //         "Name": "ND APEX Accelerator\n- Minot Office",
    //         "Address Line 1": "1925 S Broadway STE 2",
    //         "Address Line 2": "",
    //         "City": "Minot",
    //         "Territory": "ND",
    //         "Postal Code": 58701,
    //         "Phone Number": "(701) 340-4343",
    //         "Extension": "",
    //         "email for references": "nicolette.kallis@und.edu",
    //         "website": "https://ndptac.org/",
    //         "Organization Service Area": ""
    //     }
    // },
    {
        "address": "UNO College of Business Administration, Omaha, NE",
        "position": {
            "lat": 41.2580268,
            "lng": -96.010696
        },
        "info": {
            "State": "NE",
            "Name": "NBDC APEX Accelerator",
            "Address Line 1": "UNO College of Business Administration",
            "Address Line 2": "6708 Pine Streets,Suite 200, 2nd Floor, Mammel Hall",
            "City": "Omaha",
            "Territory": "NE",
            "Postal Code": 68106,
            "Phone Number": "(402) 554-6253",
            "Extension": "",
            "email for references": "nebraskaapex@unomaha.edu",
            "website": "https://www.unomaha.edu/nebraska-business-development-center/government-contracting",
            "Organization Service Area": "Dodge, Douglas, Sarpy, Saunders."
        }
    },
    {
        "address": "609 W Norfolk Ave., Norfolk, NE",
        "position": {
            "lat": 42.03247229999999,
            "lng": -97.4163786
        },
        "info": {
            "State": "NE",
            "Name": "NBDC APEX Accelerator- Norfolk Chamber",
            "Address Line 1": "609 W Norfolk Ave.",
            "Address Line 2": "",
            "City": "Norfolk",
            "Territory": "NE",
            "Postal Code": 68701,
            "Phone Number": "(402) 371-4862",
            "Extension": 101,
            "email for references": "nebraskaapex@unomaha.edu",
            "website": "https://www.unomaha.edu/nebraska-business-development-center/government-contracting",
            "Organization Service Area": "Antelope, Boone, Boyd, Burt, Butler, Cedar, Colfax, Cuming, Dakota, Dixon, Garfield, Greeley, Holt, Knox, Madison, Merrick, Nance, Pierce, Platte, Polk, Stanton, Thurston, Valley, Wayne, Wheeler."
        }
    },
    {
        "address": "285 South 68th Street Place, Lincoln, NE",
        "position": {
            "lat": 40.8110823,
            "lng": -96.6285542
        },
        "info": {
            "State": "NE",
            "Name": "NBDC APEX Accelerator- Southeast Community College",
            "Address Line 1": "285 South 68th Street Place",
            "Address Line 2": "Suite 210",
            "City": "Lincoln",
            "Territory": "NE",
            "Postal Code": 68510,
            "Phone Number": "(402) 472-1177",
            "Extension": "",
            "email for references": "nebraskaapex@unomaha.edu",
            "website": "https://www.unomaha.edu/nebraska-business-development-center/government-contracting",
            "Organization Service Area": "Cass, Clay, Fillmore, Gage, Jefferson, Johnson, Lancaster, Nemaha, Nuckolls, Otoe, Pawnee, Richardson, Saline, Seward, Thayer, Washington, Webster, York."
        }
    },
    {
        "address": "1917 West 24th Street, Kearney, NE",
        "position": {
            "lat": 40.7000969,
            "lng": -99.1070113
        },
        "info": {
            "State": "NE",
            "Name": "NBDC APEX Accelerator UNK College of Business",
            "Address Line 1": "1917 West 24th Street",
            "Address Line 2": "WSTC 107N",
            "City": "Kearney",
            "Territory": "NE",
            "Postal Code": "68849-4440",
            "Phone Number": "(402) 690-4986",
            "Extension": "",
            "email for references": "nebraskaapex@unomaha.edu",
            "website": "https://www.unomaha.edu/nebraska-business-development-center/government-contracting",
            "Organization Service Area": "Adams, Arthur, Banner, Blaine, Box Butte, Brown, Buffalo, Chase, Cherry, Cheyenne, Clay, Custer, Dawes, Dawson, Deuel, Dundy, Franklin, Frontier, Furnas, Garden, Gosper, Grant, Hall, Hamilton, Harlan, Hayes, Hitchcock, Hooker, Howard, Kearney, Keith, Keya Paha, Kimball, Lincoln, Logan, Loup, McPherson, Morrill, Perkins, Phelps, Red Willow, Rock, Scotts Bluff, Sheridan, Sherman, Sioux, Thomas."
        }
    },
    {
        "address": "1111 Highway 75, Macy, NE",
        "position": {
            "lat": 42.1169046,
            "lng": -96.3532228
        },
        "info": {
            "State": "NE",
            "Name": "Nebraska Indian Community College APEX Accelerator",
            "Address Line 1": "1111 Highway 75",
            "Address Line 2": "",
            "City": "Macy",
            "Territory": "NE",
            "Postal Code": 68039,
            "Phone Number": "(402) 494-2311",
            "Extension": 140,
            "email for references": "nebraskaapex@unomaha.edu",
            "website": "https://www.thenicc.edu/career-services/APEX-Accelerator.php",
            "Organization Service Area": "The Santee Sioux Nation and Omaha Nation on both reservations in Nebraska, including Native American businesses that are located outside of those reservations"
        }
    },
    {
        "address": "Division of Economic Development, Concord, NH",
        "position": {
            "lat": 43.2081366,
            "lng": -71.5375718
        },
        "info": {
            "State": "NH",
            "Name": "New Hampshire APEX Accelerator",
            "Address Line 1": "Division of Economic Development",
            "Address Line 2": "100 N. Main Street, Suite 100",
            "City": "Concord",
            "Territory": "NH",
            "Postal Code": "03301-5054",
            "Phone Number": "(603) 271-7581",
            "Extension": 315,
            "email for references": "Danielle.C.Bishop@livefree.nh.gov",
            "website": "https://www.nheconomy.com/apex",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "323 Dr. Martin Luther King Blvd, Newark, NJ",
        "position": {
            "lat": 40.7428189,
            "lng": -74.1770705
        },
        "info": {
            "State": "NJ",
            "Name": "NJIT  APEX Accelerators",
            "Address Line 1": "323 Dr. Martin Luther King Blvd",
            "Address Line 2": "Central King Building",
            "City": "Newark",
            "Territory": "NJ",
            "Postal Code": "07102-1982",
            "Phone Number": "(973) 596-5805",
            "Extension": "",
            "email for references": "ptac@njit.edu",
            "website": "https://www.njit.edu/ptac/",
            "Organization Service Area": "Atlantic, Bergen, Burlington, Camden, Cape May, Cumberland, Essex, Gloucester, Hudson, Hunterdon, Mercer, Middlesex, Monmouth, Morris, Ocean, Passaic, Salem, Somerset, Sussex, Warren"
        }
    },
    {
        "address": "75 Chestnut  Street, Cranford, NJ",
        "position": {
            "lat": 40.6538679,
            "lng": -74.3035328
        },
        "info": {
            "State": "NJ",
            "Name": "Union County Economic Development Corp. APEX Accelerator",
            "Address Line 1": "75 Chestnut  Street",
            "Address Line 2": "",
            "City": "Cranford",
            "Territory": "NJ",
            "Postal Code": 7016,
            "Phone Number": "(908) 527-1166",
            "Extension": "",
            "email for references": "nclark@ucedc.com",
            "website": "https://ucedc.com/apex/",
            "Organization Service Area": "Union"
        }
    },
    {
        "address": "CNM Workforce Training Center, Albuquerque, NM",
        "position": {
            "lat": 35.1867475,
            "lng": -106.5829033
        },
        "info": {
            "State": "NM",
            "Name": "New Mexico APEX Accelerator (Admin)",
            "Address Line 1": "CNM Workforce Training Center",
            "Address Line 2": "5600 Eagle Rock Ave NE, #202",
            "City": "Albuquerque",
            "Territory": "NM",
            "Postal Code": 8113,
            "Phone Number": "(505) 224-5965",
            "Extension": "",
            "email for references": "tracey.edwards@sfcc.edu",
            "website": "https://www.nmapexaccelerator.org/",
            "Organization Service Area": "Bernalillo, Catron, Chaves, Cibola, Colfax, Curry, De Baca, Dona Ana, Eddy, Grant, Guadalupe, Harding, Hidalgo, Lea, Lincoln, Los Alamos, Luna, McKinley, Mora, Otero, Quay, Rio Arriba, Roosevelt, San Juan, San Miguel, Sandoval, Santa Fe, Sierra, Socorro, Taos, Torrance, Union, Valencia"
        }
    },
    {
        "address": "CNM Workforce Training Center, Albuquerque, NM",
        "position": {
            "lat": 35.1867475,
            "lng": -106.5829033
        },
        "info": {
            "State": "NM",
            "Name": "New Mexico APEX Accelerator - Alburquerque 1",
            "Address Line 1": "CNM Workforce Training Center",
            "Address Line 2": "5600 Eagle Rock Ave NE, #206",
            "City": "Albuquerque",
            "Territory": "NM",
            "Postal Code": 87113,
            "Phone Number": "(505) 224-5964",
            "Extension": "",
            "email for references": "elythia.mcanarney@sfcc.edu",
            "website": "https://www.nmapexaccelerator.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "CNM Workforce Training Center, Albuquerque, NM",
        "position": {
            "lat": 35.1867475,
            "lng": -106.5829033
        },
        "info": {
            "State": "NM",
            "Name": "New Mexico APEX Accelerator - Alburquerque 2",
            "Address Line 1": "CNM Workforce Training Center",
            "Address Line 2": "5601 Eagle Rock Ave NE, #204",
            "City": "Albuquerque",
            "Territory": "NM",
            "Postal Code": 87113,
            "Phone Number": "(505) 224-5969",
            "Extension": "",
            "email for references": "stephen.hogan@sfcc.edu",
            "website": "https://www.nmapexaccelerator.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "ABQ Hispano Chamber of Commerce, Albuquerque, NM",
        "position": {
            "lat": 35.07258059999999,
            "lng": -106.6540382
        },
        "info": {
            "State": "NM",
            "Name": "New Mexico APEX Accelerator - Alburquerque 3",
            "Address Line 1": "ABQ Hispano Chamber of Commerce",
            "Address Line 2": "1309 4th Street SW, #116",
            "City": "Albuquerque",
            "Territory": "NM",
            "Postal Code": 87102,
            "Phone Number": "(505) 462-2240",
            "Extension": "",
            "email for references": "stephen.stewart@sfcc.edu",
            "website": "https://www.nmapexaccelerator.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "Clovis Community College, Clovis, NM",
        "position": {
            "lat": 34.4022244,
            "lng": -103.1671293
        },
        "info": {
            "State": "NM",
            "Name": "New Mexico APEX Accelerator - Clovis",
            "Address Line 1": "Clovis Community College",
            "Address Line 2": "417 Schepps Blvd",
            "City": "Clovis",
            "Territory": "NM",
            "Postal Code": "88101-8345",
            "Phone Number": "(575) 769-4135",
            "Extension": "",
            "email for references": "jonnie.loadwick@sfcc.edu",
            "website": "https://www.nmapexaccelerator.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "New Mexico State University Campus, Las Cruces, NM",
        "position": {
            "lat": 32.2792887,
            "lng": -106.7491391
        },
        "info": {
            "State": "NM",
            "Name": "New Mexico APEX Accelerator - Las Cruses",
            "Address Line 1": "New Mexico State University Campus",
            "Address Line 2": "3655 Research Dr.,",
            "City": "Las Cruces",
            "Territory": "NM",
            "Postal Code": 88003,
            "Phone Number": "(575) 644-9753",
            "Extension": "",
            "email for references": "karen.medina1@sfcc.edu",
            "website": "https://www.nmapexaccelerator.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "Santa Fe Community College, Santa Fe, NM",
        "position": {
            "lat": 35.6050125,
            "lng": -105.9982209
        },
        "info": {
            "State": "NM",
            "Name": "New Mexico APEX Accelerator (Program Manager)",
            "Address Line 1": "Santa Fe Community College",
            "Address Line 2": "6401 Richards Ave.",
            "City": "Santa Fe",
            "Territory": "NM",
            "Postal Code": "87508-4887",
            "Phone Number": "(505) 428-1374",
            "Extension": "",
            "email for references": "therese.rivera@sfcc.edu",
            "website": "https://www.nmapexaccelerator.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "Santa Fe Higher Education Center, Santa Fe, NM",
        "position": {
            "lat": 35.65401689999999,
            "lng": -105.9742043
        },
        "info": {
            "State": "NM",
            "Name": "New Mexico APEX Accelerator - Santa Fe",
            "Address Line 1": "Santa Fe Higher Education Center",
            "Address Line 2": "1950 Siringo Road",
            "City": "Santa Fe",
            "Territory": "NM",
            "Postal Code": 87505,
            "Phone Number": "(505) 428-1850",
            "Extension": "",
            "email for references": "gillis.lang@sfcc.edu",
            "website": "https://www.nmapexaccelerator.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "2401 12th Street NW, Albuquerque, NM",
        "position": {
            "lat": 35.1092812,
            "lng": -106.6591118
        },
        "info": {
            "State": "NM",
            "Name": "The National Center American Indian APEX Accelerator -New Mexico Satellite Office",
            "Address Line 1": "2401 12th Street NW",
            "Address Line 2": "Suite 205N",
            "City": "Albuquerque",
            "Territory": "NM",
            "Postal Code": 87104,
            "Phone Number": "(928) 255-5704",
            "Extension": "",
            "email for references": "adolfo@ncaied.org",
            "website": "http://www.ncaied.org/programs/ptac",
            "Organization Service Area": "Southwestern BIA Region - NM & El Paso, TX"
        }
    },
    {
        "address": "3300 W. Sahara Avenue, Las Vegas, NV",
        "position": {
            "lat": 36.1449758,
            "lng": -115.1860906
        },
        "info": {
            "State": "NV",
            "Name": "Nevada APEX Accelerator - Las Vegas",
            "Address Line 1": "1 State of Nevada Way",
            "Address Line 2": "4th Floor – Nevada Governor’s Office of Economic Development (GOED), State of Nevada",
            "City": "Las Vegas",
            "Territory": "NV",
            "Postal Code": 89119,
            "Phone Number": "(702) 486-3514",
            "Extension": "",
            "email for references": "NVAPEXAccelerator@goed.nv.gov",
            "website": "http://goed.nv.gov/programs-incentives/procurement-assistance-outreach/",
            "Organization Service Area": "Carson City, Churchill, Clark, Douglas, Elko, Esmeralda, Eureka, Humboldt, Lander, Lincoln, Lyon, Mineral, Nye, Pershing, Storey, Washoe, White Pine"
        }
    },
    {
        "address": "808 W. Nye Lane, Carson City, NV",
        "position": {
            "lat": 39.1867856,
            "lng": -119.7731096
        },
        "info": {
            "State": "NV",
            "Name": "Nevada APEX Accelerator - Carson City",
            "Address Line 1": "808 W. Nye Lane",
            "Address Line 2": "",
            "City": "Carson City",
            "Territory": "NV",
            "Postal Code": 89703,
            "Phone Number": "(775) 687-9921",
            "Extension": "",
            "email for references": "jrueben@goed.nv.gov",
            "website": "http://goed.nv.gov/programs-incentives/procurement-assistance-outreach/",
            "Organization Service Area": ""
        }
    },
    // {
    //     "address": "303 Court Street, Little Valley, NY",
    //     "position": {
    //         "lat": 42.2523894,
    //         "lng": -78.8005663
    //     },
    //     "info": {
    //         "State": "NY",
    //         "Name": "Cattaraugus APEX Accelerator",
    //         "Address Line 1": "303 Court Street",
    //         "Address Line 2": "2nd Floor, EDPT",
    //         "City": "Little Valley",
    //         "Territory": "NY",
    //         "Postal Code": 14755,
    //         "Phone Number": "(716) 938-2331;\n(716) 938-2311",
    //         "Extension": "",
    //         "email for references": "info@CattAPEX.org",
    //         "website": "http://www.catapex.org/",
    //         "Organization Service Area": "Allegany, Broome, Cattaraugus, Cayuga, Chautauqua, Chemung, Chenango, Cortland, Erie, Madison, Niagara, Schuyler, Steuben, Tioga, Tompkins"
    //     }
    // },
    {
        "address": "1 Liberty Plaza, New York City, NY",
        "position": {
            "lat": 40.7097933,
            "lng": -74.0104525
        },
        "info": {
            "State": "NY",
            "Name": "NYC SBS APEX Accelerator",
            "Address Line 1": "1 Liberty Plaza",
            "Address Line 2": "11th Floor",
            "City": "New York City",
            "Territory": "NY",
            "Postal Code": 10006,
            "Phone Number": "(212) 513-6444",
            "Extension": "",
            "email for references": "APEXAccelerator@sbs.nyc.gov",
            "website": "https://nyc-business.nyc.gov/nycbusiness/article/procurement-technical-assistance",
            "Organization Service Area": "Brooklyn, Bronx, Kings, Nassau, New York City boroughs of Manhattan, Richmond, Staten Island plus Rockland and Suffolk Counties."
        }
    },
    {
        "address": "99 Otis St, Rome, NY",
        "position": {
            "lat": 43.224295,
            "lng": -75.4079859
        },
        "info": {
            "State": "NY",
            "Name": "Empire APEX Accelerator",
            "Address Line 1": "99 Otis St",
            "Address Line 2": "",
            "City": "Rome",
            "Territory": "NY",
            "Postal Code": 13441,
            "Phone Number": "(315) 334-7855",
            "Extension": "",
            "email for references": "empireapex@nystec.com",
            "website": "https://igniteuny.com/empireapex",
            "Organization Service Area": "Albany, Cattaraugus, Clinton, Columbia, Delaware, Dutchess, Essex, Franklin, Fulton, Greene, Herkimer, Jefferson, Lewis, Montgomery, Oneida, Onondaga, Orange, Oswego, Otsego, Putnam, Rensselaer, St. Lawrence, Saratoga, Schenectady, Schoharie, Sullivan, Ulster, Warren, Washington."
        }
    },
    {
        "address": "31-10 Thomson Avenue, Long Island City, NY",
        "position": {
            "lat": 40.7451291,
            "lng": -73.9377106
        },
        "info": {
            "State": "NY",
            "Name": "LaGuardia APEX Accelerator",
            "Address Line 1": "31-10 Thomson Avenue",
            "Address Line 2": "Suite B-309",
            "City": "Long Island City",
            "Territory": "NY",
            "Postal Code": 11101,
            "Phone Number": "(718) 482-5306",
            "Extension": "",
            "email for references": "Apex@lagcc.cuny.edu",
            "website": "https://www.laguardia.edu/ce/pages/business-services/procurement-technical-assistance-center/",
            "Organization Service Area": "Queens, NY"
        }
    },
    {
        "address": "50 West Main Street, Rochester, NY",
        "position": {
            "lat": 43.1556781,
            "lng": -77.61467809999999
        },
        "info": {
            "State": "NY",
            "Name": "Monroe County Finger Lakes APEX Accelerator",
            "Address Line 1": "50 West Main Street",
            "Address Line 2": "Suite 1150",
            "City": "Rochester",
            "Territory": "NY",
            "Postal Code": 14614,
            "Phone Number": "(585) 753-2017",
            "Extension": "",
            "email for references": "apex@monroecounty.gov",
            "website": "https://www.mcflapex.org",
            "Organization Service Area": "Cayuga, Genesee, Livingston, Monroe, Onondaga, Ontario, Orleans, Seneca, Wayne, Wyoming, Yates"
        }
    },
   
    {
        "address": ", Liverpool, NY",
        "position": {
            "lat": 43.106456,
            "lng": -76.21770459999999
        },
        "info": {
            "State": "NY",
            "Name": "The National Center American Indian APEX Accelerator - New York Satellite Office",
            "Address Line 1": "",
            "Address Line 2": "",
            "City": "Liverpool",
            "Territory": "NY",
            "Postal Code": 20171,
            "Phone Number": "(480) 843-9847",
            "Extension": "",
            "email for references": "crystal@ncaied.org",
            "website": "https://www.ncaied.org/ptac",
            "Organization Service Area": "Eastern BIA Service Area"
        }
    },
    {
        "address": "4240 Campus Drive, Lima, OH",
        "position": {
            "lat": 40.7358707,
            "lng": -84.02508639999999
        },
        "info": {
            "State": "OH",
            "Name": "North West Ohio APEX Accelerator at Rhodes State College (subcenter State of Ohio Ptac)",
            "Address Line 1": "4240 Campus Drive",
            "Address Line 2": "139 Keese Hall",
            "City": "Lima",
            "Territory": "OH",
            "Postal Code": 45804,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Allen, Defiance, Fulton, Hancock, Henry, Lucas, Ottawa, Paulding, Putnam, Sandusky, Van Wert, Williams, Wood."
        }
    },
    {
        "address": "One University Plaza, Youngstown, OH",
        "position": {
            "lat": 41.1064551,
            "lng": -80.6460285
        },
        "info": {
            "State": "OH",
            "Name": "Ohio APEX Accelerator at Youngstown State University (subcenter State of Ohio Ptac)",
            "Address Line 1": "One University Plaza",
            "Address Line 2": "Williamson College of Business Administration",
            "City": "Youngstown",
            "Territory": "OH",
            "Postal Code": 44555,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Columbiana, Mahoning, Trumbull"
        }
    },
    {
        "address": "526 S. Main Street, Akron, OH",
        "position": {
            "lat": 41.0718997,
            "lng": -81.5270031
        },
        "info": {
            "State": "OH",
            "Name": "Ohio University APEX Accelerator at Akron (subcenter State of Ohio Ptac)",
            "Address Line 1": "526 S. Main Street",
            "Address Line 2": "",
            "City": "Akron",
            "Territory": "OH",
            "Postal Code": 43111,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Ashland, Carroll, Holmes, Jefferson, Medina, Richland, Stark,  Summit, Tuscarawas, Wayne"
        }
    },
    {
        "address": "Ohio University Voinovich Ctr., Athens, OH",
        "position": {
            "lat": 39.3197906,
            "lng": -82.1071164
        },
        "info": {
            "State": "OH",
            "Name": "Ohio University APEX Accelerator at Athens (subcenter State of Ohio Ptac)",
            "Address Line 1": "Ohio University Voinovich Ctr.",
            "Address Line 2": "The Ridges, Building 19",
            "City": "Athens",
            "Territory": "OH",
            "Postal Code": 45701,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Athens, Belmont, Guernsey, Harrison, Meigs, Monroe, Morgan, Noble and Washington Counties"
        }
    },
    {
        "address": "1776 Mentor Avenue, Cincinnati, OH",
        "position": {
            "lat": 39.1520985,
            "lng": -84.4672873
        },
        "info": {
            "State": "OH",
            "Name": "Ohio University APEX Accelerator at Cincinnati (subcenter State of Ohio Ptac)",
            "Address Line 1": "1776 Mentor Avenue",
            "Address Line 2": "Suite 240",
            "City": "Cincinnati",
            "Territory": "OH",
            "Postal Code": 45212,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Butler, Clermont, Clinton, Darke, Green, Hamilton, Preble and Warren Counties"
        }
    },
    {
        "address": "1350 Euclid Ave., Cleveland, OH",
        "position": {
            "lat": 41.5004398,
            "lng": -81.6819593
        },
        "info": {
            "State": "OH",
            "Name": "Ohio University APEX Accelerator at Cleveland (subcenter State of Ohio Ptac)",
            "Address Line 1": "1350 Euclid Ave.",
            "Address Line 2": "Suite 211",
            "City": "Cleveland",
            "Territory": "OH",
            "Postal Code": 44115,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Cuyahoga, Erie, Huron and Lorain Counties."
        }
    },
    {
        "address": "65 E. State Street, Columbus, OH",
        "position": {
            "lat": 39.9599513,
            "lng": -82.99830399999999
        },
        "info": {
            "State": "OH",
            "Name": "Ohio University APEX Accelerator at Columbus (subcenter State of Ohio Ptac)",
            "Address Line 1": "65 E. State Street",
            "Address Line 2": "Suite 1350",
            "City": "Columbus",
            "Territory": "OH",
            "Postal Code": 43215,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Crawford, Delaware, Fayette, Franklin, Madison, Marion, Morrow,Seneca, Union and Wyandot Counties"
        }
    },
    {
        "address": "Russ Research Center, Dayton, OH",
        "position": {
            "lat": 39.6933272,
            "lng": -84.03439449999999
        },
        "info": {
            "State": "OH",
            "Name": "Ohio University APEX Accelerator at Dayton (subcenter State of Ohio Ptac)",
            "Address Line 1": "Russ Research Center",
            "Address Line 2": "2642 Indian Ripple Road",
            "City": "Dayton",
            "Territory": "OH",
            "Postal Code": 45407,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Auglaize, Champaign, Clark, Drake, Green, Hardin, Logan, Madison, Mercer, Miami, Montgomery and Shelby Counties"
        }
    },
    {
        "address": "Lakeland Community College, Kirtland, OH",
        "position": {
            "lat": 41.6374539,
            "lng": -81.365875
        },
        "info": {
            "State": "OH",
            "Name": "Ohio University APEX Accelerator at Lakeland Community College (subcenter State of Ohio Ptac)",
            "Address Line 1": "Lakeland Community College",
            "Address Line 2": "7700 Clocktower Drive",
            "City": "Kirtland",
            "Territory": "OH",
            "Postal Code": 44094,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Ashtabula, Geauga, Lake and Portage Counties."
        }
    },
    {
        "address": "12933 Stonecreek Drive, Pickerington, OH",
        "position": {
            "lat": 39.9231563,
            "lng": -82.7813537
        },
        "info": {
            "State": "OH",
            "Name": "Ohio University APEX Accelerator at Pickerington (subcenter State of Ohio Ptac)",
            "Address Line 1": "12933 Stonecreek Drive",
            "Address Line 2": "",
            "City": "Pickerington",
            "Territory": "OH",
            "Postal Code": 43147,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Coshocton, Fayette, Fairfield, Hocking, Knox, Licking, Muskingum, Perry and Pickaway Counties"
        }
    },
    {
        "address": "216 Collins Ave., South Point, OH",
        "position": {
            "lat": 38.426697,
            "lng": -82.5782155
        },
        "info": {
            "State": "OH",
            "Name": "Southern Ohio APEX Accelerator",
            "Address Line 1": "216 Collins Ave.",
            "Address Line 2": "",
            "City": "South Point",
            "Territory": "OH",
            "Postal Code": 45680,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": "Adams, Brown, Gallia, Highland, Jackson, Lawrence, Pike, Ross, Scioto, Vinton"
        }
    },
    {
        "address": "77 South High Street, Columbus, OH",
        "position": {
            "lat": 39.9606364,
            "lng": -83.00096909999999
        },
        "info": {
            "State": "OH",
            "Name": "State of Ohio APEX Accelerator Program",
            "Address Line 1": "77 South High Street",
            "Address Line 2": "P.O Box 1001",
            "City": "Columbus",
            "Territory": "OH",
            "Postal Code": 43215,
            "Phone Number": "(614) 644-1637",
            "Extension": "",
            "email for references": "ohioapex@development.ohio.gov",
            "website": "https://find.govcontracts.ohio.gov/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "1201 W. Willow Rd., Enid, OK",
        "position": {
            "lat": 36.4189103,
            "lng": -97.89758929999999
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Autry Technology Center",
            "Address Line 1": "1201 W. Willow Rd.",
            "Address Line 2": "",
            "City": "Enid",
            "Territory": "OK",
            "Postal Code": 73703,
            "Phone Number": "580 242-2750",
            "Extension": 125,
            "email for references": "aewbank@autrytech.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "2403 N. 41st St. E., Muskogee, OK",
        "position": {
            "lat": 35.775351,
            "lng": -95.3116538
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Indian Capital Technology Center",
            "Address Line 1": "2403 N. 41st St. E.",
            "Address Line 2": "",
            "City": "Muskogee",
            "Territory": "OK",
            "Postal Code": 74403,
            "Phone Number": "(918) 348-7940",
            "Extension": "",
            "email for references": "katey.blair@ictech.edu; Fabiela.Kemble@ictech.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "4601 N Choctaw Rd, Choctaw, OK",
        "position": {
            "lat": 35.51699540000001,
            "lng": -97.2677303
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Eastern Oklahoma County Technology Center",
            "Address Line 1": "4601 N Choctaw Rd",
            "Address Line 2": "",
            "City": "Choctaw",
            "Territory": "OK",
            "Postal Code": 73020,
            "Phone Number": "(405) 390-5350",
            "Extension": "",
            "email for references": "dhoffmeier@eoctech.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "One John C. Bruton Blvd, Shawnee, OK",
        "position": {
            "lat": 35.3786176,
            "lng": -96.9078067
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Gordon Cooper Technology Center",
            "Address Line 1": "One John C. Bruton Blvd",
            "Address Line 2": "",
            "City": "Shawnee",
            "Territory": "OK",
            "Postal Code": 74804,
            "Phone Number": "405.273.7493",
            "Extension": 2317,
            "email for references": "tsanceriah@gctech.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "4500 W. Lee Blvd., Lawton, OK",
        "position": {
            "lat": 34.5897188,
            "lng": -98.4504551
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Great Plains Technology Center",
            "Address Line 1": "4500 W. Lee Blvd.",
            "Address Line 2": "",
            "City": "Lawton",
            "Territory": "OK",
            "Postal Code": 73505,
            "Phone Number": "580.255.5554",
            "Extension": "",
            "email for references": "mferguson@greatplains.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "1004 Hwy 2 N, Wilburton, OK",
        "position": {
            "lat": 34.905513,
            "lng": -95.3232776
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Kiamichi Technology Center",
            "Address Line 1": "1004 Hwy 2 N",
            "Address Line 2": "",
            "City": "Wilburton",
            "Territory": "OK",
            "Postal Code": 74578,
            "Phone Number": "918.465.2323",
            "Extension": 121,
            "email for references": "rdegiacomo@ktc.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "Health Careers Building-A113, Oklahoma City, OK",
        "position": {
            "lat": 35.4675602,
            "lng": -97.5164276
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Metro Technology Centers",
            "Address Line 1": "Health Careers Building-A113",
            "Address Line 2": "1720 Springlake Dr",
            "City": "Oklahoma City",
            "Territory": "OK",
            "Postal Code": 73111,
            "Phone Number": "405.595.4776",
            "Extension": "",
            "email for references": "lynda/speller@metrotech.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "2101 N. Ash, Ponca City, OK",
        "position": {
            "lat": 36.7274223,
            "lng": -97.0935497
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Pioneer Technology Center",
            "Address Line 1": "2101 N. Ash",
            "Address Line 2": "",
            "City": "Ponca City",
            "Territory": "OK",
            "Postal Code": 74601,
            "Phone Number": "(580) 718-4261",
            "Extension": "",
            "email for references": "dawnb@pioneertech.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "PO Box 1807, Duncan, OK",
        "position": {
            "lat": 34.5023028,
            "lng": -97.9578128
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Red River Technology Center",
            "Address Line 1": "PO Box 1807",
            "Address Line 2": "",
            "City": "Duncan",
            "Territory": "OK",
            "Postal Code": 73534,
            "Phone Number": "580.255.2903",
            "Extension": 270,
            "email for references": "lweast@rrtc.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "711 W. Tamarack, Altus, OK",
        "position": {
            "lat": 34.6668482,
            "lng": -99.3427626
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Southwest Technology  Center",
            "Address Line 1": "711 W. Tamarack",
            "Address Line 2": "",
            "City": "Altus",
            "Territory": "OK",
            "Postal Code": 73521,
            "Phone Number": "580.480.4737",
            "Extension": "",
            "email for references": "",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "3638 S. Memorial, Tulsa, OK",
        "position": {
            "lat": 36.11104419999999,
            "lng": -95.88767659999999
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator - Tulsa Technology Center",
            "Address Line 1": "3638 S. Memorial",
            "Address Line 2": "",
            "City": "Tulsa",
            "Territory": "OK",
            "Postal Code": 74145,
            "Phone Number": "(918) 828-5438",
            "Extension": "",
            "email for references": "cindy.jackson@tulsatech.edu",
            "website": "http://www.okbid.org/",
            "Organization Service Area": ""
        }
    },
    {
        "address": "1500 W. Seventh Ave., Stillwater, OK",
        "position": {
            "lat": 36.1148644,
            "lng": -97.07740919999999
        },
        "info": {
            "State": "OK",
            "Name": "Oklahoma APEX Accelerator",
            "Address Line 1": "1500 W. Seventh Ave.",
            "Address Line 2": "",
            "City": "Stillwater",
            "Territory": "OK",
            "Postal Code": 74074,
            "Phone Number": "(405) 743-5122",
            "Extension": "",
            "email for references": "Lana.Knott@careertech.ok.gov",
            "website": "http://www.okbid.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "111 N. Peters, Norman, OK",
        "position": {
            "lat": 35.2219931,
            "lng": -97.4419806
        },
        "info": {
            "State": "OK",
            "Name": "Tribal Government Institute APEX Accelerator",
            "Address Line 1": "111 N. Peters",
            "Address Line 2": "Suite 450",
            "City": "Norman",
            "Territory": "OK",
            "Postal Code": 73069,
            "Phone Number": "(405) 329-5542",
            "Extension": "",
            "email for references": "judy.warren@tgiok.com",
            "website": "http://www.tgiok.com/",
            "Organization Service Area": "EASTERN OKLAHOMA AND SOUTHERN PLAINS BIA REGIONS"
        }
    },
    {
        "address": "522 SE Washington Avenue, Roseburg, OR",
        "position": {
            "lat": 43.2114014,
            "lng": -123.3465252
        },
        "info": {
            "State": "OR",
            "Name": "American Indian Chamber Education Fund APEX Accelerator Subcenter - Oregon",
            "Address Line 1": "522 SE Washington Avenue",
            "Address Line 2": "Suite 134",
            "City": "Roseburg",
            "Territory": "OR",
            "Postal Code": 97470,
            "Phone Number": "(213) 448-2333",
            "Extension": "",
            "email for references": "outreach@aicccal.org",
            "website": "http://aicef-ptac.org/",
            "Organization Service Area": "CA, AZ, UT, NV, WA, OR, ID - BIA REGION (PACIFIC / WESTERN/NORTHWEST)"
        }
    },
    {
        "address": "1144 Gateway Loop, Springfield, OR",
        "position": {
            "lat": 44.0808013,
            "lng": -123.044699
        },
        "info": {
            "State": "OR",
            "Name": "Government Contract Assistance Program (GCAP)  - Springfield",
            "Address Line 1": "1144 Gateway Loop",
            "Address Line 2": "Suite 203",
            "City": "Springfield",
            "Territory": "OR",
            "Postal Code": 97477,
            "Phone Number": "(541) 736-1088",
            "Extension": 104,
            "email for references": "info@gcap.org",
            "website": "http://www.gcap.org/",
            "Organization Service Area": "Statewide"
        }
    },
    // {
    //     "address": ", , OR",
    //     "info": {
    //         "State": "OR",
    //         "Name": "Government Contract Assistance Program (GCAP) - Central/Eastern Oregon",
    //         "Address Line 1": "",
    //         "Address Line 2": "",
    //         "City": "",
    //         "Territory": "OR",
    //         "Postal Code": "",
    //         "Phone Number": "",
    //         "Extension": "",
    //         "email for references": "info@gcap.org",
    //         "website": "http://www.gcap.org/",
    //         "Organization Service Area": "Statewide"
    //     }
    // },
    {
        "address": ", Portland, OR",
        "position": {
            "lat": 45.515232,
            "lng": -122.6783853
        },
        "info": {
            "State": "OR",
            "Name": "Government Contract Assistance Program (GCAP) - Northwest Oregon",
            "Address Line 1": "",
            "Address Line 2": "",
            "City": "Portland",
            "Territory": "OR",
            "Postal Code": "",
            "Phone Number": "(971) 400-1749",
            "Extension": "",
            "email for references": "info@gcap.org",
            "website": "http://www.gcap.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": ", Grants Pass, OR",
        "position": {
            "lat": 42.4390069,
            "lng": -123.3283925
        },
        "info": {
            "State": "OR",
            "Name": "Government Contract Assistance Program (GCAP) - Southern Oregon",
            "Address Line 1": "",
            "Address Line 2": "",
            "City": "Grants Pass",
            "Territory": "OR",
            "Postal Code": "",
            "Phone Number": "",
            "Extension": "",
            "email for references": "info@gcap.org",
            "website": "http://www.gcap.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "1988 Newmark Avenue, Coos Bay, OR",
        "position": {
            "lat": 43.3917455,
            "lng": -124.2552007
        },
        "info": {
            "State": "OR",
            "Name": "Government Contract Assistance Program (GCAP) - Southwest Oregon",
            "Address Line 1": "1988 Newmark Avenue",
            "Address Line 2": "",
            "City": "Coos Bay",
            "Territory": "OR",
            "Postal Code": 97420,
            "Phone Number": "(541) 404-6155",
            "Extension": "",
            "email for references": "info@gcap.org",
            "website": "http://www.gcap.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "4445 SW Barbur Blvd, STE. 105, Portland, OR",
        "position": {
            "lat": 45.4914156,
            "lng": -122.678571
        },
        "info": {
            "State": "OR",
            "Name": "Northwest Native APEX Accelerator",
            "Address Line 1": "4445 SW Barbur Blvd, STE. 105",
            "Address Line 2": "",
            "City": "Portland",
            "Territory": "OR",
            "Postal Code": 97239,
            "Phone Number": "(503) 404-4572",
            "Extension": "",
            "email for references": "mramey@nnapex.org",
            "website": "https://nnapex.org/",
            "Organization Service Area": "Oregon, Washington, Idaho"
        }
    },
    
    {
        "address": "250 University Ave. Box 20, California, PA",
        "position": {
            "lat": 40.0656054,
            "lng": -79.8859198
        },
        "info": {
            "State": "PA",
            "Name": "Government Agency Coordination Office (GACO) APEX Accelerator at PennWest University",
            "Address Line 1": "250 University Ave. Box 20",
            "Address Line 2": "",
            "City": "California",
            "Territory": "PA",
            "Postal Code": 15419,
            "Phone Number": "(724) 938-5881",
            "Extension": "",
            "email for references": "GACOAPEXAccelerator@pennwest.edu",
            "website": "https://www.calu.edu/community/business-careers/gaco/index.aspx",
            "Organization Service Area": "Allegheny, Beaver, Butler, Greene and Washington"
        }
    },
    {
        "address": "Riverside Center for Innovation, Pittsburgh, PA",
        "position": {
            "lat": 40.4499193,
            "lng": -79.9969139
        },
        "info": {
            "State": "PA",
            "Name": "Government Agency Coordination Office (GACO) APEX Accelerator at PennWest University",
            "Address Line 1": "Riverside Center for Innovation",
            "Address Line 2": "700 River Avenue Suite 220",
            "City": "Pittsburgh",
            "Territory": "PA",
            "Postal Code": 15212,
            "Phone Number": "(412) 237-6098",
            "Extension": "",
            "email for references": "GACOAPEXAccelerator@pennwest.edu",
            "website": "https://www.calu.edu/gaco",
            "Organization Service Area": "Allegheny, Beaver, Butler, Greene and Washington"
        }
    },
    {
        "address": "245 Market Street Suite 200, Johnstown, PA",
        "position": {
            "lat": 40.324675,
            "lng": -78.9205583
        },
        "info": {
            "State": "PA",
            "Name": "JARI APEX Accelerator",
            "Address Line 1": "245 Market Street Suite 200",
            "Address Line 2": "Suite 200",
            "City": "Johnstown",
            "Territory": "PA",
            "Postal Code": 15901,
            "Phone Number": "(814) 254-4022",
            "Extension": "",
            "email for references": "jariptac@jari.com",
            "website": "https://jari.ecenterdirect.com/",
            "Organization Service Area": "Cambria, Fayette, Indiana and Somerset Counties."
        }
    },
    {
        "address": "Two Chatham Center, Pittsburgh, PA",
        "position": {
            "lat": 40.4392128,
            "lng": -79.9916063
        },
        "info": {
            "State": "PA",
            "Name": "JARI APEX Accelerator SPC",
            "Address Line 1": "Two Chatham Center",
            "Address Line 2": "Suite 500",
            "City": "Pittsburgh",
            "Territory": "PA",
            "Postal Code": "15219-3451",
            "Phone Number": "(412) 391-5590",
            "Extension": 325,
            "email for references": "jariptac@jari.com",
            "website": "https://jari.ecenterdirect.com/",
            "Organization Service Area": "Allegheny (shared), Armstrong and Westmoreland Counties."
        }
    },
    {
        "address": "49 Ridgway Drive, Ridgway, PA",
        "position": {
            "lat": 41.4224335,
            "lng": -78.7477281
        },
        "info": {
            "State": "PA",
            "Name": "North Central Pennsylvania Regional Planning & Development Commission",
            "Address Line 1": "49 Ridgway Drive",
            "Address Line 2": "",
            "City": "Ridgway",
            "Territory": "PA",
            "Postal Code": 15853,
            "Phone Number": "(814) 773-3162",
            "Extension": 3059,
            "email for references": "jgerraughty@sapdc.org",
            "website": "https://sapdc.org/apex_accelerator/",
            "Organization Service Area": "Cameron, Clearfield, Elk, Jefferson, McKean, Potter"
        }
    },
    {
        "address": "1151 Oak Street, Pittston, PA",
        "position": {
            "lat": 41.30726809999999,
            "lng": -75.7695322
        },
        "info": {
            "State": "PA",
            "Name": "Northeastern Pennsylvania APEX Accelerator",
            "Address Line 1": "1151 Oak Street",
            "Address Line 2": "",
            "City": "Pittston",
            "Territory": "PA",
            "Postal Code": 18640,
            "Phone Number": "(570) 655-5581",
            "Extension": "Option 2",
            "email for references": "ptac@nepa-alliance.org",
            "website": "https://ptac.nepa-alliance.org/",
            "Organization Service Area": "Carbon, Lackawanna, Luzerne, Monroe, Pike, Schuylkill and Wayne Counties"
        }
    },
    {
        "address": "312 Main Street, Towanda, PA",
        "position": {
            "lat": 41.7668344,
            "lng": -76.4432961
        },
        "info": {
            "State": "PA",
            "Name": "Northern Tier Regional Planning & Development Commission",
            "Address Line 1": "312 Main Street",
            "Address Line 2": "",
            "City": "Towanda",
            "Territory": "PA",
            "Postal Code": 18848,
            "Phone Number": "(888) 868-8800",
            "Extension": "",
            "email for references": "jgerraughty@sapdc.org",
            "website": "https://sapdc.org/apex_accelerator/",
            "Organization Service Area": "Bradford, Sullivan, Susquehanna, Tioga, Wyoming"
        }
    },
    {
        "address": "395 Seneca Street, Oil City, PA",
        "position": {
            "lat": 41.440332,
            "lng": -79.7075151
        },
        "info": {
            "State": "PA",
            "Name": "Northwest Commission APEX Accelerator",
            "Address Line 1": "395 Seneca Street",
            "Address Line 2": "",
            "City": "Oil City",
            "Territory": "PA",
            "Postal Code": 16301,
            "Phone Number": "(814) 677-4800",
            "Extension": 130,
            "email for references": "apexaccelerator@northwestpa.org",
            "website": "https://www.northwestpa.org/government-contracting/",
            "Organization Service Area": "Clarion, Crawford, Erie, Forest, Lawrence, Mercer, Venango, Warren"
        }
    },
    {
        "address": "201 Furnace Road, Lewisburg, PA",
        "position": {
            "lat": 40.9251477,
            "lng": -76.86637569999999
        },
        "info": {
            "State": "PA",
            "Name": "SEDA-COG APEX Accelerator",
            "Address Line 1": "201 Furnace Road",
            "Address Line 2": "",
            "City": "Lewisburg",
            "Territory": "PA",
            "Postal Code": 17837,
            "Phone Number": "(570) 522-7224",
            "Extension": "",
            "email for references": "rbrown@seda-cog.org",
            "website": "https://seda-cog.org/departments/ptac/",
            "Organization Service Area": "Adams, Centre, Clinton, Columbia, Cumberland, Dauphin, Franklin, Juniata, Lancaster, Lebanon, Lycoming, Mifflin, Montour, Northumberland, Perry, Snyder, Union, York."
        }
    },
    {
        "address": "4960 South 12th Street, Philadelphia, PA",
        "position": {
            "lat": 39.89066589999999,
            "lng": -75.17236799999999
        },
        "info": {
            "State": "PA",
            "Name": "Southeast Pennsylvania APEX Accelerator  - Philadelphia",
            "Address Line 1": "4960 South 12th Street",
            "Address Line 2": "Building 661, Office 108, The Navy Yard",
            "City": "Philadelphia",
            "Territory": "PA",
            "Postal Code": 19112,
            "Phone Number": "(484) 646-5816",
            "Extension": "",
            "email for references": "ptac@kutztown.edu",
            "website": "https://www.ptac-southeastpa.org/",
            "Organization Service Area": "Berks, Bucks, Chester, Delaware, Lehigh, Montgomery, Northampton and Philadelphia"
        }
    },
    {
        "address": "15200 Kutztown Rd, Kutztown, PA",
        "position": {
            "lat": 40.5087082,
            "lng": -75.7831784
        },
        "info": {
            "State": "PA",
            "Name": "Southeast Pennsylvania APEX Accelerator  - Berks",
            "Address Line 1": "15200 Kutztown Rd",
            "Address Line 2": "",
            "City": "Kutztown",
            "Territory": "PA",
            "Postal Code": 19530,
            "Phone Number": "(484) 646-5811",
            "Extension": "",
            "email for references": "ptac@kutztown.edu",
            "website": "https://www.ptac-southeastpa.org/",
            "Organization Service Area": "Berks, Bucks, Chester, Delaware, Lehigh, Montgomery, Northampton and Philadelphia"
        }
    },
    {
        "address": "416 E 5th Street, Bethlehem, PA",
        "position": {
            "lat": 40.609242,
            "lng": -75.3725549
        },
        "info": {
            "State": "PA",
            "Name": "Southeast Pennsylvania APEX Accelerator  - LeHigh",
            "Address Line 1": "416 E 5th Street",
            "Address Line 2": "",
            "City": "Bethlehem",
            "Territory": "PA",
            "Postal Code": 18015,
            "Phone Number": "(610) 758-5362",
            "Extension": "",
            "email for references": "ptac@kutztown.edu",
            "website": "https://www.ptac-southeastpa.org/",
            "Organization Service Area": "Northampton, Upper Bucks, Upper Montgomery, Lehigh"
        }
    },
    {
        "address": "School of Business-Quick Center, Chester, PA",
        "position": {
            "lat": 39.849557,
            "lng": -75.3557457
        },
        "info": {
            "State": "PA",
            "Name": "Southeasts Pennsylvania APEX Accelerator - Widener",
            "Address Line 1": "School of Business-Quick Center",
            "Address Line 2": "1304 Walnut Street",
            "City": "Chester",
            "Territory": "PA",
            "Postal Code": 19013,
            "Phone Number": "(610) 499-4109",
            "Extension": "",
            "email for references": "ptac@kutztown.edu",
            "website": "https://www.ptac-southeastpa.org/",
            "Organization Service Area": "Chester, Delaware"
        }
    },
    {
        "address": "3 Sheraton Drive, Altoona, PA",
        "position": {
            "lat": 40.4597834,
            "lng": -78.4120828
        },
        "info": {
            "State": "PA",
            "Name": "Southern Alleghenies Planning & Development Commission APEX Accelerator",
            "Address Line 1": "3 Sheraton Drive",
            "Address Line 2": "",
            "City": "Altoona",
            "Territory": "PA",
            "Postal Code": "16601-9343",
            "Phone Number": "(814) 949-6528",
            "Extension": "",
            "email for references": "jgerraughty@sapdc.org",
            "website": "https://sapdc.org/apex_accelerator/",
            "Organization Service Area": "Bedford, Blair, Fulton, Huntingdon  APEX Accelerators."
        }
    },
    {
        "address": "355 Av. Franklin Delano Roosevelt, San Juan, PR",
        "position": {
            "lat": 18.4216427,
            "lng": -66.0682437
        },
        "info": {
            "State": "PR",
            "Name": "Puerto Rico Federal Contracting Center",
            "Address Line 1": "355 Av. Franklin Delano Roosevelt",
            "Address Line 2": "",
            "City": "San Juan",
            "Territory": "PR",
            "Postal Code": 918,
            "Phone Number": "787-758-4747",
            "Extension": 3181,
            "email for references": "fecc@ddec.pr.gov.",
            "website": "https://federalcontracting.ddec.pr.gov/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "School of Business u0026 Public Administration, Mangilao, Guam, PT",
        "position": {
            "lat": 13.4702123,
            "lng": 144.8456217
        },
        "info": {
            "State": "PT",
            "Name": "Guam APEX Accelerator",
            "Address Line 1": "School of Business u0026 Public Administration",
            "Address Line 2": "303 University Dr",
            "City": "Mangilao, Guam",
            "Territory": "PT",
            "Postal Code": "96913-1800",
            "Phone Number": "(671) 735-2596",
            "Extension": "",
            "email for references": "admin@guamptac.com",
            "website": "https://www.guamptac.com/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "315 Iron Horse Way, Providence, RI",
        "position": {
            "lat": 41.8284582,
            "lng": -71.431467
        },
        "info": {
            "State": "RI",
            "Name": "Rhode Island APEX Accelerator",
            "Address Line 1": "315 Iron Horse Way",
            "Address Line 2": "Suite 101",
            "City": "Providence",
            "Territory": "RI",
            "Postal Code": 2908,
            "Phone Number": "(401) 528-9619",
            "Extension": "",
            "email for references": "ptac@riptac.org",
            "website": "https://www.riptac.org",
            "Organization Service Area": "Providence, Bristol, Kent, Newport, Washington "
        }
    },
    {
        "address": "1225 Laurel St, Columbia, SC",
        "position": {
            "lat": 34.0099555,
            "lng": -81.0360877
        },
        "info": {
            "State": "SC",
            "Name": "South Carolina APEX Accelerator",
            "Address Line 1": "1225 Laurel St",
            "Address Line 2": "",
            "City": "Columbia",
            "Territory": "SC",
            "Postal Code": 29201,
            "Phone Number": "(803) 545-4171",
            "Extension": "",
            "email for references": "conner.apex@sc.edu",
            "website": "https://scaccelerator.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "5116 S. Solberg Ave., Sioux Falls, SD",
        "position": {
            "lat": 43.4990195,
            "lng": -96.7799294
        },
        "info": {
            "State": "SD",
            "Name": "South Dakota  APEX Accelerator - Sioux Falls",
            "Address Line 1": "5116 S. Solberg Ave.",
            "Address Line 2": "",
            "City": "Sioux Falls",
            "Territory": "SD",
            "Postal Code": 57108,
            "Phone Number": "(605) 367-5252",
            "Extension": "",
            "email for references": "marcella.hurley@usd.edu",
            "website": "https://sdbusinesshelp.com/procurement-technical-assistance-center/",
            "Organization Service Area": "Beadle, Brookings, Brown, Campbell, Clark, Codington, Day, Deuel, Edmunds, Faulk, Grant, Hamlin, Hand, Kingsbury, Lake, Lincoln, Marshall, McCook, McPherson, Miner, Minnehaha, Moody, Potter, Roberts, Spink, Turner, Walworth"
        }
    },
    {
        "address": "221 S. Central Avenue, Pierre, SD",
        "position": {
            "lat": 44.3690538,
            "lng": -100.3562369
        },
        "info": {
            "State": "SD",
            "Name": "South Dakota APEX Accelerator - Pierre",
            "Address Line 1": "221 S. Central Avenue",
            "Address Line 2": "Suite 33",
            "City": "Pierre",
            "Territory": "SD",
            "Postal Code": 57501,
            "Phone Number": "(605) 773-2783",
            "Extension": "",
            "email for references": "bob.weyrich@usd.edu",
            "website": "https://sdbusinesshelp.com/procurement-technical-assistance-center/",
            "Organization Service Area": "Buffalo, Corson, Dewey, Haakon, Hughes, Hyde, Jones, Mellette, Perkins, Shannon, Stanley, Sully, Todd, Ziebach"
        }
    },
    {
        "address": "730 E. Watertown St., Rapid City, SD",
        "position": {
            "lat": 44.0887626,
            "lng": -103.1987803
        },
        "info": {
            "State": "SD",
            "Name": "South Dakota APEX Accelerator - Rapid City",
            "Address Line 1": "730 E. Watertown St.",
            "Address Line 2": "",
            "City": "Rapid City",
            "Territory": "SD",
            "Postal Code": 57701,
            "Phone Number": "(605) 716-0010",
            "Extension": "",
            "email for references": "ameyer@wrbsc.com",
            "website": "https://sdbusinesshelp.com/procurement-technical-assistance-center/",
            "Organization Service Area": "Bennett, Butte, Custer, Fall River, Harding, Jackson, Lawrence, Lyman, Meade, Pennington"
        }
    },
    {
        "address": "1808 Summit Street, Yankton, SD",
        "position": {
            "lat": 42.88731569999999,
            "lng": -97.40695980000001
        },
        "info": {
            "State": "SD",
            "Name": "South Dakota APEX Accelerator - Yankton",
            "Address Line 1": "1808 Summit Street",
            "Address Line 2": "",
            "City": "Yankton",
            "Territory": "SD",
            "Postal Code": 57089,
            "Phone Number": "(605) 665-4408",
            "Extension": "",
            "email for references": "fredb@districtiii.org",
            "website": "https://sdbusinesshelp.com/procurement-technical-assistance-center/",
            "Organization Service Area": "Aurora, Bon Homme, Brule, Charles Mix, Clay, Davison, Douglas, Gregory, Hanson, Hutchinson, Jerauld, Lyman, Sanborn, Tripp, Union, Yankton"
        }
    },
    {
        "address": "193 Polk Avenue, Nashville, TN",
        "position": {
            "lat": 36.1353017,
            "lng": -86.7485732
        },
        "info": {
            "State": "TN",
            "Name": "University of Tennessee APEX Accelerator - Nashville",
            "Address Line 1": "193 Polk Avenue",
            "Address Line 2": "Suite C",
            "City": "Nashville",
            "Territory": "TN",
            "Postal Code": 37210,
            "Phone Number": "(615) 532-8885",
            "Extension": "",
            "email for references": "debbie.barber@tennessee.edu",
            "website": "https://www.cis.tennessee.edu/programs/tennessee-apex-accelerator",
            "Organization Service Area": "Middle Tennessee"
        }
    },
    {
        "address": "744 McCallie Avenue, Chattanooga, TN",
        "position": {
            "lat": 35.0438373,
            "lng": -85.2966959
        },
        "info": {
            "State": "TN",
            "Name": "University of Tennessee APEX Accelerator - Chattanooga",
            "Address Line 1": "744 McCallie Avenue",
            "Address Line 2": "Suite 502 Doctors Building",
            "City": "Chattanooga",
            "Territory": "TN",
            "Postal Code": 37402,
            "Phone Number": "(423) 504-5300; (423) 425-4806",
            "Extension": "",
            "email for references": "paul.middlebrooks@tennessee.edu",
            "website": "https://www.cis.tennessee.edu/programs/tennessee-apex-accelerator",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "605 Airways Boulevard, Jackson, TN",
        "position": {
            "lat": 35.6229324,
            "lng": -88.8466286
        },
        "info": {
            "State": "TN",
            "Name": "University of Tennessee APEX Accelerator - Jackson",
            "Address Line 1": "605 Airways Boulevard",
            "Address Line 2": "Suite 109",
            "City": "Jackson",
            "Territory": "TN",
            "Postal Code": 38301,
            "Phone Number": "(615) 253-6381",
            "Extension": "",
            "email for references": "vclark9@utk.edu",
            "website": "https://www.cis.tennessee.edu/programs/tennessee-apex-accelerator",
            "Organization Service Area": "West Tennessee"
        }
    },
    {
        "address": "1400 Oak Ridge Turnpike, Oak Ridge, TN",
        "position": {
            "lat": 36.0147185,
            "lng": -84.2586714
        },
        "info": {
            "State": "TN",
            "Name": "University of Tennessee APEX Accelerator - Oak Ridge",
            "Address Line 1": "1400 Oak Ridge Turnpike",
            "Address Line 2": "",
            "City": "Oak Ridge",
            "Territory": "TN",
            "Postal Code": 37830,
            "Phone Number": "(865) 483-0623",
            "Extension": "",
            "email for references": "jutta.bangs@tennessee.edu",
            "website": "https://www.cis.tennessee.edu/programs/tennessee-apex-accelerator",
            "Organization Service Area": "East Tennessee"
        }
    },
    {
        "address": "3209 S. Staples Street, Corpus Christi, TX",
        "position": {
            "lat": 27.7511424,
            "lng": -97.3978332
        },
        "info": {
            "State": "TX",
            "Name": "Del Mar College APEX Accelerator",
            "Address Line 1": "3209 S. Staples Street",
            "Address Line 2": "CED 146",
            "City": "Corpus Christi",
            "Territory": "TX",
            "Postal Code": 78411,
            "Phone Number": "(361) 698-1020",
            "Extension": "",
            "email for references": "APEXAccelerator@delmar.edu",
            "website": "https://www.delmar.edu/apexaccelerator/",
            "Organization Service Area": "Aransas, Bee, Calhoun, Duval, Goliad, Jim Wells, Kenedy, Kleberg, Live Oak, McMullen, Nueces, Refugio, San Patricio, Victoria"
        }
    },
    {
        "address": "Contract Opportunities Center, El Paso, TX",
        "position": {
            "lat": 31.7641699,
            "lng": -106.4767528
        },
        "info": {
            "State": "TX",
            "Name": "Contract Opportunities Center APEX Accelerator",
            "Address Line 1": "Contract Opportunities Center",
            "Address Line 2": "9050 Viscount",
            "City": "El Paso",
            "Territory": "TX",
            "Postal Code": 79925,
            "Phone Number": "(915) 831-7747",
            "Extension": "",
            "email for references": "parmend1@epcc.edu",
            "website": "https://www.elpasococ.org",
            "Organization Service Area": "El Paso"
        }
    },
    {
        "address": "749 Gateway St., Abilene, TX",
        "position": {
            "lat": 32.404687,
            "lng": -99.71165479999999
        },
        "info": {
            "State": "TX",
            "Name": "NW Texas APEX Accelerator",
            "Address Line 1": "749 Gateway St.",
            "Address Line 2": "#301 Building C,",
            "City": "Abilene",
            "Territory": "TX",
            "Postal Code": 79602,
            "Phone Number": "(806) 745-3973",
            "Extension": "",
            "email for references": "nwtptac@ttu.edu",
            "website": "http://www.nwtpac.com/",
            "Organization Service Area": "Andrews, Archer, Armstrong, Bailey, Baylor, Borden, Brewster, Briscoe, Brown, Callahan, Carson, Castro, Childress, Clay, Cochran, Coke\nColeman, Collingsworth, Comanche, Concho, Cottle, Crane, Crocket, Crosby, Culberson, Dallam, Dawson, Deaf Smith, Dickens, Dimmit, Donley, Eastland, Ector, Edwards, Erath, Fisher, Floyd, Foard, Gaines, Garza, Glasscock, Gray, Hale, Hall, Hansford, Hardeman, Hartley, Haskell, Hemphill, Hockley, Hood, Howard, Hudspeth, Hutchinson, Irion, Jack, Jeff Davis, Jones, Kent, Kimble, King, Kinney, Knox, Lamb, Lipscomb, Loving, Lubbock, Lynn, Martin, Maverick, McCulloch, Menard, Midland, Mitchell, Montague, Moore, Motley, Nolan, Ochiltree, Oldham, Palo Pinto, Parker, Parmer, Pecos, Potter, Presidio, Randall, Reagan, Real, Reeves, Roberts, Runnels, Schliecher, Scurry, Shackelford, Sherman, Somervell, Stephens, Sterling, Stonewall, Sutton, Swisher, Taylor, Terrell, Terry, Throckmorton, Tom Green, Upton, Uvalde, Val Verde, Ward, Wheeler, Wichita, Wilbarger, Winkler, Wise, Yoakum, Young"
        }
    },
    {
        "address": "1407 E. Freddy Gonzalez Drive, Edinburg, TX",
        "position": {
            "lat": 26.2871318,
            "lng": -98.1506702
        },
        "info": {
            "State": "TX",
            "Name": "Rio South Texas Regional Procurement Assistance Center/UTRGV APEX Accelerator",
            "Address Line 1": "1407 E. Freddy Gonzalez Drive",
            "Address Line 2": "Rm. 1.200",
            "City": "Edinburg",
            "Territory": "TX",
            "Postal Code": 78539,
            "Phone Number": "(956) 665-7550",
            "Extension": "",
            "email for references": "ptac@utrgv.edu",
            "website": "https://www.utrgv.edu/ptac",
            "Organization Service Area": "Brooks, Cameron, Hidalgo, Maverick. Starr, Webb, Willacy, Zapata"
        }
    },
    {
        "address": "1455 West Loop South, Houston, TX",
        "position": {
            "lat": 29.7526482,
            "lng": -95.4542747
        },
        "info": {
            "State": "TX",
            "Name": "University of Houston APEX Accelerator",
            "Address Line 1": "1455 West Loop South",
            "Address Line 2": "Suite 890",
            "City": "Houston",
            "Territory": "TX",
            "Postal Code": 77027,
            "Phone Number": "(713) 752-8477",
            "Extension": "",
            "email for references": "uhapex@central.uh.edu",
            "website": "https://www.uhapex.uh.edu",
            "Organization Service Area": "Austin, Brazoria, Brazos, Burleson, Chambers, Colorado, Fayette, Fort Bend, Galveston, Grimes, Hardin, Harris, Jackson, Jefferson, Lavaca, Lee, Leon, Liberty, Madison, Matagorda, Milam, Montgomery, Orange, Robertson, Walker, Waller, Washington, Wharton"
        }
    },
    {
        "address": "501 W Cesar Chavez, San Antonio, TX",
        "position": {
            "lat": 29.4236811,
            "lng": -98.50329490000001
        },
        "info": {
            "State": "TX",
            "Name": "University of Texas at San Antonio APEX Accelerator",
            "Address Line 1": "501 W Cesar Chavez",
            "Address Line 2": "Suite 2.322",
            "City": "San Antonio",
            "Territory": "TX",
            "Postal Code": 78207,
            "Phone Number": "(210) 458-3251",
            "Extension": "",
            "email for references": "UTSA.APEXAccelerator@utsa.edu",
            "website": "https://apexaccelerator.iedtexas.org/",
            "Organization Service Area": "Atascosa, Bandera, Bastrop, Bell, Bexar, Blanco, Burnet, Caldwell, Comal, Concho, Crockett, Dewitt, Edwards, Frio, Gillespie, Gonzales, Guadalupe, Hays, Irion, Karnes, Kendall, Kerr, Kimble, Kinney, La Salle, Lampasas, Llano, Mason, McCullough, Medina, Menard, Mills, Real, San Saba, Schleicher, Sutton, Travis, Uvalde, Val Verde, Williamson, Wilson, Zavala Counties"
        }
    },
    {
        "address": "7300 Jack Newel Blvd South, Fort Worth, TX",
        "position": {
            "lat": 32.7841369,
            "lng": -97.2190854
        },
        "info": {
            "State": "TX",
            "Name": "Cross Timbers APEX Accelerator",
            "Address Line 1": "7300 Jack Newel Blvd South",
            "Address Line 2": "Suite 116",
            "City": "Fort Worth",
            "Territory": "TX",
            "Postal Code": 76118,
            "Phone Number": "(817) 272-5978",
            "Extension": "",
            "email for references": "lori.manning@uta.edu",
            "website": "https://www.uta.edu/research/centers/cross-timbers",
            "Organization Service Area": "Anderson, Angelina, Harrison, Bosque, Bowie, Camp, Cass, Cherokee, Clay, Collin, Comanche, Cooke, Coryell, Dallas, Delta, Denton, Eastland, Ellis, Erath, Falls, Fannin, Franklin, Freestone, Grayson, Gregg, Hamilton, Henderson, Hill, Hood, Hopkins, Houston, Hunt, Jack, Jasper, Johnson, Kaufman, Lamar, Limestone, Marion, McLennan, Montague, Morris, Nacogdoches, Navarro, Newton, Palo Pinto, Panola, Parker, Polk, Rains, Red River, Rockwall, Rusk, Sabine, San Augustine, San Jacinto, Shelby, Smith, Somervell, Stephens, Tarrant, Titus, Trinity, Tyler, Upshur, Van Zandt, Wichita, Wise, Wood, Young and The Alabama-Coushatta Tribe of Texas"
        }
    },
    {
        "address": "Bridgerland Technical College, Logan, UT",
        "position": {
            "lat": 41.7559214,
            "lng": -111.8507103
        },
        "info": {
            "State": "UT",
            "Name": "Utah APEX Accelerator - Logan",
            "Address Line 1": "Bridgerland Technical College",
            "Address Line 2": "1410 North 1000 West, Room 1900",
            "City": "Logan",
            "Territory": "UT",
            "Postal Code": 84341,
            "Phone Number": "(435) 750-3261",
            "Extension": "",
            "email for references": "tmcclain@btech.edu",
            "website": "https://business.utah.gov/apex/",
            "Organization Service Area": "Cache, Box Elder, Rich Counties"
        }
    },
    {
        "address": "Davis Technical College, Kaysville, UT",
        "position": {
            "lat": 41.0294767,
            "lng": -111.9254704
        },
        "info": {
            "State": "UT",
            "Name": "Utah APEX Accelerator - Kaysville",
            "Address Line 1": "Davis Technical College",
            "Address Line 2": "450 South Simmons Way",
            "City": "Kaysville",
            "Territory": "UT",
            "Postal Code": 84037,
            "Phone Number": "(801) 633-0758",
            "Extension": "",
            "email for references": "dcobler@utah.gov",
            "website": "https://business.utah.gov/apex/",
            "Organization Service Area": "Davis, Morgan, Weber Counties"
        }
    },
    {
        "address": "Dixie Technical College, St. George, UT",
        "position": {
            "lat": 37.0973711,
            "lng": -113.5914908
        },
        "info": {
            "State": "UT",
            "Name": "Utah APEX Accelerator - St. George",
            "Address Line 1": "Dixie Technical College",
            "Address Line 2": "610 S Tech Ridge Dr",
            "City": "St. George",
            "Territory": "UT",
            "Postal Code": 84770,
            "Phone Number": "435-674-8483",
            "Extension": "",
            "email for references": "jcwiggins@utah.gov",
            "website": "https://business.utah.gov/apex/",
            "Organization Service Area": "Kane, Millard, Piute, Sanpete, Sevier, Washington, Wayne Counties"
        }
    },
    {
        "address": "Salt Lake Community College Miller Campus, Sandy, UT",
        "position": {
            "lat": 40.575329,
            "lng": -111.9009
        },
        "info": {
            "State": "UT",
            "Name": "Utah APEX Accelerator - Sandy",
            "Address Line 1": "Salt Lake Community College Miller Campus",
            "Address Line 2": "9750 South 300 West",
            "City": "Sandy",
            "Territory": "UT",
            "Postal Code": 84070,
            "Phone Number": "(801) 957-5357",
            "Extension": "",
            "email for references": "aquayson@utah.gov",
            "website": "https://business.utah.gov/apex/",
            "Organization Service Area": "South Salt Lake County"
        }
    },
    {
        "address": "SUU Business Resource Center, Cedar City, UT",
        "position": {
            "lat": 37.6638301,
            "lng": -113.0698404
        },
        "info": {
            "State": "UT",
            "Name": "Utah APEX Accelerator - Cedar City",
            "Address Line 1": "SUU Business Resource Center",
            "Address Line 2": "510 West 800 South",
            "City": "Cedar City",
            "Territory": "UT",
            "Postal Code": 84720,
            "Phone Number": "435-865-8884",
            "Extension": "",
            "email for references": "lgreysonjones@suu.edu",
            "website": "https://business.utah.gov/apex/",
            "Organization Service Area": "Beaver, Garfield, Iron Counties"
        }
    },
    {
        "address": "Governor's Office of Ecomonic Opportunity, Salt Lake City, UT",
        "position": {
            "lat": 40.7689298,
            "lng": -111.8888661
        },
        "info": {
            "State": "UT",
            "Name": "Utah APEX Accelerator - Salt Lake City",
            "Address Line 1": "Governor's Office of Ecomonic Opportunity",
            "Address Line 2": "60 E South Temple, STE. 300",
            "City": "Salt Lake City",
            "Territory": "UT",
            "Postal Code": 84111,
            "Phone Number": "(801) 538-8655",
            "Extension": "",
            "email for references": "pkramer@utah.gov",
            "website": "https://business.utah.gov/apex/",
            "Organization Service Area": "Salt Lake, Summit, Tooele Counties"
        }
    },
    {
        "address": "USU Eastern, Price, UT",
        "position": {
            "lat": 39.607914,
            "lng": -110.8039829
        },
        "info": {
            "State": "UT",
            "Name": "Utah APEX Accelerator - Price",
            "Address Line 1": "USU Eastern",
            "Address Line 2": "451 East 400 North",
            "City": "Price",
            "Territory": "UT",
            "Postal Code": 84501,
            "Phone Number": "(435) 613-5198",
            "Extension": "",
            "email for references": "",
            "website": "https://business.utah.gov/apex/",
            "Organization Service Area": "Carbon, Daggett, Duchesne, Emery, Grand, San Juan, Uintah Counties"
        }
    },
    {
        "address": "815 West 1250 South, Suite 210, Orem, UT",
        "position": {
            "lat": 40.2737465,
            "lng": -111.7152607
        },
        "info": {
            "State": "UT",
            "Name": "Utah APEX Accelerator - Orem",
            "Address Line 1": "815 West 1250 South, Suite 210",
            "Address Line 2": "UVU Business Resource Center",
            "City": "Orem",
            "Territory": "UT",
            "Postal Code": 84058,
            "Phone Number": "(801) 538-8775",
            "Extension": "",
            "email for references": "sortiz@utah.gov",
            "website": "https://business.utah.gov/apex/",
            "Organization Service Area": "Juab, Utah, Wasatch Counties"
        }
    },
    {
        "address": "P.O. Box 1808, Petersburg, VA",
        "position": {
            "lat": 37.2279279,
            "lng": -77.40192669999999
        },
        "info": {
            "State": "VA",
            "Name": "Crater Planning District Commission",
            "Address Line 1": "P.O. Box 1808",
            "Address Line 2": "1964 Wakefield Street",
            "City": "Petersburg",
            "Territory": "VA",
            "Postal Code": 23805,
            "Phone Number": "(804) 861-1667",
            "Extension": 310,
            "email for references": "lmann@craterptac.org",
            "website": "https://craterpdc.org/our-works/ptac/",
            "Organization Service Area": "Amelia, Brunswick, Buckingham, Charles City Charlotte, Chesterfield, Colonial Heights, Cumberland, Danville, Dinwiddie, Emporia, Franklin, Goochland , Greensville, Halifax, Henry, Hopewell, Lunenburg, Martinsville, Mecklenburg, Nottoway, Patrick, Petersburg, Pittsylvania, Powhattan, Prince Edward, Prince George, Surry."
        }
    },
    {
        "address": "724 Community College Road, Richlands, VA",
        "position": {
            "lat": 37.00528449999999,
            "lng": -81.7944007
        },
        "info": {
            "State": "VA",
            "Name": "Southwest VA Community College APEX Accelerator",
            "Address Line 1": "724 Community College Road",
            "Address Line 2": "",
            "City": "Richlands",
            "Territory": "VA",
            "Postal Code": 24641,
            "Phone Number": "(276) 964-7334",
            "Extension": "",
            "email for references": "apex.info@sw.edu",
            "website": "https://sw.edu/apex/",
            "Organization Service Area": "Bland, Buchanan, Carroll, Craig, Dickenson, Floyd, Giles, Grayson, Lee, Montgomery, Patrick, Pulaski, Russell, Scott, Smyth, Tazewell, Washington, Wise, Wythe"
        }
    },
    {
        "address": "Mason Enterprise Center, Fairfax, VA",
        "position": {
            "lat": 38.8614536,
            "lng": -77.3021971
        },
        "info": {
            "State": "VA",
            "Name": "Virginia Ptac",
            "Address Line 1": "Mason Enterprise Center",
            "Address Line 2": "10306 Eaton Pl, Suite 180",
            "City": "Fairfax",
            "Territory": "VA",
            "Postal Code": 22030,
            "Phone Number": "(703) 277-7750",
            "Extension": "",
            "email for references": "ptac@gmu.edu",
            "website": "https://VirginiaAPEX.org",
            "Organization Service Area": "Accomack, Albemarle, Alexandria, Allegany, Amherst, Appomattox, Arlington, Augusta, Bath, Bedford, Botetourt, Buena Vista City, Campbell, Caroline, Charlottesville City, Chesapeake City, Clarke, Covington City, Culpeper, Essex, Fairfax, Fairfax City, Falls Church City, Fauquier, Fluvanna, Franklin City, Frederick, Fredericksburg City, Gloucester, Greene, Hampton City, Hanover, Harrisonburg City, Henrico, Highland, Isle of Wight, James City, King and Queen, King George, King William, Lancaster, Lexington City, Loudoun, Louisa, Lynchburg City, Madison, Manassas City, Manassas Park City, Mathews, Middlesex, Nelson, New Kent, Newport News, Norfolk City, Northampton, Northumberland, Orange, Page, Poquoson City, Portsmouth City, Prince William, Rappahannock, Richmond, Richmond City, Roanoke, Roanoke City, Rockbridge, Rockingham, Salem City, Shenandoah, Southampton, Spotsylvania, Staunton City, Suffolk City, Virginia Beach, Warren, Waynesboro, Westmoreland, Winchester City, York"
        }
    },
    {
        "address": "1125 Jefferson Davis Highway, Fredericksburg, VA",
        "position": {
            "lat": 38.3074817,
            "lng": -77.4803228
        },
        "info": {
            "State": "VA",
            "Name": "Virginia APEX Accelerator - Central Virginia Office",
            "Address Line 1": "1125 Jefferson Davis Highway",
            "Address Line 2": "Suite 400",
            "City": "Fredericksburg",
            "Territory": "VA",
            "Postal Code": 22401,
            "Phone Number": "(703) 277-7750",
            "Extension": "",
            "email for references": "ptac@gmu.edu",
            "website": "https://VirginiaAPEX.org",
            "Organization Service Area": ""
        }
    },
    {
        "address": "140 Independence Blvd., Virginia Beach, VA",
        "position": {
            "lat": 36.8413258,
            "lng": -76.1364613
        },
        "info": {
            "State": "VA",
            "Name": "Virginia APEX Accelerator - Hampton Roads Office",
            "Address Line 1": "140 Independence Blvd.",
            "Address Line 2": "",
            "City": "Virginia Beach",
            "Territory": "VA",
            "Postal Code": 23462,
            "Phone Number": "(703) 277-7751",
            "Extension": "",
            "email for references": "ptac@gmu.edu",
            "website": "https://VirginiaAPEX.org",
            "Organization Service Area": ""
        }
    },
    {
        "address": "2 John Brewers Bay, St. Thomas, VI",
        "position": {
            "lat": 18.3451788,
            "lng": -64.9777571
        },
        "info": {
            "State": "VI",
            "Name": "Virgin Islands APEX Accelerator",
            "Address Line 1": "2 John Brewers Bay",
            "Address Line 2": "",
            "City": "St. Thomas",
            "Territory": "VI",
            "Postal Code": 802,
            "Phone Number": "(340) 693-1292",
            "Extension": "",
            "email for references": "frankie.dixon@uvi.edu",
            "website": "https://usviptac.ecenterdirect.com/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "60 Main Street, Burlington, VT",
        "position": {
            "lat": 44.4761575,
            "lng": -73.2175257
        },
        "info": {
            "State": "VT",
            "Name": "Vermont APEX Accelerator - Burlington Office",
            "Address Line 1": "60 Main Street",
            "Address Line 2": "",
            "City": "Burlington",
            "Territory": "VT",
            "Postal Code": 5401,
            "Phone Number": "(802) 652-2134",
            "Extension": "",
            "email for references": "Babette.Lizotte@vermont.gov; ACCD.APEX@vermont.gov",
            "website": "https://www.apex.vermont.gov",
            "Organization Service Area": "Chittenden, Franklin, Grand Isle"
        }
    },
    {
        "address": "1 National Life Drive, Montpelier, VT",
        "position": {
            "lat": 44.25611139999999,
            "lng": -72.58453089999999
        },
        "info": {
            "State": "VT",
            "Name": "Vermont APEX Accelerator - Montpelier Office",
            "Address Line 1": "1 National Life Drive",
            "Address Line 2": "Deane C. Davis Bldg., 6th Floor",
            "City": "Montpelier",
            "Territory": "VT",
            "Postal Code": "05620-0501",
            "Phone Number": "(802) 585-6109; (802) 828-5237",
            "Extension": "",
            "email for references": "Bonnie.Sanders@vermont.gov",
            "website": "https://www.apex.vermont.gov",
            "Organization Service Area": "Addison, Lamoille, Orange, Washington"
        }
    },
    {
        "address": "194 Main Street, Newport, VT",
        "position": {
            "lat": 44.9358792,
            "lng": -72.20993659999999
        },
        "info": {
            "State": "VT",
            "Name": "Vermont APEX Accelerator - Newport Office",
            "Address Line 1": "194 Main Street",
            "Address Line 2": "Suite 219",
            "City": "Newport",
            "Territory": "VT",
            "Postal Code": 5855,
            "Phone Number": "(802) 334-9377",
            "Extension": "",
            "email for references": "Brenda.Plastridge@vermont.gov",
            "website": "https://www.apex.vermont.gov",
            "Organization Service Area": "Caledonia, Essex, Orleans"
        }
    },
    {
        "address": "61 Valley View, Mendon, VT",
        "position": {
            "lat": 43.636734,
            "lng": -72.9357272
        },
        "info": {
            "State": "VT",
            "Name": "Vermont APEX Accelerator - Rutland Office",
            "Address Line 1": "61 Valley View",
            "Address Line 2": "Suite 2",
            "City": "Mendon",
            "Territory": "VT",
            "Postal Code": 5701,
            "Phone Number": "(802) 272-1587",
            "Extension": "",
            "email for references": "Elizabeth.Adams@vermont.gov",
            "website": "https://www.apex.vermont.gov",
            "Organization Service Area": "Bennington, Rutland"
        }
    },
    {
        "address": "14 Clinton Square Suite 7, Springfield, VT",
        "position": {
            "lat": 43.2984078,
            "lng": -72.4823117
        },
        "info": {
            "State": "VT",
            "Name": "Vermont APEX Accelerator - Springfield Office",
            "Address Line 1": "14 Clinton Square Suite 7",
            "Address Line 2": "",
            "City": "Springfield",
            "Territory": "VT",
            "Postal Code": 5156,
            "Phone Number": "(802) 885-3061",
            "Extension": "",
            "email for references": "Ed.Williams@vermont.gov",
            "website": "https://www.apex.vermont.gov",
            "Organization Service Area": "Windham, Windsor"
        }
    },
    {
        "address": "713 Jadwin Avenue, Richland, WA",
        "position": {
            "lat": 46.2761935,
            "lng": -119.2757151
        },
        "info": {
            "State": "WA",
            "Name": "American Indian Chamber Education Fund APEX Accelerator Subcenter - Washington",
            "Address Line 1": "713 Jadwin Avenue",
            "Address Line 2": "Suite 9",
            "City": "Richland",
            "Territory": "WA",
            "Postal Code": 99352,
            "Phone Number": "(509) 581-7505",
            "Extension": "",
            "email for references": "Jeremy.sandoval@aicccal.org",
            "website": "http://aicef-ptac.org/",
            "Organization Service Area": "CA, AZ, UT, NV, WA, OR, ID - BIA REGION (PACIFIC / WESTERN/NORTHWEST)"
        }
    },
    {
        "address": "805 Broadway St, Vancouver, WA",
        "position": {
            "lat": 45.62746050000001,
            "lng": -122.6700494
        },
        "info": {
            "State": "WA",
            "Name": "Washington APEX at Columbia River Economic Development Council",
            "Address Line 1": "805 Broadway St",
            "Address Line 2": "",
            "City": "Vancouver",
            "Territory": "WA",
            "Postal Code": 98660,
            "Phone Number": "(503) 694-9403",
            "Extension": "",
            "email for references": "swwa@washingtonptac.org",
            "website": "https://www.washingtonptac.org",
            "Organization Service Area": "Clark, Cowlitz, Skamania Counties"
        }
    },
    {
        "address": "808 134th ST SW, Everett, WA",
        "position": {
            "lat": 47.8763418,
            "lng": -122.2452246
        },
        "info": {
            "State": "WA",
            "Name": "Washington APEX at Economic Alliance Snohomish County",
            "Address Line 1": "808 134th ST SW",
            "Address Line 2": "Suite 101",
            "City": "Everett",
            "Territory": "WA",
            "Postal Code": 98204,
            "Phone Number": "(425) 248-4223",
            "Extension": "",
            "email for references": "snohomish@washingtonptac.org",
            "website": "https://www.washingtonptac.org",
            "Organization Service Area": "San Juan. Island,  Snohomish, Skagit, Whatcom Counties"
        }
    },
    {
        "address": "801 Riverside Avenue Suite 240, Spokane, WA",
        "position": {
            "lat": 47.657676,
            "lng": -117.4239512
        },
        "info": {
            "State": "WA",
            "Name": "Washington APEX at Greater Spokane Inc",
            "Address Line 1": "801 Riverside Avenue Suite 240",
            "Address Line 2": "",
            "City": "Spokane",
            "Territory": "WA",
            "Postal Code": 99201,
            "Phone Number": "(509) 321-3641",
            "Extension": "",
            "email for references": "Spokane@washingtonptac.org",
            "website": "https://www.washingtonptac.org",
            "Organization Service Area": "Adams, Asotin, Douglas, Ferry, Garfield, Lincoln, Okanogan, Pend Oreille, Spokane, Stevens, Whitman Counties"
        }
    },
    {
        "address": "1221 D Street NE, Auburn, WA",
        "position": {
            "lat": 47.318554,
            "lng": -122.2256259
        },
        "info": {
            "State": "WA",
            "Name": "Washington APEX at Green River College",
            "Address Line 1": "1221 D Street NE",
            "Address Line 2": "Suite 210 C",
            "City": "Auburn",
            "Territory": "WA",
            "Postal Code": 98002,
            "Phone Number": "(253) 520-6267",
            "Extension": "",
            "email for references": "king@washingtonptac.org",
            "website": "https://www.washingtonptac.org",
            "Organization Service Area": "King County"
        }
    },
    {
        "address": "2021 NW Myhre Rd, Silverdale, WA",
        "position": {
            "lat": 47.6522885,
            "lng": -122.67776
        },
        "info": {
            "State": "WA",
            "Name": "Washington APEX at Kitsap Economic Development Alliance",
            "Address Line 1": "2021 NW Myhre Rd",
            "Address Line 2": "Suite 100",
            "City": "Silverdale",
            "Territory": "WA",
            "Postal Code": 98983,
            "Phone Number": "(877) 465-4872",
            "Extension": "",
            "email for references": "kitsap@washingtonptac.org",
            "website": "https://www.washingtonptac.org",
            "Organization Service Area": "Kitsap, Mason Counties"
        }
    },
    {
        "address": "4220 6th Ave SE, Lacey, WA",
        "position": {
            "lat": 47.0438451,
            "lng": -122.8299535
        },
        "info": {
            "State": "WA",
            "Name": "Washington APEX Accelerator at Thurston Economic Development Council / Main Center",
            "Address Line 1": "4220 6th Ave SE",
            "Address Line 2": "",
            "City": "Lacey",
            "Territory": "WA",
            "Postal Code": 98503,
            "Phone Number": "(360) 464-6041",
            "Extension": "",
            "email for references": "info@washingtonptac.org",
            "website": "https://www.washingtonptac.org",
            "Organization Service Area": "Chelan, Grays Harbor, Kittitas, Lewis, Pacific, Pierce, Thurston,  Wahkiakum"
        }
    },
    {
        "address": "7130 W Grandridge Blvd, Kennewick, WA",
        "position": {
            "lat": 46.21735229999999,
            "lng": -119.2179772
        },
        "info": {
            "State": "WA",
            "Name": "Washington APEX Accelerator at Tri City Regional Chamber of Commerce",
            "Address Line 1": "7130 W Grandridge Blvd",
            "Address Line 2": "Suite C",
            "City": "Kennewick",
            "Territory": "WA",
            "Postal Code": 99336,
            "Phone Number": "(509) 736-0510",
            "Extension": "",
            "email for references": "tricity@washingtonptac.org",
            "website": "https://www.washingtonptac.org",
            "Organization Service Area": "Benton, Columbia, Franklin, Grant, Klickitat, Walla Walla, & Yakima Counties"
        }
    },
    {
        "address": "338 W First St, Port Angeles, WA",
        "position": {
            "lat": 48.1211282,
            "lng": -123.4393871
        },
        "info": {
            "State": "WA",
            "Name": "North Olympic Peninsula APEX Accelerator",
            "Address Line 1": "338 W First St",
            "Address Line 2": "Ste 105",
            "City": "Port Angeles",
            "Territory": "WA",
            "Postal Code": 98362,
            "Phone Number": "(360) 457-7793",
            "Extension": "",
            "email for references": "APEX@clallam.org",
            "website": "https://www.clallam.org",
            "Organization Service Area": "Clallam, Jefferson"
        }
    },
    {
        "address": "10437 Innovation Drive, Wauwatosa, WI",
        "position": {
            "lat": 43.0434765,
            "lng": -88.0446759
        },
        "info": {
            "State": "WI",
            "Name": "Wisconsin Procurement Institute",
            "Address Line 1": "10437 Innovation Drive",
            "Address Line 2": "Suite 320",
            "City": "Wauwatosa",
            "Territory": "WI",
            "Postal Code": 53226,
            "Phone Number": "414-270-3600",
            "Extension": "",
            "email for references": "ainav@wispro.org",
            "website": "https://www.wispro.org/",
            "Organization Service Area": "Statewide"
        }
    },
    {
        "address": "126 E. Burke Street, Martinsburg, WV",
        "position": {
            "lat": 39.4571111,
            "lng": -77.96251050000001
        },
        "info": {
            "State": "WV",
            "Name": "RCAC APEX Accelerator Eastern Counties",
            "Address Line 1": "126 E. Burke Street",
            "Address Line 2": "",
            "City": "Martinsburg",
            "Territory": "WV",
            "Postal Code": 25401,
            "Phone Number": "(304) 821-4533",
            "Extension": "",
            "email for references": "mhedrick@rcacwv.com",
            "website": "https://www.rcacwv.com",
            "Organization Service Area": "Berkeley, Grant, Hampshire, Hardy, Jefferson, Mineral, Morgan, Pendleton, Pocahontas, Randolph, Ritchie, Tucker"
        }
    },
    {
        "address": "200 W. Main Street, Clarksburg, WV",
        "position": {
            "lat": 39.27924549999999,
            "lng": -80.3380914
        },
        "info": {
            "State": "WV",
            "Name": "RCAC APEX Accelerator Northern Counties",
            "Address Line 1": "200 W. Main Street",
            "Address Line 2": "Suite B",
            "City": "Clarksburg",
            "Territory": "WV",
            "Postal Code": 26301,
            "Phone Number": "(304) 344-2545",
            "Extension": 5,
            "email for references": "mhedrick@rcacwv.com",
            "website": "https://www.rcacwv.com",
            "Organization Service Area": "Barbour, Brooke, Doddridge, Hancock, Harrison, Lewis, Marion, Marshall, Monongalia, Ohio, Pleasants, Preston, Ritchie, Taylor, Tyler, Upshur, Wetzel, Wirt, Wood"
        }
    },
    {
        "address": "1116 Smith Street, Charleston, WV",
        "position": {
            "lat": 38.3507429,
            "lng": -81.62566319999999
        },
        "info": {
            "State": "WV",
            "Name": "Regional Contracting Assistance Center Inc.- APEX Accelerator HQ",
            "Address Line 1": "1116 Smith Street",
            "Address Line 2": "Suite 401",
            "City": "Charleston",
            "Territory": "WV",
            "Postal Code": 25301,
            "Phone Number": "(304) 344-2546",
            "Extension": 1,
            "email for references": "mhedrick@rcacwv.com",
            "website": "https://www.rcacwv.com",
            "Organization Service Area": "Boone, Braxton, Cabell, Calhoun, Clay, Fayette, Gilmer, Greenbrier, Jackson, Kanawha, Lincoln, Logan, Mason, McDowell, Mercer, Mingo, Monroe, Nicholas, Putnam, Raleigh, Roane, Summers, Wayne, Webster, Wyoming"
        }
    },
    {
        "address": "1000 Vermillion Street, Athens, WV",
        "position": {
            "lat": 37.4227005,
            "lng": -81.0069575
        },
        "info": {
            "State": "WV",
            "Name": "RCAC APEX Accelerator Mid-Southern Counties",
            "Address Line 1": "1000 Vermillion Street",
            "Address Line 2": "127 Marsh Hall",
            "City": "Athens",
            "Territory": "WV",
            "Postal Code": 24712,
            "Phone Number": "(304) 396-9117",
            "Extension": "",
            "email for references": "mhedrick@rcacwv.com",
            "website": "https://www.rcacwv.com",
            "Organization Service Area": ""
        }
    },
    {
        "address": "1400 E. College Drive, Cheyenne, WY",
        "position": {
            "lat": 41.1038489,
            "lng": -104.7810831
        },
        "info": {
            "State": "WY",
            "Name": "Wyoming APEX Accelerator - Cheyenne",
            "Address Line 1": "1000 E. UNIVERSITY AVE",
            "Address Line 2": "",
            "City": "LARAMIE",
            "Territory": "WY",
            "Postal Code": 82070,
            "Phone Number": "(307) 343-0765",
            "Extension": "",
            "email for references": "wyapex@uwyo.edu",
            "website": "https://www.wyomingsbdc.org/wyomingapexaccelerator",
            "Organization Service Area": "Albany, Big Horn, Campbell, Carbon, Converse, Crook, Fremont, Goshen, Hot Springs, Johnson, Laramie, Lincoln, Natrona, Niobrara, Park, Platte, Sheridan, Sublette, Sweetwater, Teton, Uinta, Washakie, Weston"
        }
    },
    {
        "address": "1000 East University Ave, Dept. 3922, Laramie, WY",
        "position": {
            "lat": 41.3140214,
            "lng": -105.5846008
        },
        "info": {
            "State": "WY",
            "Name": "Wyoming APEX Accelerator - Laramie",
            "Address Line 1": "1000 East University Ave, Dept. 3922",
            "Address Line 2": "",
            "City": "Laramie",
            "Territory": "WY",
            "Postal Code": 82071,
            "Phone Number": "(307) 343-0765",
            "Extension": "",
            "email for references": "jforsyth@uwyo.edu",
            "website": "https://www.wyomingsbdc.org/wyomingapexaccelerator/",
            "Organization Service Area": ""
        }
    }
]